// TaskDetailsPopup.js
import React, { useState } from 'react';
import axios from 'axios'; // Import axios if not already imported
// import Clock from './MapLocationRow ';

const TaskDetailsPopup = ({ task, onUpdateStatus }) => {
  const [status, setStatus] = useState(task.task_done);
  const [isImageFullScreen, setIsImageFullScreen] = useState(false);

  const handleStatusUpdate = async (fieldName) => {
    try {
      // Assuming you are using axios for API calls
      await axios.put(`https://empadmin.hola9.com/account/assigntaskview/${task}`, {
        [fieldName]: !task[fieldName], // Toggle the status
      });

      // Update local state to reflect the changes
      setStatus(!task[fieldName]);

      // Callback to update the status in the parent component
      onUpdateStatus(task.id, fieldName, !task[fieldName]);
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };
  const handleImageClick = () => {
    setIsImageFullScreen(true);
  };

  const closeFullScreenImage = () => {
    setIsImageFullScreen(false);
  };


  return (
    <>
      <div className="task-details-popup">

        <div className="popup-content" style={{display:"flex"}}>
        <div style={{width:"50%"}}>
          <h3>{task.task_name}</h3>
          <p>Assignee: {task.assignee_name}</p>
          <p>Project: {task.project_name}</p>
          <p>Tl_name: {task.tl_name}</p>
          <p>Due Date: {task.due_date}</p>

          </div>
          {task.addphoto_base64 && (
            <img
              src={`data:image/png;base64,${task.addphoto_base64}`}
              alt="Task"
              style={{ height: '150px', width: '150px', cursor: 'pointer' }}
              onClick={handleImageClick}
            />
          )}
        
          {/* Close button */}
        </div>
        <div style={{ marginTop: "20px", wordWrap: "break-word" }}>
  <p style={{ fontWeight: "bold" }}>Task Description:</p>
  {task.task_description}
</div>
      </div>
      {isImageFullScreen && (
        <div className="full-screen-overlay" onClick={closeFullScreenImage}>
          <div className="full-screen-image-container">
            <img
              src={`data:image/png;base64,${task.addphoto_base64}`}
              alt="Full Screen Task"
              style={{ maxHeight: '100%', maxWidth: '100%' }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default TaskDetailsPopup;
