// PaySlip.js


import React, { useEffect, useState } from 'react';
import './PaySlip.css';
import companyLogo from './Hola9logo.jpg'; 
import stamp from './stamp.jpg'; 
import { BiPrinter } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
const PaySlip = ({ match }) => {
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(true); // New state for loading

  const { emp } = useParams();

  useEffect(() => {
    // Fetch data for the specified employeeId
    setLoading(true); // Set loading to true before starting the fetch

    fetch(`https://empadmin.hola9.com/account/employees_joining/${emp}/`)
      .then(response => response.json())
      .then(data => {
        setEmployee(data);
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false in case of an error
      });
  }, [emp]);
  const numberToWords = (num) => {
    const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
  
    const convert = (num) => {
      if (num < 10) return units[num];
      if (num < 20) return teens[num - 11];
      if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ' ' + units[num % 10] : '');
      if (num < 1000) return units[Math.floor(num / 100)] + ' Hundred' + (num % 100 !== 0 ? ' ' + convert(num % 100) : '');
      if (num < 1000000) return convert(Math.floor(num / 1000)) + ' Thousand' + (num % 1000 !== 0 ? ' ' + convert(num % 1000) : '');
      if (num < 1000000000) return convert(Math.floor(num / 1000000)) + ' Million' + (num % 1000000 !== 0 ? ' ' + convert(num % 1000000) : '');
      if (num < 1000000000000) return convert(Math.floor(num / 1000000000)) + ' Billion' + (num % 1000000000 !== 0 ? ' ' + convert(num % 1000000000) : '');
      
     
    };
  
    return convert(num);
  };
  
  
  return (
    <>
    {loading ? (
      <div className="loading-overlay">
                <div className="loading-spinner" />
              </div>
    ):(
      <div className="pay-slip" style={{marginTop:"70px"}}>
      <div className="header">
        <img src={companyLogo} alt="Company Logo" className="company-logo" />
        <br></br>        <br></br>

       <hr style={{color:"navy",backgroundColor:"black",border:"1px solid black"}}/>
      </div>
      <div className="company-info">
          <h2 style={{fontSize:"120%"}}>Salary Slip - {employee.month} {employee.year}</h2>
          <p style={{fontSize:"70%"}}><a href='https://hola9.com' style={{color:"blue"}}> Hola9</a> |  <a style={{color:"blue"}} href='tel:+919606451628'>9606451628</a> |  <a style={{color:"blue"}} href='mailto:hello@hola9.com'>hello@hola9.com</a></p>
        </div>
      <>
      <style
  dangerouslySetInnerHTML={{
    __html:
      "\ntable{\nwidth: 100%;\nborder-collapse:collapse;\nborder: 1px solid black;\n}\ntable td, table th {border: 1px solid black;}\ntable td{line-height:25px;padding-left:15px;}\ntable th{background-color:#fbc403; color:#363636;}\n"
  }}
/>


  <table style={{border:"1px solid black"}}>
    <tbody>
    
      <tr>
   
        <th>Payslip_Id</th>
        <td>{employee.id}</td>
        <th>Name</th>
        <td>{employee.employeeName}</td>
      </tr>
      {/*---2 row-*/}
      <tr>
        <th>Bank</th>
        <td>{employee.bank_name}</td>
        <th>Branch Name</th>
        <td>{employee.branch_name}</td>
        {/* <th>Bank A/c No.</th>
        <td>0x2x6x25x6</td> */}
      </tr>
      <tr>
        <th>Bank Acc.</th>
        <td>{employee.acc_num}</td>
        <th>IFSC</th>
        <td>{employee.ifsc}</td>
        {/* <th>PF No.</th>
        <td>-NA-</td> */}
        {/* <th>Bank A/c No.</th>
        <td>0x2x6x25x6</td> */}
      </tr>
      <tr>
        {/* <th>Bank Acc.</th>
        <td>{employee.acc_num}</td>
        <th>IFSC</th>
        <td>{employee.ifsc}</td> */}
        <th>PF No.</th>
        <td>-NA-</td>
        <th>EIN</th>
        <td>--</td>
      </tr>
      {/*----3 row--*/}
      <tr>
        <th>Working Days</th>
        <td>{employee.totalwork}</td>
        <th>Leaves</th>
        <td>{employee.leaves}</td>
      </tr>
      {/*----4 row--*/}
     
      {/*----5 row--*/}
      <tr>
        <th>Location</th>
        <td>Bengaluru</td>
        <th>Department</th>
        <td>Software</td>
      </tr>
      {/*----6 row--*/}
      <tr>
        
        <th>Designation</th>
        <td>{employee.designation}</td>
        <th>Date of Joining</th>
        <td>{employee.dateOfJoining}</td>
      </tr>
      <tr>
        <th>Month:</th>
        <td>{employee.month}</td>
        <th>Year</th>
        <td>{employee.year}</td>
      </tr>
    </tbody>
  </table>
  <br />
  <table style={{border:"1px solid black"}}>
    <tbody>
      <tr>
        <th>Earnings</th>
        <th>Amount</th>
        <th>Deductions</th>
        <th>Amount</th>
      </tr>
      <tr>
        <td>Basic</td>
        <td>{employee.basicDA}</td>
        <td>provident fund</td>
        <td>{employee.providentFund}</td>
      </tr>
      <tr>
        <td>House Rent Allowance</td>
        <td>{employee.hra}</td>
        <td>professional tax</td>
        <td>{employee.professionTax}</td>
      </tr>
      <tr>
        <td>special Allowance</td>
        <td>-NA-</td>
        <td>Income tax</td>
        <td>-NA-</td>
      </tr>
      <tr>
        <td>conveyance</td>
        <td>{employee.conveyance}</td>
        <td>E.S.I</td>
        <td>{employee.esi}</td>
      </tr>
      <tr>
        <td>ADD Special allowance</td>
        <td>-NA-</td>
        <td>Loan</td>
        <td>--</td>
      </tr>
      <tr>
        <td>Shift Allowance</td>
        <td>-NA-</td>
        <td>Profession Tax</td>
        <td>000.00</td>
      </tr>
      <tr>
        <td>Bonus</td>
        <td>000.00</td>
        <td>LOP</td>
        <td>000.00</td>
      </tr>
      <tr>
        <td>Medical Allowance</td>
        <td>-NA-</td>
      </tr>
      <tr>
        <th>Gross Earnings</th>
        <td>{employee.totalAddition}</td>
        <th>Gross Deductions</th>
        <td>{employee.totalDeduction}</td>
      </tr>
     
    </tbody>
  </table>
  <br/>
  <table>
  <tr>
     <td> <strong>NET Salary </strong></td>
  <td><strong>{employee.netSalary}</strong></td>

  <td><strong>{numberToWords(parseFloat(employee.netSalary))} Only</strong></td>
      </tr>
  </table>
</>

      <div className="stamp">
        <img src={stamp} alt="Company Logo" className="-logo" height={150} width={170} />
      </div>       
      <hr style={{color:"navy",backgroundColor:"black",border:"1px solid black"}}/>
      <center className="company-info">
          <h5>HOLA9 Classifieds India Private Limited</h5>
          <p>#12, SecondFloor,3rd Cross, Patel Narayana Reddy Layout, 6th Block Koramangala, Bangalore, Karnataka-560095</p>
        </center>
        <small style={{color:"blue"}}>E-receipt acknowledgment: Signature not necessary for this internet-generated slip
</small>
      <button onClick={() => window.print()} className="print-button">
        <BiPrinter/> Print Pay Slip
      </button>
    </div>
    )}
</>

  );
};

export default PaySlip;
