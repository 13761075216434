import React, { useState, useEffect } from "react";
import { AiOutlineSmile, AiOutlineUpload } from "react-icons/ai";
import { Modal } from "antd";
import "./NewFeed.css";
import axios from "axios";
import { MdDelete, MdSend } from "react-icons/md";
import { BiPhotoAlbum } from "react-icons/bi";
import { NewsFeedLoader, NewsFeedPostLoader } from "../Loaders/Loader";
import { FaEllipsis } from "react-icons/fa6";
import { useAPI } from '../Context/APIProvider ';
const NewsfeedHome = (props) => {
  const {apiData} = useAPI();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible22, setIsModalVisible22] = useState(false);
  const [postContent, setPostContent] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [feedData, setFeedData] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [deleteing, setdeleteing] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintesting = JSON.parse(localStorage.getItem("logintesting"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
  const name = loggedInadmin
    ? loggedInadmin.name
    : loggedInUser
    ? loggedInUser.name
    : logintesting
    ? logintesting.name
    : loginbackend
    ? loginbackend.name
    : logintl
    ? logintl.name
    : logintltest
    ? logintltest.name
    : loginbackendtl
    ? loginbackendtl.name
    : null;
  const organization = loggedInUser
    ? loggedInUser.organization
    : logintesting
    ? logintesting.organization
    : loginbackend
    ? loginbackend.organization
    : logintl
    ? logintl.organization
    : logintltest
    ? logintltest.organization
    : loginbackendtl
    ? loginbackendtl.organization
    : loggedInadmin
    ? loggedInadmin.name
    : null;

  useEffect(() => {
    // Fetch feed data on component mount
    fetchFeedData();
  }, []); // Empty dependency array to run once on mount
  const [errorMessage, setErrorMessage] = useState("");

  const fetchFeedData = () => {
    setIsLoading(true);
    fetch("https://empadmin.hola9.com/account/feeds/")
      .then((response) => response.json()) // Parse response as JSON
      .then((data) => {
        setFeedData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching feed data:", error);
      });
  };

  const [errorMsg, setErrorMsg] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowErrorMessage(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [showErrorMessage]);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const showModal2001 = () => {
    setIsModalVisible22(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleOk2001 = () => {
    setIsModalVisible22(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleCancel2001 = () => {
    setIsModalVisible22(false);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    let month = now.getMonth() + 1;
    let day = now.getDate();
    let hour = now.getHours();
    let minute = now.getMinutes();
    let second = now.getSeconds();

    // Add leading zero if necessary
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    hour = hour < 10 ? "0" + hour : hour;
    minute = minute < 10 ? "0" + minute : minute;
    second = second < 10 ? "0" + second : second;

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    showModal2001();
  };

  const handlePost = () => {
    if (postContent.length < 1) {
      setErrorMessage("Write Something");
      return;
    }
    setIsLoading1(true);
    const formData = new FormData();
    formData.append("desc", postContent);
    if (selectedFile) {
      formData.append("image", selectedFile);
    }
    formData.append("date", getCurrentDateTime()); // Current date and time
    formData.append("name", name); // Use props.name2001 for name
    formData.append("organization", organization); // Use props.organization

    fetch("https://empadmin.hola9.com/account/feeds/", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          console.log("Post successful", formData);
          setPostContent("");
          setSelectedFile(null); // Reset to null after successful post
          fetchFeedData(); // Fetch updated feed data after posting
          setShowErrorMessage(true);
          setErrorMsg("Your Post successful");
          setIsLoading1(false);
        } else {
          console.error("Post failed");
          setShowErrorMessage(true);
          setErrorMsg("Your Post failed");
          setIsLoading1(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading1(false);
      });
  };

  const handleDeleteClick = (id) => {
    console.log("Deleting comment with id:", id);
    const formData = new FormData();
    setIsLoading1(true);
    setdeleteing(true)
    axios
      .delete(`https://empadmin.hola9.com/account/feeds/${id}`, {
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const updatedComments = feedData.filter((comment) => comment.id !== id);
        setFeedData(updatedComments);
        setIsLoading1(false);
        setShowPopup(false)
        setdeleteing(false)
      })
      .catch((error) => {
        setIsLoading1(false);
        setShowPopup(false)
        setdeleteing(false)
        setShowErrorMessage(true)
        setErrorMsg('Failed to Delete Please try again')

        console.error("Error deleting comment:", error);
      });
  };
  const [isModalVisible6, setIsModalVisible6] = useState(false);

  const icons = [
    "😀",
    "😃",
    "😄",
    "😁",
    "😆",
    "😅",
    "😂",
    "🤣",
    "😊",
    "😇",
    "🙂",
    "🙃",
    "😉",
    "😌",
    "😍",
    "🥰",
    "😘",
    "😗",
    "😙",
    "😚",
    "😋",
    "😛",
    "😝",
    "😜",
    "🤪",
    "🤨",
    "🧐",
    "🤓",
    "😎",
    "🤩",
    "🥳",
    "😏",
    "😒",
    "😞",
    "😔",
    "😟",
    "😕",
    "🙁",
    "😣",
    "😖",
    "😫",
    "😩",
    "🥺",
    "😢",
    "😭",
    "😤",
    "😠",
    "😡",
    "🤬",
    "🤯",
    "😳",
    "🥵",
    "🥶",
    "😱",
    "😨",
    "😰",
    "😥",
    "😓",
    "🤗",
    "🤔",
    "🤭",
    "🤫",
    "🤥",
    "😶",
    "😐",
    "😑",
    "😬",
    "🙄",
    "😯",
    "😦",
    "😧",
    "😮",
    "😲",
    "🥱",
    "😴",
    "🤤",
    "😪",
    "😵",
    "🤐",
    "🥴",
    "🤢",
    "🤮",
    "🤧",
    "😷",
    "🤒",
    "🤕",
    "🤑",
    "🤠",
    "😈",
    "👿",
    "👹",
    "👺",
    "🤡",
    "💩",
    "👻",
    "💀",
    "☠️",
    "👽",
    "👾",
    "🤖",
    "🎃",
    "😺",
    "😸",
    "😹",
    "😻",
    "😼",
    "😽",
    "🙀",
    "😿",
    "😾",
    "🌞",
    "🌝",
    "🌚",
    "🌎",
    "🌍",
    "🌏",
    "🌻",
    "🌹",
    "🌺",
    "🌸",
    "🌼",
    "🌷",
    "🌱",
    "🌲",
    "🌳",
    "🌴",
    "🌵",
    "🌾",
    "🌿",
    "☘️",
    "🍀",
    "🍁",
    "🍂",
    "🍃",
    "🍇",
    "🍈",
    "🍉",
    "🍊",
    "🍋",
    "🍌",
    "🍍",
    "🍎",
    "🍏",
    "🍐",
    "🍑",
    "🍒",
    "🍓",
    "🥝",
    "🍅",
    "🥥",
    "🥭",
    "🥑",
    "🍆",
    "🥔",
    "🥕",
    "🌽",
    "🌶️",
    "🥒",
    "🥦",
    "🍄",
    "🥜",
    "🌰",
    "🍞",
    "🥐",
    "🥖",
    "🥨",
    "🥯",
    "🧀",
    "🥚",
    "🍳",
    "🥞",
    "🧇",
    "🥓",
    "🥩",
    "🍗",
    "🍖",
    "🦴",
    "🌭",
    "🍔",
    "🍟",
    "🍕",
    "🥪",
    "🥙",
    "🧆",
    "🌮",
    "🌯",
    "🥗",
    "🥘",
    "🍲",
    "🥫",
    "🍛",
    "🍜",
    "🍝",
    "🍠",
    "🍢",
    "🍣",
    "🍤",
    "🍥",
    "🥮",
    "🍡",
    "🥟",
    "🥠",
    "🥡",
    "🦀",
    "🦞",
    "🦐",
    "🦑",
    "🦪",
    "🍦",
    "🍧",
    "🍨",
    "🍩",
    "🍪",
    "🎂",
    "🍰",
    "🧁",
    "🥧",
    "🍫",
    "🍬",
    "🍭",
  ];

  const insertIcon = (icon) => {
    setPostContent(postContent + icon);
  };
  return (
    <>
      <div className="newsfeed-container">
        <div className="newsfeed-post container-background">
          <div className="new-post-action">
            {apiData ? (
              apiData.map(
               (user) =>(
               <>           
                    <img
                   className="my-profile"
                   src={user.image.replace("http://66.179.250.38:8005/", "https://empadmin.hola9.com/")}
                   alt="profile"
                   title="profile"
                   />
               </>
              ))):(
               <img
               className="my-profile"
               src="https://e8gsb.csb.app/images/profile-320.jpg"
               alt="profile"
               title="profile"
               />
             )}
            <input
              placeholder="What's on your mind, Drop Here?"
              value={postContent}
              onChange={(e) => setPostContent(e.target.value)}
              style={{ borderBottomColor: errorMessage ? "rgb(255, 157, 157)" : "" }}
            />
          </div>
          <div className="new-post-types">
                  <div
                    className="post-type"
                    onClick={() => setIsModalVisible6(true)}
                  >
                    <AiOutlineSmile />
                  </div>

                  <div className="post-type" onClick={showModal}>
                    <BiPhotoAlbum /> Photos
                  </div>

                  <div
                    className="post-type content-hover"
                    onClick={handlePost}
                    style={{ cursor: "pointer", padding:'5px 10px' }}
                  >
                    <MdSend />
                    send
                  </div>
                </div>
        </div>

        <Modal
          title="Choose Emoji"
          visible={isModalVisible6}
          onCancel={() => setIsModalVisible6(false)}
          footer={null}
        >
          <div className="emoji-icons">
            {icons.map((icon, index) => (
              <button key={index} onClick={() => insertIcon(icon)}>
                {icon}
              </button>
            ))}
          </div>
        </Modal>

        <Modal
          title="Upload File"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <input
            type="file"
            onChange={handleFileChange}
            className="upload-input"
          />
        </Modal>
        <Modal
          title="Image Preview"
          visible={selectedImage !== ""}
          onCancel={() => setSelectedImage("")}
          footer={null}
        >
          <img
            src={selectedImage}
            alt="Preview"
            style={{ width: 400, maxHeight: 400 }}
          />
        </Modal>
        {isLoading ? (
          <NewsFeedLoader />
        ) : (
          <>
            {isLoading1 && <NewsFeedPostLoader />}
            <div className="newsfeed-content-grid scroll-bar" style={{overflowY:"scroll"}}>
              {feedData.sort((a, b) => b.id - a.id).map((data, index) => (
                <div key={index} className="newfeed-column-container" >
                  <div className="newsfeed-img-container">
                    <span>
                  <img
                    src={
                      data.profile.image
                        ? "https://empadmin.hola9.com" + data.profile.image
                        : "https://th.bing.com/th/id/OIP.x7X2oAehk5M9IvGwO_K0PgHaHa?rs=1&pid=ImgDetMain"
                    }
                    className="newsfeed-image"
                    alt="img" 
                  />
                  <div>
                  <b className="newsfeed-message-name">{data.name}</b>
                  <b
                        className="newsfeed-message-time"
                        style={{ fontSize: "60%" }}
                      >
                        {data.date}
                      </b>
                  </div>
                    </span>
                    {name === data.name && 
                  <FaEllipsis onClick={() => setShowPopup(data.id)}/>
                    }
                  </div>
                    <span onClick={showModal2001}>
                      {data.image && (
                        <img
                          src={"https://empadmin.hola9.com" + data.image}
                          className="newsfeed-image"
                          alt="img"
                          onClick={() =>
                            handleImageClick(
                              "https://empadmin.hola9.com" + data.image
                            )
                          }
                        />
                      )}
                      </span>
                    <p
                      className="newsfeed-message-body"
                      style={{ fontSize: "70%" }}
                    >
                      {data.desc}
                    </p>
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      {showErrorMessage && (
        <div className={`side-pop-message-div`}>
          <i
            className={`side-pop-message ${
              errorMsg === "Your Post successful"
                ? "sucess-message"
                : "error-message"
            }`}
          >
            {errorMsg}
          </i>
        </div>
      )}

{showPopup && 
      <div className="popupContainer-center3" onClick={() => setShowPopup(!showPopup)} style={{zIndex:'2'}}>
      <div className='popupContainer-center-sub3 logout-popup box-shadow popup-768-sub3' onClick={(e) => e.stopPropagation()}>
        <h5 className="content-hover" style={{padding:'10px 0'}} onClick={() => handleDeleteClick(showPopup)}>{deleteing ? 'Deleting...' : 'Delete'}</h5>
        <hr/>
        <h5 className="content-hover" onClick={() => setShowPopup(!showPopup)} style={{padding:'10px 0', color:'gray'}}>Cancel</h5>
        </div>
        </div>
      }
    </>
  );
};

export default NewsfeedHome;
