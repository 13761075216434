import React, { useEffect, useState } from "react"
import "./Notification.css";
import { LoadingUrl2 } from "../Loaders/Preloader";

const Notifications = () => {

  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintesting = JSON.parse(localStorage.getItem("logintesting"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
  const name=loggedInadmin ? loggedInadmin.name :loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name:logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name: null;
  const organization = loggedInUser
  ? loggedInUser.organization
  : logintesting
  ? logintesting.organization
  : loginbackend
  ? loginbackend.organization
  : logintl
  ? logintl.organization
  : logintltest
  ? logintltest.organization
  : loginbackendtl
  ? loginbackendtl.organization
  : null;
  const [details, setDetails] = useState([]);
  const [loading, setloading] = useState(true)
  useEffect(() => {
    fetch(`https://empadmin.hola9.com/account/usernotification/?name=${name}&organization=${organization}`)
      .then((response) => response.json())
      .then((data) => {
        setDetails(data);
        setloading(false)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [name]);
  return (
    <>
    {loading ? <LoadingUrl2/> : <>
      {details.map((data, index) => (
        <div key={index} className="notification-card content-hover">
          <div className="notification-head">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT36VHh-mjL_Rc8IL60D77dMDPL_fNhosHuag&usqp=CAU"
              className="notification-image"
              width={40}
              height={40}
              alt="profile"
            />
            {/* <b className="notification-title">{data.name}</b> */}
            <i className="notification-time">{data.created_date.split('T')[0]}</i>
          </div>
          <p className="notification-para">{data.message}</p>
        </div>
        
      ))}
      </>}
    </>
  );
};

export default Notifications;
