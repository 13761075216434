import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import server from "../images/server.gif";
import internet from "../images/offline.gif";
export const NoInternetWarning = () => {

  const navigate = useNavigate();

  // const handleTryAgain = () => {
  //   window.location.reload();
  //   window.location.href = '/';
  // };

  // useEffect(() => {
  //   handleTryAgain();
  // }, []);
  return (
    <div style={{ textAlign: 'center', paddingTop: '50px' }}>
      <span role="img" aria-label="Warning" style={{ fontSize: '50px' }}>
        ⚠️
      </span>
      <p style={{ fontSize: '24px', marginTop: '20px' }}>
        Oops! It seems like you're offline.
        <br/>
      <button className='button' onClick={()=> {navigate('/'); window.location.reload()}}>Try again</button>
      </p>
      
      <img src={internet} alt="No Internet Gif" style={{ maxWidth: '100%', marginTop: '20px', height:'400px' }} />
    </div>
  );
};

export const Seruvavailabel = () => {
  const navigate = useNavigate();
  return (
    <div style={{ textAlign: 'center', paddingTop: '50px' }}>
      <span role="img" aria-label="Warning" style={{ fontSize: '50px' }}>
        ⚠️
      </span>
      <p style={{ fontSize: '24px', marginTop: '20px' }}>
        Server Unvailabel.<span style={{color:"red"}}>Under Maintainance</span>
        <br/>
      <button className='button' onClick={()=> {navigate('/'); window.location.reload()}}>Try again</button>
      </p>
      
      <img src={server} alt="No servaer Gif" style={{ maxWidth: '100%', marginTop: '20px' , height:'400px'}} />
    </div>
  );
};

