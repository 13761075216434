import React from "react";
import ProjectDetails from "../Projects/ProjectDetails";
import Home from "../Home/Home";
import Profile from "../Profile/Profile";
import Leave from "../Services/Leaves/Leave";
import Reports from "../Services/Reports/Reports";
import Gallery from "../Services/Gallery/Gallery";
import DailyReports from "../Services/Reports/DailyReports";
import CheckInLogs from "../Services/CheckIn/CheckInLogs";
import AdminReports from "../Services/Reports/AdminReports";
import AdminCheckInLogs from "../Services/CheckIn/AdminCheckInLogs";
import Admintask from "../../Components/Services/Task/Employeetaskad";
import Testingtl from "../../Components/Services/Task/Employeetestinglead";
import TaskCardPage1 from "../../Components/Services/Task/Employeetask1";
import TaskCardPage from "../../Components/Services/Task/Employeetask";
import Personalslip from "../../Components/Services/Salaryslip/Personalslip";
import EmployeesList from "../Services/Salaryslip/Employeeslips";
import NewsfeedHome from "../NewsFeed/NewsfeedHome";
import AddProfile from "../Services/AddProfile/AddProfile";
import AddUser from "../Services/AddUser/AddUser";
import EmployeeDash from '../SearchEmployee/EmployeeDash'
import PerformanceEvents from "../Home/PerformanceEvents";

const MainBar = ({
  isActive,setIsActive
}) => {

  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintesting = JSON.parse(localStorage.getItem("logintesting"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
  const name=loggedInadmin ? loggedInadmin.name :loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name:logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name: null;

  return (
    <div className='MainBar-Pad-Mar scroll-bar'>
      {isActive === "Home" ? <Home/> : null}
      {isActive === "Profile" ? <Profile/> : null}
      {isActive === "Chat" ? "Chat" : null}
      {isActive && isActive.startsWith("Projects/") ? (
        <ProjectDetails project={isActive.split("/")[1]} setIsActive={setIsActive}/>
      ) : null}
      {isActive && isActive.startsWith("Search/") ? (
        <EmployeeDash search={isActive.split("/")[1]} isActive={isActive} />
      ) : null}
      {isActive && isActive.startsWith("profileDetails/") ? (
        <EmployeeDash profileDetails={isActive.split("/")[1]} isActive={isActive} />
      ) : null}
      {isActive === "About" ? "About" : null}
      {isActive === "Gallery" ? <Gallery/> : null}
      
      {isActive === "Your details - logs" ? (loggedInadmin ? <AdminReports  setIsActive={setIsActive} />  : <Reports setIsActive={setIsActive}/>) : null}
      {isActive === "checkIn Details" ?(loggedInadmin ? <AdminCheckInLogs/> : <CheckInLogs setIsActive={setIsActive}/>) : null}
      {isActive === "Task list" ? (
 <>
 {loggedInUser ? (
   <>
     <TaskCardPage1 name={loggedInUser.name} />
   </>
 ) : logintl ? (
   <>
   <Admintask name={logintl.name}/> </>
 ) : logintesting ? (
   <>
   <Testingtl name={logintesting.name}/> 
   </>
 ) : logintltest ? (
   <>   <Testingtl name={logintltest.name}/> 
   </>
 ) : loginbackend ? (
   <>   <Admintask name={loginbackend.name}/> </>
 ) : loginbackendtl ? (
  <>   <Admintask name={loginbackendtl.name}/> </>
  ) : loggedInadmin ? (
   <><TaskCardPage />
   </>
 ) : null}
</>            ) : null}
      {isActive === "Leave" ? <Leave/> : null}
      {isActive === "Salaryslips" ? (loggedInadmin ? <EmployeesList/>:<Personalslip name={name}/>) : null}
      {isActive === "Daily report" ? <DailyReports/> : null}
      {isActive === "News Feed" ? <NewsfeedHome/> : null}
      {isActive === "Add Profile" ? <AddProfile/> : null}
      {isActive === "Credentials" ? <AddUser/> : null}
      {isActive === "Event" ? <PerformanceEvents/> : null}
    </div>
  );
};

export default MainBar;
