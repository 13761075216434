import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo2 from "./hrmsnonbg.png";
import { RiEyeOffFill } from "react-icons/ri";
import { FaEye } from "react-icons/fa";
import { useAPI } from "../Context/APIProvider ";

const Adminlogin = ({setActiveButton}) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [loginError, setLoginError] = useState(""); // New state for login error
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false); // State to manage showing or hiding password
const data = useAPI();
  const [isProcessing, setIsProcessing] = useState(false);
  useEffect(() => {
    const companyDomain = "outlook.com";
    const emailRegex = new RegExp(`^[A-Za-z0-9._%+-]+@${companyDomain}$`, "i");
  
    if (!emailRegex.test(email) && email.trim() !== "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }, [email]);
  
  useEffect(() => {
    // Check if data has loaded
    if (data.length > 0) {
      // Navigate automatically based on the first entry
      const firstEntry = data[2];
      if (firstEntry[1] === true) {
        window.location.href = firstEntry[3];
    }
    }
  }, [data]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    try {
      const response = await fetch("https://empadmin.hola9.com/account/adminAuth", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: email, // Assuming username is the email entered
          password: password,
          organization: name,
        }),
      });

      const result = await response.json();

      if (result === true) {
        // Successful login, redirect to another page
        localStorage.setItem("loggedInadmin", JSON.stringify({ email, password,name }));
      window.location.reload();
        navigate(`/`); // Replace "/otherPage" with the actual path you want to navigate to
      } else {
        // Login failed, display an error message
        setLoginError("Invalid username or password");
      }
    } catch (error) {
      console.error("Error:", error);
    }finally {
      // Set the processing state to false when the login request is complete (success or failure)
      setIsProcessing(false);
    }
  };

  // Check if the user is already logged in (stored in localStorage)
  useEffect(() => {
    const loggedInUser = localStorage.getItem("loggedInadmin");
    if (loggedInUser) {
      const { email, name } = JSON.parse(loggedInUser);
      navigate(`/`);
    }
  }, []);

  // Function to handle logout
  const handleLogout = () => {
    // Remove user details from localStorage
    localStorage.removeItem("loggedInUser");
  };

  const pri = "sss^7FvbbPric";

  return (
    <>
   
      <form onSubmit={handleSubmit}  className="login-form-container">
<div><img src={logo2} alt="logo"/></div>

        <div className="form-group">
          <label htmlFor="name">Organization</label>
            <input
            style={{borderBottomColor: loginError ? 'rgb(255, 0, 0)' : ''}}
              type="text"
              placeholder="Organization"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className='input-field'
            />
          </div>
        <div className="form-group">
        <label htmlFor="name">E-mail Address</label>
          <input
          style={{borderBottomColor: loginError ? 'rgb(255, 0, 0)' : ''}}
            type="email"
            placeholder="Outlook"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          className="input-field"

          />
        </div>
        <div className="form-group">
        <label htmlFor="password">Password</label>
        <div className="password-input-container">

          <input
          style={{borderBottomColor: loginError ? 'rgb(255, 0, 0)' : ''}}
          type={showPassword ? "text" : "password"}
            placeholder="* * * * * *"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          className="input-field"

          />
        {showPassword ? <RiEyeOffFill onClick={() => setShowPassword(!showPassword)}/> : <FaEye onClick={() => setShowPassword(!showPassword)}/>}
        </div>
          </div>
         {loginError && <div className="error" style={{color:"red",fontSize:"70%"}}>Wrong Credentials Please check</div>}
{/*
        {emailError && <div className="error">Enter Outlook Email Id's Only</div>} */}

          <div className="LoginForm-buttons">
           <button className="button" type="submit" disabled={isProcessing}>
            {isProcessing ? "Wait..." : isProcessing ? "Processing..." : "Login"}
          </button>
          <button className="button admin-login-button" type="button" onClick={() => setActiveButton("employee")}>Employee Login</button>
          </div>
      <p className="Admin-Login-signUp">Want to get more services <Link to={`/Pricing/${pri}`} className="Admin-Login-signUp-link">Sign Up</Link> & <Link to={`/Fogotpassword/Verify`} className="Admin-Login-signUp-link">Forget password</Link> </p>
      </form>

   
    </>
  );
};

export default Adminlogin;
