import React, { useEffect } from "react";

export default function MapLocationAds1 ({ id1, props1 }) {
  useEffect(() => {
    const iframeData = document.getElementById(`iframeId-${id1}`);
    const lat1 = props1.lati1; // Replace with your latitude
    const lon1 = props1.long1; // Replace with your longitude

    // Construct the HTML content to embed the Google Map with the marker
    const iframeContent = `
      <html>
        <head>
          <style>
            body {
              margin: 0;
              padding: 0;
            }
            #map-container-${id1} {
              height: 100vh; /* Adjust the height as needed */
              position: relative;
            }
            #google-map-${id1} {
              width: 100%;
              height: 100%;
            }
            #marker-${id1} {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          </style>
        </head>
        <body>
          <div id="map-container-${id1}">
            <div id="google-map-${id1}">
              <iframe
                width="100%"
                height="100%"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?q=${lat1},${lon1}&z=15&output=embed"
              ></iframe>
            </div>
            <div id="marker-${id1}">
              <svg
                width="30"
                height="30"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                aria-hidden="true"
                role="img"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 1024 1024"
              >
                <!-- Your SVG content for the marker goes here -->
              </svg>
            </div>
          </div>
        </body>
      </html>
    `;

    // Set the iframe's content
    iframeData.contentWindow.document.open();
    iframeData.contentWindow.document.write(iframeContent);
    iframeData.contentWindow.document.close();
  }, [id1, props1.lati, props1.long]);

  return (
    <div style={{ marginBottom: "10px", width: "100%" }}>
      <div style={{ border: "1px solid blue", borderRadius: "10px", overflow: "hidden" }}>
        <iframe
          id={`iframeId-${id1}`}
          title={`Google Map - ${id1}`}
          height="100"
          width="100%"
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  );
}
