
import React, { useState, useEffect } from 'react';
import "./pricingcard.css"
import { BiRupee } from 'react-icons/bi';
import { Link } from 'react-router-dom';
const Pricing = () => {
    return(
        <>
  <div className="wrapper">
  <div className="pricing-table gprice-single">
    <div className="head">
      <h4 className="title">Free</h4>
    </div>
    <div className="content">
      <div className="price" style={{textAlign:"center"}}>
        <h1>₹0</h1>
      </div>
      <ul>
        <li>2 GB Ram</li>
        <li>40GB SSD Cloud Storage</li>
        <li>For 15 Days</li>
        <li>Responsive Framework</li>
        <li>Up to 30 User's Can add</li>
        <li>
          {" "}
          <del>Monthly Billing Software</del>{" "}
        </li>
        <li>
          {" "}
          <del>1 Free Website</del>
        </li>
      </ul>
      <div className="sign-up">
        <Link to="/Signup" className="btn bordered radius">
          Signup Now
        </Link>
      </div>
    </div>
  </div>
  <div className="pricing-table gprice-single">
    <div className="head">
      <h4 className="title">Standard</h4>
    </div>
    <div className="content">
      <div className="price">
        <h1>₹299</h1>
      </div>
      <ul>
        <li>4 GB Ram</li>
        <li>100GB SSD Cloud Storage</li>
        <li>Month Subscription</li>
        <li>Responsive Framework</li>
        <li>Monthly Billing Software</li>
        <li>Up to 50 User's Can add</li>

        <li>
          <del>1 Free Website</del>
        </li>
      </ul>
      <div className="sign-up">
        <a href="#" className="btn bordered radius">
          Pay Now
        </a>
      </div>
    </div>
  </div>
  <div className="pricing-table gprice-single">
    <div className="head">
      <h4 className="title">Premium</h4>
    </div>
    <div className="content">
      <div className="price">
        <h1>₹599</h1>
      </div>
      <ul>
        <li>16 GB Ram</li>
        <li>400GB SSD Cloud Storage</li>
        <li>Month Subscription</li>
        <li>Full Security</li>
        <li>Monthly Billing Software</li>
        <li>1 Free Website</li>
        <li>Up to 200 User's Can add</li>

      </ul>
      <div className="sign-up">
        <a href="#" className="btn bordered radius">
          Pay Now
        </a>
      </div>
    </div>
  </div>
</div>

</>

    )
}

export default Pricing;