import React, { useEffect, useState } from "react";
import "./Holidays.css";
import AddHoliday from "./AddHoliday";
import { FaRegTrashAlt, FaRegWindowClose } from "react-icons/fa";


const Holidays = () => {


  const loggedInAdmin = localStorage.getItem("loggedInadmin");

  const [addHoliday, setAddHoliday] = useState(false)

      // ---------------- auto hide error popup after 5 seconds ----------------- //
      const [message, setmessage] = useState(null)
      const [showErrorMessage, setShowErrorMessage] = useState(false);
      useEffect(() => {
        const timer = setTimeout(() => {
          setShowErrorMessage(false);
        }, 5000);
        return () => clearTimeout(timer);
      }, [showErrorMessage]);


  // ------------------------ fetch holiday data -------------------------- //
  const [holidays, setHolidays] = useState([]);
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintesting = JSON.parse(localStorage.getItem("logintesting"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
  const name=loggedInadmin ? loggedInadmin.name :loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name:logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name: null;
  const organization = loggedInUser
  ? loggedInUser.organization
  : logintesting
  ? logintesting.organization
  : loginbackend
  ? loginbackend.organization
  : logintl
  ? logintl.organization
  : logintltest
  ? logintltest.organization
  : loginbackendtl
  ? loginbackendtl.organization
  : loggedInadmin
  ? loggedInadmin.name
  : null;
  const fetchHolidayData = async () => {
    try {
      const response = await fetch(
        `https://empadmin.hola9.com/account/holiday/organization=${organization}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setHolidays(data);
    } catch (error) {
      console.error("Error fetching holidays:", error);
    }
  };
  useEffect(() => {
    fetchHolidayData();
  }, []);



  // ------------------------- delete holiday data ------------------ //

  const deleteHoliday = async (holidayId) => {
    try {
      const response = await fetch(`https://empadmin.hola9.com/account/holiday/${holidayId}/`, {
        method: "DELETE"
      });

      if (!response.ok) {
        setShowErrorMessage(true);
      setmessage('Error Deleting Holiday')
        throw new Error("Network response was not ok");
      }
      fetchHolidayData();
      setShowErrorMessage(true);
      setmessage('Holiday Deleted')
    } catch (error) {
      console.error("Error deleting holiday data:", error);
    }
  };


  // --------------------- edit holiday data --------------------- //

  const [editingHoliday, setEditingHoliday] = useState(null);
  const [formData, setFormData] = useState({
    id : '',
    holidayname: "",
    desc: "",
    date: "",
    week: ""
  });

  const editHoliday = (holiday) => {
    setEditingHoliday(holiday);
    setFormData({
      id: holiday.id,
      holidayname: holiday.holidayname,
      desc: holiday.desc,
      date: holiday.date,
      week: holiday.week
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const submitEdit = async (holidayId) => {
    try {
      const response = await fetch(`https://empadmin.hola9.com/account/holiday/${holidayId}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        setShowErrorMessage(true);
        setmessage('Error Editing Holiday')
        throw new Error("Network response was not ok");
      }
      fetchHolidayData();
      setEditingHoliday(null);
      setShowErrorMessage(true);
      setmessage('Holiday Edited')
      setFormData({
        id:'',
        holidayname: "",
        desc: "",
        date: "",
        week: ""
      });
    } catch (error) {
      console.error("Error editing holiday data:", error);
    }
  };

  
  //  ---------------- check for holiday ------------------- //
  const [currentDate, setCurrentDate] = useState(new Date());


  const isHoliday = (date) => {
    return holidays.some(
      (holiday) => new Date(holiday.date).toDateString() === date.toDateString()
    );
  };

  // -------------------------  generate calendar days for each month ----------------------------- //
  const generateCalendarDays = (year, month) => {
    const days = [];
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);
    const startDayOfWeek = startDate.getDay(); // 0 (Sunday) to 6 (Saturday)

    // -------------- empty cells ----------------- //
    for (let i = 0; i < startDayOfWeek; i++) {
      days.push(
        <div className="holiday-empty-cell" key={`holiday-empty-${i}`}></div>
      );
    }

    // ------------------ get dates ,todays-date,  holidays, sat & sun ------------------------------- //
    for (let i = 1; i <= endDate.getDate(); i++) {
      const date = new Date(year, month, i);
      const classNames = ["holiday-day"];
      if (isHoliday(date)) {
        classNames.push("holiday-holiday");
      }
      if (date.getDay() === 0) {
        classNames.push("holiday-sunday");
      } else if (date.getDay() === 6) {
        classNames.push("holiday-saturday");
      }
      if (
        date.getDate() === new Date().getDate() &&
        date.getMonth() === new Date().getMonth() &&
        date.getFullYear() === new Date().getFullYear()
      ) {
        classNames.push("holiday-today");
      }
      days.push(
        <div
          className={classNames.join(" ")}
          key={date.getDate()}
          // onClick={() => handleDayClick(date)}
        >
          {date.getDate()}
        </div>
      );
    }

    return days;
  };

  const goToPreviousMonth = () => {
    const previousMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    setCurrentDate(previousMonth);
  };

  const goToNextMonth = () => {
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    setCurrentDate(nextMonth);
  };


  // ----------- current month  ------------- holidays - desciption ---------------------- //
  const filteredHolidays = holidays.filter((holiday) => {
    const holidayDate = new Date(holiday.date);
    return holidayDate.getMonth() === currentDate.getMonth() && holidayDate.getFullYear() === currentDate.getFullYear();
  });


  return (
    <>
      <div className="holiday-header">
        <h2>
          {currentDate.toLocaleString("default", {
            month: "long",
            year: "numeric",
          })}
        </h2>
        <button
          className="holiday-nav-button content-hover"
          onClick={goToPreviousMonth}
        >
          <i className="fas fa-arrow-left"></i>
        </button>
        <button
          className="holiday-nav-button content-hover"
          onClick={goToNextMonth}
        >
          <i className="fas fa-arrow-right"></i>
        </button>
      </div>
      <div className="holiday-days">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <div className="holiday-day-label" key={day}>
            {day}
          </div>
        ))}
        {generateCalendarDays(
          currentDate.getFullYear(),
          currentDate.getMonth()
        )}
      </div>

      <br/>
      <br/>

      {filteredHolidays.map((selectedHoliday) => (
        <>
        <div className="holiday-pop-Home-Card ">
         <span className="holiday-pop-date-info border" onClick={loggedInAdmin ? () => editHoliday(selectedHoliday) : null} style={{cursor: loggedInAdmin ? "pointer" : null}}>
                <p>{new Date(selectedHoliday.date).toLocaleDateString('en-US', { weekday: 'short' })}</p>
                <h3>{new Date(selectedHoliday.date).getDate()}</h3>
              </span>
              <span className="holiday-pop-details">
                <b>{selectedHoliday.holidayname}</b>
                {loggedInAdmin ? <>
                <FaRegTrashAlt onClick={() => deleteHoliday(selectedHoliday.id)} className="holiday-icon"/>
                </>: null}
              </span>
            </div>
                <p className="holiday-pop-details-p">{selectedHoliday.desc}</p>
            </>
      ))}






{editingHoliday ? (<>
  <h5 style={{textAlign:'center', maxWidth:'100%'}}>Edit Holiday</h5>
  <FaRegWindowClose style={{float:'right', marginTop:'-14px', fontSize:'70%', cursor:'pointer'}} onClick={() => setEditingHoliday(null)}/>
      <hr/>
        <form className="form-group2" style={{gap:'10px'}}>
          <input type="text" name="holidayname" value={formData.name} onChange={handleInputChange} />
          <input type="text" name="desc" value={formData.desc} onChange={handleInputChange} />
          <input type="date" name="date" value={formData.date} onChange={handleInputChange} />
          <input type="text" name="week" value={formData.week} onChange={handleInputChange} />
          <button type="button" className="button" onClick={() => submitEdit(formData.id)}>Edit Holiday</button>
        </form>
      </>) : (<>
{loggedInAdmin ? <> 
<h5 style={{textAlign:'center'}}>Add Holiday</h5>
      <hr/>

      <AddHoliday fetchHolidayData={fetchHolidayData}/>
      </>: null}
   </> )}



   {showErrorMessage && (
    <div className={`side-pop-message-div`}>
        <i
          className={`side-pop-message ${
            message === ('Holiday Deleted' || 'Holiday Edited')
              ? "sucess-message"
              : "error-message"
          }`}
        >
          {message}
          </i>
          </div>
)}
      </>
  );
};

export default Holidays;
