import React, { useEffect, useState } from "react";
import "./Profile.css";
import { FaFacebook, FaInstagram, FaSignOutAlt, FaTwitter, FaUserEdit } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import EditProfile from "./EditProfile";
import { useNavigate, useParams } from "react-router-dom";
import Logout from '../LogOut/Logout'
import { ProfileLoader } from "../Loaders/Loader";
import { useAPI } from "../Context/APIProvider ";
import AdminEditProfile from "./AdminEditProfile";

const Profile = () => {
  const {apiData} = useAPI();
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintesting = JSON.parse(localStorage.getItem("logintesting"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
  const name=loggedInadmin ? loggedInadmin.name :loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name:logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name: null;
  
  const navigate = useNavigate();

  const [details, setDetails] = useState([]);
  const [Admindetails, setAdminDetails] = useState([]);
  const [openEditForm, setOpenEditForm] = useState(false)
  const [openAdminEditForm, setOpenAdminEditForm] = useState(false)
  const [logoutPopUp, setLogoutPopUp] = useState(false)
  const [loading, setloading] = useState(true)

  useEffect(() => {
    fetch(`https://empadmin.hola9.com/account/loginprofile/?name=${name}`)
      .then((response) => response.json())
      .then((data) => {
        setloading(false)
        setDetails(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [name]);

const organization = loggedInUser
    ? loggedInUser.organization
    : logintesting
      ? logintesting.organization
      : loginbackend
        ? loginbackend.organization
        : logintl
          ? logintl.organization
          : logintltest
            ? logintltest.organization
            : loginbackendtl
              ? loginbackendtl.organization
              : loggedInadmin
                ? loggedInadmin.name
                : null;
  useEffect(() => {
    fetch(`https://empadmin.hola9.com/account/adminapiget/?organization=${organization}`)
      .then((response) => response.json())
      .then((data) => {
        setloading(false)
        setAdminDetails(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [name]);


  return (      

    <>
    {loading && <ProfileLoader/>}
      <div className="Profile-container">
        {!loggedInadmin ? <>
          {!apiData.length < 1 ? (
    apiData.map((user) => (
        <div className="profile-dash-container" onClick={() => setOpenEditForm(false)}>
          <div className="profile-dash-card">
            <img 
            className="border"
            src={user.image === null ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeUj1ciE6wDturbVa82fUvijTvwHFWrG7SPw&s" :user.image.replace("http://66.179.250.38:8005/", "https://empadmin.hola9.com/") } alt={user.name === null ? name : user.name}
             />
            <div className="profile-card-details">
              <span>
                <h2 title="Surya Narayana">{user.name === null ? name : user.name}               <FaUserEdit title="Edit Profile" className="profile-edit-icon" onClick={(e) => {setOpenEditForm(true); e.stopPropagation()}} />
                 </h2>
                <p>{user.email === null ? '' : user.email}</p>
              </span>
              <span className="profile-dash-socail">
              {user.facebook_link !== null ? (
                <FaFacebook title="Facebook" onClick={() => window.open(user.facebook_link, "_blank")}
                    />
                  ) : null}
                 {user.insta_link !== null  ? (<FaInstagram title="Instagram" onClick={() => window.open(user.insta_link, "_blank")}
                    />
                  ) : null}
                {user.linkedin_link !== null  ? (<FaLinkedinIn title="Linked in"  onClick={() => window.open(user.linkedin_link, "_blank")}
                    />
                  ) : null}
                 {user.twitter_link !== null  ? (<FaTwitter title="twitter" onClick={() => window.open(user.twitter_link, "_blank")}
                    />
                  ) : null}
                </span>
            </div>
          </div>

          <div className="profile-dash-details p-d-d">
            <h5>Personal</h5>
            <div className="profile-dash-details-row">
              <span>
                <h6>Date of Birth</h6>
                <p>{user.dob === null ? '-' : user.dob}</p>
              </span>
              <span>
                <h6>Blood Group</h6>
                <p>{user.blood_group === null ? '-' : user.blood_group}</p>
              </span>
              <span>
                <h6>Phone Number</h6>
                <p>{user.phone_number === null ? '-' : user.phone_number}</p>
              </span>
              <span>
                <h6>Marital Status</h6>
                <p>{user.marital_status === null ? '-' : user.marital_status}</p>
              </span>
              <span>
                <h6>Gender</h6>
                <p>{user.gender === null ? '-' : user.gender}</p>
              </span>
            </div>
          </div>

          <div className="profile-dash-details">
            <h5>Professional</h5>
            <div className="profile-dash-details-row">
              <span>
                <h6>Employee ID</h6>
                <p>{user.userid === null ? '-' : user.userid}</p>
              </span>
              <span>
                <h6>Reporting</h6>
                <p>{user.reporting_to === null ? '-' : user.reporting_to}</p>
              </span>
              <span>
                <h6>Designation</h6>
                <p>{user.role === null ? '-' : user.role}</p>
              </span>
              <span>
                <h6>Joining Date</h6>
                <p>{user.working_since === null ? '-' : user.working_since}</p>
              </span>
            </div>
          </div>
          <div className="profile-dash-details">
            <h5>Leaves</h5>
            <div className="profile-dash-details-row">
              <span>
                <h6>Earned_Leave</h6>
                <p>{user.earned_leave === null ? '-' : user.earned_leave}</p>
              </span>
              <span>
                <h6>Sick_Leave</h6>
                <p>{user.sick_leave === null ? '-' : user.sick_leave}</p>
              </span>
              <span>
                <h6>Casual_Leave</h6>
                <p>{user.casual_leave === null ? '-' : user.casual_leave}</p>
              </span>
              
            </div>
          </div>

          <div className="profile-dash-details">
            <h5>Projects</h5>
            <div className="profile-dash-Projects">
              <span>
                {user.project_name === null ? "-" : 
                <p>Hola9 &nbsp; | &nbsp; on going</p>
                }
              </span>
              {/* <span>
                <p>HRMS &nbsp; | &nbsp; on going</p>
              </span>
              <span>
                <p>Services website &nbsp; | &nbsp; 09-10-23 to 05-01-24</p>
              </span> */}
            </div>
          </div>
          
          
          <div className="Profile-mobile-options content-hover" onClick={() => {navigate(`/EditProf/${ user.name}`)}}>
          <FaUserEdit />
          <h5>Edit Profile</h5>
          </div>
          <div className="Profile-mobile-options content-hover" onClick={() => {setLogoutPopUp(!logoutPopUp)}} style={{marginTop:'0', marginBottom:'100px'}}>
            <FaSignOutAlt />
          <h5>Logout</h5>
          </div>
        </div>
        ))):("No Profiles yet added")}</>
: <>
        {Admindetails.map((user) => (
 <div className="profile-dash-container" onClick={() => setOpenAdminEditForm(false)}>
 <div className="profile-dash-card">
   <img 
   className="border"
   src={user.image === null ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeUj1ciE6wDturbVa82fUvijTvwHFWrG7SPw&s" :user.logo } alt={user.name === null ? name : user.name}
    />
   <div className="profile-card-details">
     <span>
       <h2 title="Surya Narayana">{user.name === null ? name : user.name}               <FaUserEdit title="Edit Profile" className="profile-edit-icon" onClick={(e) => {setOpenAdminEditForm(true); e.stopPropagation()}} />
        </h2>
       <p>{user.email === null ? '' : user.email}</p>
       <p>{user.social_links.insta_link}</p>
     </span>
     <span className="profile-dash-socail">
     {user.social_links?.facebook_link !== null ? (
       <FaFacebook title="Facebook" onClick={() => window.open(user.social_links.facebook_link, "_blank")}
           />
         ) : null}
        {user.social_links?.insta_link !== null  ? (<FaInstagram title="Instagram" onClick={() => window.open(user.social_links.insta_link, "_blank")}
           />
         ) : null}
       {user.social_links?.linkedin_link !== null  ? (<FaLinkedinIn title="Linked in"  onClick={() => window.open(user.social_links.linkedin_link, "_blank")}
           />
         ) : null}
        {user.social_links?.twitter_link !== null  ? (<FaTwitter title="twitter" onClick={() => window.open(user.social_links.twitter_link, "_blank")}
           />
         ) : null}
       </span>
   </div>
 </div>

 <div className="profile-dash-details p-d-d">
   <h5>Organization</h5>
   <div className="profile-dash-details-row">
     <span>
       <h6>Organization</h6>
       <p>{user.organization === null ? '-' : user.organization}</p>
     </span>
     <span>
       <h6>Company Type</h6>
       <p>{user.company_type === null ? '-' : user.company_type}</p>
     </span>
     <span>
       <h6>Company Since</h6>
       <p>{user.company_since === null ? '-' : user.company_since}</p>
     </span>
     <span>
       <h6>Employees</h6>
       <p>{user.no_of_employees === null ? '-' : user.no_of_employees}</p>
     </span>
     <span>
       <h6>Domain</h6>
       <p>{user.domain === null ? '-' : user.domain}</p>
     </span>
   </div>
 </div>

 <div className="profile-dash-details">
   <h5>Company Info</h5>
   <div className="profile-dash-Projects">
     <span>
       {user.company_type === null ? "-" : 
       <p>{` Company Type : ${user.company_type}` }</p>
       }
     </span>
     <span>
     {user.company_since === null ? "-" : 
     <p>{` Company Since : ${user.company_since }` }</p>
       }
     </span>
     <span>
     {user.tagline === null ? "-" : 
     <p>{` Tagline : ${user.tagline}` }</p>
       }
     </span>
     <span>
     {user.about === null ? "-" :
     <p>{` About : ${user.about}` }</p>
       }
     </span>
   </div>
 </div>
 
 
 <div className="Profile-mobile-options content-hover" onClick={() => {navigate(`/editUserProfile/${user.name}`)}}>
 <FaUserEdit />
 <h5>Edit Profile</h5>
 </div>
 <div className="Profile-mobile-options content-hover" onClick={() => {setLogoutPopUp(!logoutPopUp)}} style={{marginTop:'0', marginBottom:'100px'}}>
   <FaSignOutAlt />
 <h5>Logout</h5>
 </div>
</div>
        ))}</>
      }
        {openEditForm === true ? 
        <form className="profile-edit-container border container-background">
            <EditProfile />
        </form>
        : null}
        {openAdminEditForm === true ? 
        <form className="profile-edit-container border container-background">
            <AdminEditProfile />
        </form>
        : null}
      </div>
      {logoutPopUp ? 
      <div className="logout-pop" onClick={() => setLogoutPopUp(false)}>
      <div className="lunchpop-container container-background logout-pop-container" onClick={(e) => e.stopPropagation()}>
        <Logout setLogoutPopUp={setLogoutPopUp}/>
        </div>
        </div>
        : null}
    </>

  );
};

export default Profile;
