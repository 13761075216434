import React, { useEffect, useState } from 'react';
import './Projects.css'
import Hola9 from '../images/HOLA9.jpg'
import AddProject from './AddProject';
import { FaHandsHoldingCircle } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

const Projects = ({isActive, setIsActive, activeTab, setActiveTab}) => {

  const navigate = useNavigate()

  const [addProject, setAddProjecct] = useState(false);
  const [LOAD, setLOAD] = useState(false);


    const handleMenuItem = (tabItem) => {
    sessionStorage.setItem("project", tabItem);

        setIsActive(`Projects/${tabItem}`);
        setActiveTab(`Projects/${tabItem}`);
    };
    const [data1, setData1] = useState([]);
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
    const logintl = JSON.parse(localStorage.getItem("logintl"));
    const logintesting = JSON.parse(localStorage.getItem("logintesting"));
    const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
    const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
    const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
    const name=loggedInadmin ? loggedInadmin.name :loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name:logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name: null;
    const organization = loggedInUser
    ? loggedInUser.organization
    : logintesting
    ? logintesting.organization
    : loginbackend
    ? loginbackend.organization
    : logintl
    ? logintl.organization
    : logintltest
    ? logintltest.organization
    : loginbackendtl
    ? loginbackendtl.organization
    : loggedInadmin
    ? loggedInadmin.name
    : null;
    useEffect(() => {
      setLOAD(true);
      const fetchData = async () => {
        try {
          const response = await fetch(`https://empadmin.hola9.com/account/projectdetail/?organization=${organization}`);
          const apiData = await response.json();
          setData1(apiData);
          setLOAD(false);
        } catch (error) {
          setLOAD(true);
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, []);

    return (
        <>
        {loggedInadmin && <>
               <div className='`Projects content-hover add-project-visible' onClick={() => setAddProjecct(!addProject)}>
        
               <img src= {addProject ?'https://cdn-icons-png.freepik.com/256/9426/9426995.png?semt=ais_hybrid' : "https://cdn-icons-png.flaticon.com/512/1004/1004733.png"} alt={`Add Project`}/>
                    <div>
                        <h5 style={{color:addProject ? 'red' : ''}}>{addProject ? 'Close Add Project Tab' : 'Add Project'}</h5>
                    </div>
        </div>

        {addProject && <AddProject/>}
        <div className='`Projects content-hover add-project-visible-768' onClick={() => navigate('/addProject')}>
        <img src="https://cdn-icons-png.flaticon.com/512/1004/1004733.png" alt={`Add Project`}/>
                    <div>
                        <h5>Add Project</h5>
                    </div>
        </div>
        </> }
            {data1.map((data, index) => (
                <div key={index} className={`Projects content-hover ${isActive === `Projects/${data.projectname}` && 'content-active'}`} onClick={() => handleMenuItem(data.projectname)}>
                    <img src={"https://empadmin.hola9.com/"+data.logo} alt={data.projectname}/>
                    <div>
                        <h5>{data.projectname}</h5>
                        <p>{data.description}</p>
                    </div>
                </div>
            ))}
            {data1.length<1 &&!LOAD && 'No Projects Found'}
            {LOAD&&"...loading "}
        </>
    );
};

export default Projects;
