import React from 'react';

const Logout = ({setLogoutPopUp}) => {
  const CheckIn = localStorage.getItem("Check In");
  const handleLogout = (e) => {
    e.preventDefault()
    if(CheckIn){
     alert("Checkout Before logout")
    }else{
      sessionStorage.clear();
      localStorage.clear();
      window.location.reload();
    }
  };

  
    return (
        <>
        <div>
          <h4 onClick={handleLogout} className="content-hover" title="Log out">Log out</h4>
          <h4 onClick={handleLogout} className="content-hover" title="Log out of all accounts">Log out of all accounts</h4>
          <h4 className="content-hover" onClick={() => setLogoutPopUp(false)} title="Cancel">Cancel</h4>
        </div>
        </>
    );
};

export default Logout;

