import React from "react";
import { FaAward } from "react-icons/fa";
import { GiMedal } from "react-icons/gi";
import { TbBooks } from "react-icons/tb";

const HomeAbout = (props) => {
  const data1=props.data
  const names = data1.map((item) => item.company_type);
  const names1 = data1.map((item) => item.no_of_employees);
    const data2=[
        {id:1,icon:<FaAward/>,title:`${names} Platform`},
        {id:2,icon:<TbBooks/>,title:`${names1} Employees`},
        {id:3,icon:<GiMedal/>,title:"Developers,Testers ,BA and Hr"},
    ]

  return (
    <>
     {data1.map((data, index) => (
          <>
    <section className='Home-about-fragment'  id="about">
      <div className="Home-about">
        <h1>About {data.organization}</h1>
        <h2>About {data.organization}</h2>
      </div>
      <div className="Home-about-content">
        <div className="Home-about-content-para">
          <h4>
          {data.about}
                    </h4>
        </div>
        <div className='Home-about-content-icons'>
          {data2.map(item=>(
              <span className="border button">
                <div>{item.icon}</div>
                <h5>{item.title}</h5>
              </span>
            ))}
          
          </div>
      </div>
    </section></>))}
    </>
  );
};

export default HomeAbout;
