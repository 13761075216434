import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo2 from "./hrmsnonbg.png";
import { RiEyeOffFill } from "react-icons/ri";
import { FaEye } from "react-icons/fa";

const Login = ({setActiveButton}) => {
  const [email, setEmail] = useState("");
  const [name, setname] = useState("");
  const [organization, setorg] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isProcessing1, setIsProcessing1] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to manage showing or hiding password

  useEffect(() => {
    setIsProcessing1(true);

    const fetchData = async () => {
      try {
        const response = await fetch('https://empadmin.hola9.com/account/loginEmployee/');
        const apiData = await response.json();
        setData(apiData);
        setIsProcessing1(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsProcessing1(true);

      }
    };

    fetchData();
  }, []);

  const tlNames = data.filter((item) => item.tl).map((item) => item.name);
  const Tesing = data.filter((item) => item.testing).map((item) => item.name);
  const Tesingtl = data.filter((item) => item.testing_tl).map((item) => item.name);
  const Backenddash = data.filter((item) => item.backend).map((item) => item.name);
  const Backendtl = data.filter((item) => item.backend_tl).map((item) => item.name);
  useEffect(() => {
    const companyDomain = "outlook.com";
    const emailRegex = new RegExp(`^[A-Za-z0-9._%+-]+@${companyDomain}$`, "i");
  
    if (!emailRegex.test(email) && email.trim() !== "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }, [email]);

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (emailError) {
      return; // Don't submit if there's an email error
    }
    setIsProcessing(true);
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString();
    try {
      const response = await fetch("https://empadmin.hola9.com/account/loginEmployee/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: email, password, name,organization }),
      });
      const result = await response.json();
      localStorage.setItem("lastlogintime",formattedDate);
      if (tlNames.includes(name)) {
        // Check if the name is a TL
        if (result === true) {
          // If the login is successful, save userpa details in localStorage
          localStorage.setItem("logintl", JSON.stringify({ email, name ,password,organization }));
          window.location.reload();
          // Navigate to TL dashboard
          navigate(`/`);
        } else {
          // If the login fails for the TL, set the login error message
          setLoginError("Invalid Credentials for Team Lead");
        }
      }else if (Tesing.includes(name)) {
        // Check if the name is a TL
        if (result === true) {
          // If the login is successful, save userpa details in localStorage
          localStorage.setItem("logintesting", JSON.stringify({ email, name ,password,organization }));
          window.location.reload();
          // Navigate to TL dashboard
          navigate(`/`);
        } else {
          // If the login fails for the TL, set the login error message
          setLoginError("Invalid Credentials for Team Lead");
        }
      }else if (Tesingtl.includes(name)) {
        // Check if the name is a TL
        if (result === true) {
          // If the login is successful, save userpa details in localStorage
          localStorage.setItem("logintestingtl", JSON.stringify({ email, name ,password,organization }));
          window.location.reload();
          // Navigate to TL dashboard
          navigate(`/`);
        } else {
          // If the login fails for the TL, set the login error message
          setLoginError("Invalid Credentials for Team Lead");
        }
      }else if (Backenddash.includes(name)) {
        // Check if the name is a TL
        if (result === true) {
          // If the login is successful, save userpa details in localStorage
          localStorage.setItem("loginbackend", JSON.stringify({ email, name ,password,organization }));
          window.location.reload();
          // Navigate to TL dashboard
          navigate(`/`);
        } else {
          // If the login fails for the TL, set the login error message
          setLoginError("Invalid Credentials for Team Lead");
        }
      }else if (Backendtl.includes(name)) {
        // Check if the name is a TL
        if (result === true) {
          // If the login is successful, save userpa details in localStorage
          localStorage.setItem("loginbackendtl", JSON.stringify({ email, name ,password,organization}));
          window.location.reload();
          // Navigate to TL dashboard
          navigate(`/`);
        } else {
          // If the login fails for the TL, set the login error message
          setLoginError("Invalid Credentials for Team Lead");
        }
      }else {
        // For non-TL users
        if (result === true) {
          // If the login is successful, save user details in localStorage
          localStorage.setItem("loggedInUser", JSON.stringify({ email, name ,password,organization}));
          // Navigate to the next page
          navigate(`/`);
          window.location.reload();

        } else {
          // If the login fails for non-TL users, set the login error message
          setLoginError("Invalid Credentials");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
    finally {
      // Set the processing state to false when the login request is complete (success or failure)
      setIsProcessing(false);
    }
  };
  
  
  // Check if the user is already logged in (stored in localStorage)
  // useEffect(() => {
  //   const loggedInUser = localStorage.getItem("loggedInUser");
  //   if (loggedInUser) {
  //     const { email, name } = JSON.parse(loggedInUser);
  //     navigate(`/ProjectDetails/${name}`);
  //   }
  // }, []);

  // Function to handle logout
  const handleLogout = () => {
    // Remove user details from localStorage
    localStorage.removeItem("loggedInUser");
  };

  return (
    <>
        <form onSubmit={handleSubmit} className="login-form-container">
<div><img src={logo2} alt="logo"/></div>
          <div className="form-group">
          <label htmlFor="name" className="lab" >Name</label>
            <input
            style={{borderBottomColor: loginError ? 'rgb(255, 0, 0)' : ''}}
              type="text"
              placeholder="Name"
              id="name"
              value={name}
              onChange={(e) => setname(e.target.value)}
              required
              className='input-field'
            />
          </div>
          <div className="form-group">
          <label htmlFor="org" className="lab" >Organization</label>
            <input
            style={{borderBottomColor: loginError ? 'rgb(255, 0, 0)' : ''}}
              type="text"
              placeholder="Organization"
              id="organization"
              value={organization}
              onChange={(e) => setorg(e.target.value)}
              required
              className='input-field'
            />
          </div>
          <div className="form-group">
          <label htmlFor="email" className="lab" >E-mail Address</label>
            <input
            style={{borderBottomColor: (loginError || emailError) ? 'rgb(255, 0, 0)' : ''}}
              type="email"
              placeholder="Outlook"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="input-field"
            />
          </div>
          
          <div className="form-group">
          <label htmlFor="password" className="lab" >Password</label>
          <div className="password-input-container">
            <input
              style={{ borderBottomColor: loginError ? 'rgb(255, 0, 0)' : '' }}
              type={showPassword ? "text" : "password"}
              placeholder="* * * * * *"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="input-field"
            />
           
              {showPassword ? <RiEyeOffFill onClick={() => setShowPassword(!showPassword)}/> : <FaEye onClick={() => setShowPassword(!showPassword)}/>}
          </div>
          </div>
          {loginError && <div className="error" style={{color:"red",fontSize:"70%"}}>Wrong Credentials Please check</div>}

          {/* {loginError && (
            <div className="error" style={{ color: "red" }}>
              {loginError}
            </div>
          )} */}
        {/* {emailError && <div className="error">Enter Outlook Email Id's Only</div>} */}

<div className="LoginForm-buttons">
           <button className="button" type="submit" disabled={isProcessing1}>
            {isProcessing1 ? "Wait..." : isProcessing ? "Processing..." : "Login"}
          </button>
          <button className="button admin-login-button" type="button" onClick={() => setActiveButton("admin")}>Admin Login</button>
          </div>
        </form>
     
    </>
  );
};

export default Login;
