import React, { createContext, useContext, useState, useEffect } from 'react';

const APIContext = createContext();

export const APIProvider = ({ children }) => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
    const logintl = JSON.parse(localStorage.getItem("logintl"));
    const logintesting = JSON.parse(localStorage.getItem("logintesting"));
    const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
    const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
    const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
    const name=loggedInadmin ? loggedInadmin.name :loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name:logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name: null;
  
  const [apiData, setAPIData] = useState([]);

  useEffect(() => {
    // Fetch API data and update state
    const fetchData = async () => {
      try {
        const response = await fetch(`https://empadmin.hola9.com/account/loginprofile/?name=${name}`);
        const data = await response.json();
        setAPIData(data);
      localStorage.setItem("myid",data[0].id);
      } catch (error) {
        console.error('Error fetching API data:', error);
      }
    };

    fetchData();

  }, []);
  const [data, setData] = useState([]);
  useEffect(() => {
    // Check if data has loaded
    if (data.length > 0) {
      // Navigate automatically based on the first entry
      const firstEntry = data[1];
      if (firstEntry[1] === true) {
        window.location.href = firstEntry[3];
    }
    }
  }, [data]);
  useEffect(() => {
    // Fetch data from your API
    fetch('https://script.google.com/macros/s/'+locationcurrent)
      .then(response => response.json())
      .then(data => setData(data.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  return (
    <APIContext.Provider value={{ apiData, data }}>
      {children}
    </APIContext.Provider>
  ); 
};

export const useAPI = () => useContext(APIContext);
export const locationcurrent = "AKfycbxj8ZdfzTZ2KLXvS_1nO2VNfcdhCYlehaPqoylrblFhOrhbubBMVigvrG5TEMjBg88/exec?action=getData";
