import React from 'react';
const HomeCompanies = (props) => {
  const data1=props.data
  return (
    <>
      {data1.map((data, index) => (
      <div className='home-companies-main'>

        <div className='home-companies-sub-one container-background'>
          <img src={data.logo.replace("http://66.179.250.38:8005/", "https://empadmin.hola9.com/")}
/>
        </div>

        <div className='home-companies-sub-two container-background'>
          <div>
          {data.company_type && <span><h6>Comapany Type | </h6><h5>{`${data.company_type}`}</h5></span>}
          {data.company_since && <span><h6>Company Since | </h6><h5>{`${data.company_since}`}</h5></span>}
          {data.domain && <span><h6>Website | </h6><h5><a href={`${data.domain}`} target='_blank'>{`${data.domain}`}</a></h5></span>}
          {data.email && <span><h6>Email | </h6><h5>{`${data.email}`}</h5></span>}
         
          </div>
        </div>
      </div>
      ))}
    </>
  );
};

export default HomeCompanies;