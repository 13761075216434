import React, { useEffect, useState } from 'react';

export const LoadingUrl = () => {
  return (
    <img
      src="https://cdn.dribbble.com/users/115601/screenshots/5356365/loading.gif"
      alt="loading..."
      style={{maxWidth:'100%'}}
    />
  );
};
export const LoadingUrl2 = () => {
  return (
    <img
      src="https://cdn.dribbble.com/users/115601/screenshots/5356365/loading.gif"
      style={{width:'100%'}}
      alt="loading..."
    />
  );
};

const Preloader = () => {

 
  return (
    <>
    
      <img src='https://static.vecteezy.com/system/resources/thumbnails/024/679/369/original/glass-shattered-loader-animation-blue-glass-pieces-text-message-4k-footage-on-white-background-colorful-loading-progress-indicator-with-alpha-channel-transparency-for-ui-ux-web-design-video.jpg'
        style={{width:window.innerWidth, height:window.innerHeight}}
       alt='loader' />
       
    </>
  );
}

export default Preloader;
