import React, { useEffect, useState } from 'react';

const AddHoliday = ({fetchHolidayData}) => {

  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
  const organization = loggedInadmin.name;

      // ---------------- auto hide error popup after 5 seconds ----------------- //
      const [message, setmessage] = useState(null)
      const [showErrorMessage, setShowErrorMessage] = useState(false);
      useEffect(() => {
        const timer = setTimeout(() => {
          setShowErrorMessage(false);
        }, 5000);
        return () => clearTimeout(timer);
      }, [showErrorMessage]);

    const [holidayData, setHolidayData] = useState({
        id: null,
        date: "",
        holidayname: "",
        week: "",
        image: null,
        desc: "",
        organization: organization
      });
    
      const [editMode, setEditMode] = useState(false);
    
      const handleInput = (e) => {
        const { name, value } = e.target;
        setHolidayData({ ...holidayData, [name]: value });
      };
    
      const handleFileChange = (e) => {
        const file = e.target.files[0];
        setHolidayData({ ...holidayData, image: file });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const formData = new FormData();
          formData.append("date", holidayData.date);
          formData.append("holidayname", holidayData.holidayname);
          formData.append("week", holidayData.week);
          formData.append("desc", holidayData.desc);
          formData.append("organization", holidayData.organization);
    
          if (holidayData.image) {
            formData.append("image", holidayData.image);
          }
    
          let url = "https://empadmin.hola9.com/account/holiday/";
          let method = "POST";
    
          if (editMode) {
            url += `${holidayData.id}/`;
            method = "PUT";
          }
    
          const response = await fetch(url, {
            method: method,
            body: formData
          });
    
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
    
          const data = await response.json();
          console.log(data);
          fetchHolidayData();
          setShowErrorMessage(true)
          setmessage('Holiday Added')
        } catch (error) {
          console.error("Error submitting holiday data:", error);
          setShowErrorMessage(true)
          setmessage('Error Adding Holiday',<br/>, 'Please Try again Later')
        }
      };

      
    return (
        <>
           <form onSubmit={handleSubmit} className="form-group2">
          <label>
            Date:</label>
            <input
              type="date"
              name="date"
              value={holidayData.date}
              onChange={handleInput}
              required
            />
          
          <label>
            Holiday Name:</label>
            <input
              type="text"
              name="holidayname"
              value={holidayData.holidayname}
              onChange={handleInput}
              required
            />
          
          <label>
            Days:</label>
            <input
              type="text"
              name="week"
              value={holidayData.week}
              onChange={handleInput}
              required
              placeholder='Monday..'
            />
          
          <label>
            Image: </label>
            <input
              type="file"
              name="image"
              accept="image/*"
              onChange={handleFileChange}
            />
         
          <label>
            Description: </label>
            <textarea
            style={{marginBottom:'5px'}}
              name="desc"
              value={holidayData.desc}
              onChange={handleInput}
              required
            />
         
          <button type="submit" className="button">
            {editMode ? "Update Holiday" : "Add Holiday"}
          </button>
        </form> 




        {showErrorMessage && (
        <i
          className={`side-pop-message ${
            message === 'Holiday Added'
              ? "sucess-message"
              : "error-message"
          }`}
        >
          {message}
          </i>
)}
        </>
    );
};

export default AddHoliday;