import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Notifications from "./Components/Notifications/Notifications";
import BackArrow from "./Components/LandingPage/MenuBar/BackArrow";
import Holidays from "./Components/Services/Holidays/Holidays";
import LandingPage from "./Components/LandingPage/LandingPage";
import EditProfile from "./Components/Profile/EditProfile";
import LoginForm from './Components/LogIn/LoginForm';
import Pricing from './Components/LogIn/AdminPricingCards/Pricing'
import AdminSignup from './Components/LogIn/AdminPricingCards/FreePlan/Adminsignup'
import Loggedout from "./Components/LogOut/Loggedout";
import OfflineListener from "./Components/Network/Offline";
import { NoInternetWarning } from "./Components/Network/Internet";
import Checkcred from "./Components/LogOut/Checkcred";
import ForgotPasswordPage from "./Components/LogIn/AdminPricingCards/Forgetpasswordadmin";
import EmployeeDash from "./Components/SearchEmployee/EmployeeDash";
import Preloader from "./Components/Loaders/Preloader";
import EmployeesList from "./Components/Services/Salaryslip/Employeeslips";
import PaySlip from "./Components/Services/Salaryslip/PaySlip";
import EmployeeEditForm from "./Components/Services/Salaryslip/Employeeslipedit";
import SalarySlip from "./Components/Services/Salaryslip/SalarySlip";
import AddProject from "./Components/Projects/AddProject";
import BrowseNotifications from "./Components/NewsFeed/Browsernofication";
import Int from "./Components/Services/Leaves/Instructai";


function App() {
  const loggedInUser = localStorage.getItem("loggedInUser");
  const loggedInAdmin = localStorage.getItem("loggedInadmin");
  const tlLoggedIn = localStorage.getItem("logintl");
  const testingLoggedIn = localStorage.getItem("logintesting");
  const testingTLLoggedIn = localStorage.getItem("logintestingtl");
  const backendLoggedIn = localStorage.getItem("loginbackend");
  const backendTLLoggedIn = localStorage.getItem("loginbackendtl");

  // Check if any of the specific items are present in local storage
  const hasValidCredentials =
    loggedInUser ||
    loggedInAdmin ||
    tlLoggedIn ||
    testingLoggedIn ||
    testingTLLoggedIn ||
    backendLoggedIn ||
    backendTLLoggedIn;

  return (
    <>
    
    <BrowserRouter>
    <Loggedout/>
    <Checkcred/>
    <OfflineListener/>
    <BrowseNotifications/>
    <Int/>
    {/* <Preloader/> */}
<Routes>
<Route path="/" exact element={hasValidCredentials ? <LandingPage /> : <LoginForm/>} />
<Route
  path="/notifications/"
  exact
  element={
    <>
      <div
        style={{ maxWidth: "600px", margin: "auto", padding: "5%" }}
      >
        <BackArrow name={"Notifications"}/>
        <Notifications />
      </div>
    </>
  }
/>
  <Route path="/holidays/" exact element={
    <>
    <div
      style={{ maxWidth: "600px", margin: "auto", padding: "5%" }}
    >
      <BackArrow name={"Holidays"}/>
      <Holidays />
    </div>
  </>
  } />
  <Route path="/addProject/" exact element={
    <>
    <div
      style={{ maxWidth: "600px", margin: "auto", padding: "5%" }}
    >
      <BackArrow name={"Add New Project"}/>
      <AddProject />
    </div>
  </>
  } />
  <Route path="/EditProf/:names" exact element={
    <>
    <form
      style={{ maxWidth: "600px", margin: "auto", padding: "5%" }}
    >
      <BackArrow name={"Edit User Profile"}/>
      <EditProfile />
    </form>
  </>
  } />
  <Route path="/editUserProfile/:names" exact element={
    <>
    <form
      style={{ maxWidth: "600px", margin: "auto", padding: "5%" }}
    >
      <BackArrow name={"Edit User Profile"}/>
      <EditProfile />
    </form>
  </>
  } />
  <Route path='/Pricing/:id' exact element={<Pricing/>}/>
  <Route path='/Fogotpassword/Verify' exact element={
        <>
        <form
          style={{ maxWidth: "600px", margin: "auto", padding: "5%" }}
        >
          <BackArrow name={"Forgot Password"}/>
  <ForgotPasswordPage/>
  </form>
  </>
  }/>
  <Route path='/Profile/:id' exact element={<EmployeeDash/>}/>
  <Route path='/Nointernet' exact element={<NoInternetWarning/>}/>
  <Route path='/allslips'exact element={<EmployeesList/>}/>
  <Route path='/Printslip/:emp'exact element={<PaySlip/>}/>
  <Route path='/edit/:employeeId'exact element={<EmployeeEditForm/>}/>
  <Route path='/salaryslip'exact element={<SalarySlip/>}/>

  <Route path='/Signup'exact element={
    <div  style={{height:window.innerHeight}}>
    <BackArrow name={"SignUp admin Features - Free"}/>
    <AdminSignup/>
    </div>
  }/>
</Routes>
    </BrowserRouter>            
</>
  );
}

export default App;
