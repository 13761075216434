import React, { useEffect, useState } from 'react';

const AssignTask = () => {

    const [error, seterror] = useState(false);
    const bloodGroupOptions = [
        "Hola9","Matrimony","DatingApp","Realestate","Services","HRMS","Jobportal"
       ];
       const bloodGroupOptions1 = [
        "Hola9","Matrimony","DatingApp","Realestate","Services","Employee login","Jobportal"
       ];
     
       const [taskData, setTaskData] = useState({
         assignee_name: "",
         project_name: "",
         tl_name: "",
         task_name: "",
         due_date: "",
         task_description: "",
         add_photo:null,
         tester : ""
        
       });
     
       const handleInputChange = (e) => {
         const { name, value, type, checked, files } = e.target;
       
         if (type === 'file') {
           // If the input type is file (for image), update the task_image property
           setTaskData((prevData) => ({
             ...prevData,
             [name]: files[0],
           }));
         } else {
           // For other input types, update the state as before
           setTaskData((prevData) => ({
             ...prevData,
             [name]: type === 'checkbox' ? checked : value,
           }));
         }
       };
       
     
       const handleSubmit = (e) => {
        if (!taskData.assignee_name || !taskData.tl_name || !taskData.project_name) {
          alert('Please select values for Assignee Name, TL Name, and Project Name');
          return;
        }
         e.preventDefault();
       
         const formData = new FormData();
         // Append other form data to FormData
         Object.entries(taskData).forEach(([key, value]) => {
           formData.append(key, value);
         });
       
         // Send data to the API
         fetch('https://empadmin.hola9.com/account/assigntaskview', {
           method: 'POST',
           body: formData,
         })
           .then((response) => {
             if (response.ok) {
               // Handle success
               alert('Task submitted successfully');
             } else {
               // Handle errors
               alert('Failed to submit task');
             }
           })
           .catch((error) => {
             console.error('Error:', error);
           });
       };
       const [data, setData] = useState([]);
       
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://empadmin.hola9.com/account/loginEmployee/');
        const apiData = await response.json();
        setData(apiData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const names = data.map((item) => item.name);
  const Testing = data.filter((item) => item.testing).map((item) => item.name);
  const tlNames = data.filter((item) => item.tl).map((item) => item.name);


    return (
        <>
           <form className='form-group2 form-group3'>
            <div>
            <label >
            Task-Name :</label>
            <input
              type="text"
              name="task_name"
              onChange={handleInputChange}
              required
            />
            <label>
            Task-Desc:</label>
            <input
              type="text"
              name="task_description" required
              onChange={handleInputChange}
            /></div>
            <div>
               <label>
            AssigneeName:</label>
            <select name="assignee_name"onChange={handleInputChange} required>
            <option>select</option>
              {names.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            
            <label>
            ProjectTl_Name:</label>
            <select name="tl_name" onChange={handleInputChange} required>
            <option>select</option>
              {tlNames.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select></div>
            
            <div>
            <label>
            Testing_Team:</label>
            <select name="tester" onChange={handleInputChange} required>
            <option>select</option>
              {Testing.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            
            <label>
            Due-Date :</label>
            <input
              type="date"
              name="due_date" required
              onChange={handleInputChange}
            /></div>
            <div>
             <label>
            Project Name:</label>
            <select name="project_name"  onChange={handleInputChange} required>
            <option>select</option>
              {bloodGroupOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <label>Task Image:</label>
  <input type="file" name="add_photo"  onChange={handleInputChange} accept="image/*" /></div>
  
      <button onClick={handleSubmit} className='button'>Assign Task</button>
           </form>
        </>
    );
};

export default AssignTask;