import React, { useEffect, useState } from 'react';
import Hand from "../images/HelloHand.png";
import { FaMapMarked } from 'react-icons/fa';
import { NavProfileLoader } from '../Loaders/Loader';
import { useAPI } from '../Context/APIProvider ';
const NavMenuProfile = ({setIsActive,  setActiveTab}) => {
  const {apiData} = useAPI();

  const [loading, setloading] = useState(false)
 

  const CheckIn = localStorage.getItem("Check In");
  const loation = localStorage.getItem("check in Time");

  const handleProfile = () => {
    setIsActive("Profile")
    setActiveTab('')
  }

  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));

    return (
        <>
           {loading ? <NavProfileLoader/> : <>
             {!apiData.length < 1 ? (
       apiData.map(
        (user) =>(
        <>       
            
             <img
            className="menubar-profile-img"
            src={user.image === null ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeUj1ciE6wDturbVa82fUvijTvwHFWrG7SPw&s" :user.image.replace("http://66.179.250.38:8005/", "https://empadmin.hola9.com/")}
            alt="profile"
            title="profile"
            style={{ border: CheckIn ? "2.5px solid green" : "2.5px solid red"}}
            onClick={handleProfile}/>
        </>
       ))):(
        <>
        {!loggedInadmin&&
        <img
        className="menubar-profile-img"
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS90HxBHJqRkvtgM9Z7RyR3bLV2mlG01SzkgB51gGui1Hewzt6sjpD3FrceNug5R-8nGPA&usqp=CAU"
        alt="profile"
        title="profile"
        onClick={handleProfile}/>}</>
      )}
      {loggedInadmin &&<img
        className="menubar-profile-img"
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS90HxBHJqRkvtgM9Z7RyR3bLV2mlG01SzkgB51gGui1Hewzt6sjpD3FrceNug5R-8nGPA&usqp=CAU"
        alt="profile"
        title="profile"
        onClick={handleProfile}
        />}
          <span className={`menubar-profile-cont ${loation ? 'location-color' : ''}`} >
            <p>
          {loation ?(<><FaMapMarked/> {loation}</>)  : 
          <>
              Good Day
              <img src={Hand} width={13} height={13} alt="Hello-icon" />
              </>
            }
            </p>
            
            <h5 title="profile" onClick={handleProfile}>{loggedInadmin ? 'Admin' : `${apiData.map((user) =>  user.name)}`}</h5>
          </span>

          </>}
        </>
    );
};

export default NavMenuProfile;