import React, { useEffect, useState } from 'react';
import './CheckInLogs.css'
import { FaFileContract } from 'react-icons/fa';
import MapLocationAds from "./MapLocation";
import MapLocationAds1 from "./Maplocation1";
import { ListLoader } from '../../Loaders/Loader';
import CheckIn from './CheckIn';
import ApproveForm from './ApproveForm';

const CheckInLogs = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setloading] = useState(true)
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Loading state added
    const [itemsToLoad, setItemsToLoad] = useState(20);
    const [limitIncreaseCount, setLimitIncreaseCount] = useState(0);
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintesting = JSON.parse(localStorage.getItem("logintesting"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
  const name=loggedInadmin ? loggedInadmin.name :loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name:logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name: null;
    
  const [filters, setFilters] = useState({
      name: "",
      date: "",
    });
    const itemsPerPage = 42; // Number of items to display per page
  
    const [pageNumber, setPageNumber] = useState(0);
    const checkin = localStorage.getItem("Check In");
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(
            `https://empadmin.hola9.com/account/employeelogin2?limit=${itemsToLoad}&name=${name}`
          );
          const apiData = await response.json();
          setData(apiData);
          setFilteredData(apiData);
          setloading(false)
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData(); // Call the async function
    }, [itemsToLoad, name]); // Include itemsToLoad and name in the dependency array
    const handleLoadMore = () => {
      setItemsToLoad(itemsToLoad + 20);
      setLimitIncreaseCount((prevCount) => prevCount + 1); // Increase the number of items to load
      // Increase the number of items to load
    };
    const [prevDataLength, setPrevDataLength] = useState(0);
  
    useEffect(() => {
      const intervalId = setInterval(async () => {
        if (!isLoading && limitIncreaseCount != 3) {
          const newDataLength = data.length;
  
          if (newDataLength !== prevDataLength) {
            handleLoadMore();
            setPrevDataLength(newDataLength);
          } else {
            clearInterval(intervalId); // Stop further calls to handleLoadMore
          }
        }
      }, 2000);
  
      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }, [isLoading, data, prevDataLength, limitIncreaseCount]);
  
    const handleFilterChange = (e) => {
      const { name, value } = e.target;
      const updatedFilters = { ...filters, [name]: value };
      setFilters(updatedFilters);
  
      let filtered = data.filter((employee) => {
        const nameMatch = employee.name
          .toLowerCase()
          .includes(updatedFilters.name.toLowerCase());
        const dateMatch =
          updatedFilters.date === "" ||
          new Date(employee.date).toLocaleDateString() ===
            new Date(updatedFilters.date).toLocaleDateString();
        return nameMatch && dateMatch;
      });
      setFilteredData(filtered);
    };
    const dataall = filteredData.filter((user) => user.name === name);
    const pageCount = Math.ceil(dataall.length / itemsPerPage);
  
    const changePage = ({ selected }) => {
      setPageNumber(selected);
      setLimitIncreaseCount(0);
    };
    const displayedData = dataall.slice(
      pageNumber * itemsPerPage,
      (pageNumber + 1) * itemsPerPage
    );

    const convertMinutesToHours = (minutes) => {
      const totalMinutes = parseInt(minutes, 10);
      const hours = Math.floor(totalMinutes / 60);
      const remainingMinutes = totalMinutes % 60;
      return `${hours}h ${remainingMinutes}m`;
    };
    const currentTime = new Date();
    
    return (
        <>
        {loading ? <ListLoader/> : 
<>
           <div className='Reports-Top'>
            <h5> <FaFileContract className='Report-top-icon' />CheckIn Reports <span className='error-message'>{name}</span></h5>
            <CheckIn/>
           </div>

           <div className='reportsscroll-div'>
           <table className='Reports-table' id="employeeTable">
            <thead className='Reports-thead container-background'>
                <tr className='Reports-tr'>
                <th>ID</th>
            <th>
              <input
                type="date"
                name="date"
                value={filters.date}
                onChange={handleFilterChange}
                style={{ width: "90%", padding:'5px', outline:'none', fontSize:'100%'}}
                className='border'
              />
            </th>
            <th>Check-in</th>
            <th>Lunch Break</th>
            <th>Check-Out</th>
            <th>Total Time</th>
                </tr>
            </thead>
            <tbody  className='Reports-thead container-background'>
            {displayedData
            .sort((a, b) => b.id - a.id)
            .map((employee) => (
              <tr key={employee.id} className='Reports-tr'>
                 {employee.approved === true ? (<td>{employee.id}</td>):(<td style={{color:"red"}}>{employee.id}</td>)}
                <td>{employee.date.split('T')[0]}</td>
                <td>
                  {employee.login_time}
                  {employee.lattiude1 && employee.longitude1 ? (
                    <>
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${employee.lattiude1},${employee.longitude1}`}
                        target="_blank"
                        style={{
                          fontSize: "80%",
                          padding: "3px 10px",
                          marginLeft: "3%",
                        }}
                      >
                        See On Map
                      </a>
                      <MapLocationAds
                        id={employee.id}
                        name={name}
                        props={{
                          lati: employee.lattiude1,
                          long: employee.longitude1,
                        }}
                      />
                    </>
                  ) : (
                    <>No location found</>
                  )}
                </td>
                <td>
                  {employee.lunch_break_checkin && (
                    <p>{`${employee.lunch_break_checkin}`}</p>
                  )}
                  {employee.lunch_break_checkout && (
                    <p>{`${employee.lunch_break_checkout}`}</p>
                  )}
                </td>
                <td>
                  {employee.logout_time}
                  {employee.lattiude2 && employee.longitude2 ? (
                    <>
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${employee.lattiude2},${employee.longitude2}`}
                        target="_blank"
                        style={{
                          fontSize: "80%",
                          padding: "3px 10px",
                          marginLeft: "3%",
                        }}
                      >
                        See On Map
                      </a>
                      <MapLocationAds1
                        id1={employee.id}
                        props1={{
                          lati1: employee.lattiude2,
                          long1: employee.longitude2,
                        }}
                      />
                    </>
                  ) : (
                    <>No location found</>
                  )}
                </td>
               {employee.approved === true ? (
    <td>{convertMinutesToHours(employee.total_time)}</td>
) : (
    <>
        <td 
           
        >
          {employee.date.split('T')[0] !== currentTime.toISOString().split('T')[0] ? (
            <>
            <small style={{color:"red"}}>Absent</small><br/>
  <span className='button'  onClick={() => setShowPopup(true)}>Approve from HR</span> </>
) : (
  "No data"
)}
        </td>
        {showPopup && 
      <div className="popupContainer-center" onClick={() => setShowPopup(!showPopup)} style={{zIndex:'2'}}>
      <div className='popupContainer-center-sub logout-popup box-shadow' onClick={(e) => e.stopPropagation()}>
        <ApproveForm dataid={employee.id}/>
        </div>
        </div>
      }
    </>
)}
              </tr>
            ))}
        </tbody>
           </table>
           </div>
           </>
      }


        </>
    );
};

export default CheckInLogs;