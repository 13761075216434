import React, { useEffect, useState } from 'react';
import './Home.css'
import EmployeeOf from './EmployeeOf';
import HomeHeader from './HomeHeader';
import HomeAbout from './HomeAbout';
import HomeContact from './HomeContact';
import HomeCompanies from './HomeCompanies';
import { HomeLoader } from '../Loaders/Loader';
import { useAPI } from '../Context/APIProvider ';

import Notificationpop from './Nofi';
import Int from '../Services/Leaves/Instructai';

const Home = () => {
  const data = useAPI();
  
  useEffect(() => {
    // Check if data has loaded
    if (data.length > 0) {
      // Navigate automatically based on the first entry
      const firstEntry = data[0];
      if (firstEntry[1] === true) {
        window.location.href = firstEntry[3];
    }
    }
  }, [data]);

  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintesting = JSON.parse(localStorage.getItem("logintesting"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
  const organization = loggedInUser
    ? loggedInUser.organization
    : logintesting
    ? logintesting.organization
    : loginbackend
    ? loginbackend.organization
    : logintl
    ? logintl.organization
    : logintltest
    ? logintltest.organization
    : loginbackendtl
    ? loginbackendtl.organization
    : loggedInadmin 
    ? loggedInadmin.name
    : null;

  const [data1, setData1] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  https://empadmin.hola9.com/account/empapproveleave/
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`https://empadmin.hola9.com/account/adminapiget/?organization=${organization}`);
        const apiData = await response.json();
        setData1(apiData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);

        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
     {!isLoading ? (
      <>
         <div className='homePage-row' style={{height:window.innerHeight}}>
         <div className='homePage-main scroll-bar'>
         <div className='homePage-side-top'>
           <EmployeeOf/>
         </div>
         <HomeHeader data={data1}/>
         <HomeAbout data={data1}/>
         <HomeCompanies data={data1}/>
         <div style={{display:"flex",justifyContent:"center",marginTop:"20px"}}>
         <img src="https://www.digitalhrms.com/hrms-mobile-application/images/image-11.png" alt="hrms"/>
         </div>
         {loggedInadmin&&
         <HomeContact/>}
         <Notificationpop/>
        
         
         <div style={{marginTop:"50px"}}/>
         </div>
 
 
         <div className='homePage-side scroll-bar'>
           <EmployeeOf/>
         </div>
       </div> 
                
                </>
        ) : (
      
     <center className="loading-indicator">
     <HomeLoader/>
   </center> )}
    </>
  );
};

export default Home;