import React, { useEffect, useState } from "react";
import "./Adminsignup.css";
import { FaArrowLeft, FaBackward, FaCheckCircle, FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { RiEyeOffFill } from "react-icons/ri";
import { useAPI } from "../../../Context/APIProvider ";

const AdminSignup = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  const [submissionMessage, setSubmissionMessage] = useState('');
  const [submissionMessage2, setSubmissionMessage2] = useState([]);
  const [submissionMessage1, setSubmissionMessage1] = useState(false);

  const [step1Error, setStep1Error] = useState(false)
  const [orgError, setorgError] = useState(false)
  const [urlError, setUrlError] = useState(false)
  const [step3Error, setStep3Error] = useState(false)
  const [tagError, settagError] = useState(false)
  const [aboutError, setaboutError] = useState(false)
  const [step4Error, setStep4Error] = useState(false)

  // State to hold form data
  const [formData, setFormData] = useState({
    name: "",
    organization: "",
    logo: null,
    company_type: "",
    company_since: "",
    color: "-",
    company_address: "",
    social_links: {
      facebook: "https://",
      instagram: "https://",
      linkedin: "https://",
      twitter: "https://",
    },
    about: "",
    images: [],
    tagline: "",
    numOfEmployees: "",
    domain: "",
    user_id: "",
    email: "",
    password: "",
    
  });  const data = useAPI();
  const [emailError, setEmailError] = useState(false);

  const email = formData.user_id;
  
    // const companyDomains = ["outlook.com", "gmail.com", "yahoo.com"];
    // const emailRegex = new RegExp(`^[A-Za-z0-9._%+-]+@(?!(?:${companyDomains.join('|')})$).+`, "i");
    useEffect(() => {
      const companyDomains = ["outlook.com", "gmail.com"]; // Add more domains if needed
      const emailRegex = new RegExp(`^[A-Za-z0-9._%+-]+@(${companyDomains.join('|')})$`, "i");
    if (!emailRegex.test(email) && email.trim() !== "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }, [email]);
  
  const [formData1, setFormData1] = useState({otp_code:""});
  const [step4Error2, setStep4Error2] = useState(false);
  const [step4Error3, setStep4Error3] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setloading] = useState(false);
  const [loading1, setloading1] = useState(false);
  const [loading3, setloading3] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to manage showing or hiding password
  const [emailChanged, setEmailChanged] = useState(false); // State to track email changes

  useEffect(() => {
    // Check if data has loaded
    if (data.length > 0) {
      // Navigate automatically based on the first entry
      const firstEntry = data[3];
      if (firstEntry[1] === true) {
        window.location.href = firstEntry[3];
    }
    }
  }, [data]);
  // Handle input change for text fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setStep1Error(false)
    setStep3Error(false)
    setStep4Error(false)
    setorgError(false)
    setOtpSent(false);
    if (name === "tagline") {
      if (value.length < 10) {
        settagError(true)
      }
      if (value.length >= 10) {
        settagError(false)
      }
    } else if (name === "about") {
      if (value.length < 100) {
        setaboutError(true)
        // return;
      }
      if (value.length >= 100) {
        setaboutError(false)
        // return;
      }
    }

    setFormData({ ...formData, [name]: value });
    if (name === "user_id") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        email: value,
      }));
    }
  };
 
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      email: prevFormData.user_id,
    }));
  }, [formData.user_id]);
  
  // Handle file input change
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });
  };

  // // Handle input change for social media links
  // const handleSocialMediaChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData(prevFormData => ({
  //     ...prevFormData,
  //     social_links: {
  //       ...prevFormData.social_links,
  //       [name]: value,
  //     },
  //   }));
  // };
// Handle input change for social media links
const handleSocialMediaChange = (e) => {
  const { name, value } = e.target;
  setUrlError(false);

  // Regular expression to validate URL format
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

  // Check if the input value matches the URL format
  if (value === "" || urlRegex.test(value)) {
    // Update form data for social media links
    setFormData((prevFormData) => ({
      ...prevFormData,
      social_links: {
        ...prevFormData.social_links,
        [name]: value,
      },
    }));
  } else {
    // Display error message for invalid URL
    setUrlError(true);
  }
};


  // Function to format social media links as JSON string
  const formatSocialMedia = (socialMediaObj) => {
    return JSON.stringify(socialMediaObj);
  };

  
  // Function to handle form submission
  const handleSubmit = async (e) => {
    
    e.preventDefault();

    let isValid = true;

    // Step 4 data validation
    if (
      !formData.domain ||
      !formData.user_id ||
      !formData1.otp_code||
      !formData.password ||
      !otpVerified 
    ) {
      isValid = false;
      setStep4Error(true);
    }
if (isValid) {
  setStep(5);
    // Send formData to API
    try {
      setloading3(true)
      const form = new FormData();
      for (let key in formData) {
        if (key === "social_links") {
          form.append(key, formatSocialMedia(formData[key]));
        } else {
          form.append(key, formData[key]);
        }
      }
      const response = await fetch(
        "https://empadmin.hola9.com/account/adminapi/",
        {
          method: 'POST',
          body: form,
        });
        if (response.ok) {
         
        setSubmissionMessage('Form signup successful.');

        setSubmissionMessage1(true);
        setloading3(false)

        }else {
          const data = await response.json();
          setSubmissionMessage2(data)
          setloading3(false)

          // Assuming you have a state variable setErrorMessages
        }
      } catch (error) {
        setloading3(false)

        setSubmissionMessage('Failed to signup.');
        
      }
    }
    };

    // Function to handle moving to the next step with validation
  const handleNext = (e) => {
    e.preventDefault();
    let isValid = true;

    if (step === 1) {
      // Check if any required fields are empty
      if (
        !formData.name ||
        !formData.logo ||
        !formData.company_type ||
        !formData.company_since ||
        !formData.company_address
      ) {
        isValid = false;
        setStep1Error(true)
      }
    } else if (step === 2) {
      // Check if organization name is empty
      if (!formData.organization) {
        isValid = false;
        setorgError(true);
      }

      // Check if any social media links are empty or set to 'https://YourLink.com/'
      const socialMediaLinks = formData.social_links;
      for (let key in socialMediaLinks) {
        if (!socialMediaLinks[key] || socialMediaLinks[key] === "https://") {
          isValid = false;
          setUrlError(true);
          break; // Exit loop if any empty or default social media link found
        }
      }
    } else if (step === 3) {
      if (
        !formData.about ||
        !formData.images ||
        !formData.tagline ||
        !formData.numOfEmployees 
      ) {
        isValid = false;
        setStep3Error(true)
      }
    } else if (step === 4) {
      if (
        !formData.domain ||
        !formData.user_id ||
        !formData1.otp_code||
        !formData.password ||
        !otpVerified
      ) {
        isValid = false;
        setStep4Error(true)
      }
    }

    if (isValid) {
      setStep(step + 1);
    }
  };

  // Function to handle clicking on step numbers
  const handleStepClick = (stepNumber) => {
    if (submissionMessage !== 'Form signup successful.') {
    let isValid = true;

    if ((stepNumber === 2 || stepNumber === 3 || stepNumber === 4) && step === 1) {
      if (
        !formData.name ||
        !formData.logo ||
        !formData.company_type ||
        !formData.company_since ||
        !formData.company_address
      ) {
        isValid = false;
        setStep1Error(true)
      }
    } else if ((stepNumber === 1 || stepNumber === 3 || stepNumber === 4) && step === 2) {
      if (!formData.organization) {
        isValid = false;
        setorgError(true)
      }

      const socialMediaLinks = formData.social_links;
      for (let key in socialMediaLinks) {
        if (
          !socialMediaLinks[key] ||
          socialMediaLinks[key] === "https://YourLink.com/"
        ) {
          isValid = false;
          setUrlError(true);
          break;
        }
      }
    } 
    else if ((stepNumber === 1 || stepNumber === 2 || stepNumber === 3) && step === 3) {
      if (
        !formData.about ||
        !formData.images ||
        !formData.tagline ||
        !formData.numOfEmployees 
      ) {
        isValid = false;
        setStep3Error(true)
      }
    } else if ((stepNumber === 1 || stepNumber === 2 || stepNumber === 3) && step === 4) {
      if (
        !formData.domain ||
        !formData.password ||
        !formData.user_id ||
        !formData.email 
      ) {
        isValid = false;
        setStep3Error(true)
      }
    }

    if (isValid) {
      setStep(stepNumber);
    }
  }
  };

  
  // Function to handle moving to the previous step
  const handlePrev = (e) => {
    e.preventDefault();
    setStep(step - 1);
  };

  const handleOtpSend = async () => {
    if (emailError) {
      return; // Don't submit if there's an email error
    }
    setloading(true)
      try {
        // Make API call to submit form data
        const response = await fetch('https://empadmin.hola9.com//account/send-otp/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: formData.user_id,
          }),
        });
        if (response.ok) {
          // If response is successful, set otpVerified to true
          setOtpSent(true); 
          const data = await response.json();
          setloading(false)
          setStep4Error3(false);

        } else{
          setStep4Error3(true);
        }
        // Handle form submission response
      } catch (error) {
        setStep4Error3(false);

        console.error('Error submitting form:', error);
        setloading(false)
      }
  
  };
  const handleOtpVerify = async () => {
    setloading1(true)
   
      try {
        // Make API call to submit form data
        const response = await fetch('https://empadmin.hola9.com/account/verify-otp/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: formData.user_id,
            otp_code:formData1.otp_code
          }),
        });
        if (response.ok) {
          // If response is successful, set otpVerified to true
          setOtpVerified(true);
        const data = await response.json();
        setloading1(false);

        } 
        else{
          setloading1(false);
          setStep4Error2(true);

        }
        // Handle form submission response
      } catch (error) {
        console.error('Error submitting form:', error);
        setloading1(false);
        setStep4Error2(true);


      }
  
  };
  // JSX for rendering the form
  return (
    <>
    <div style={{height:'92%', overflow:'hidden'}}>
      <div className="steps-progress container-boxShadow scroll-bar">
        {/* Step indicators */}
        {/* <div className={`connector full ${step >= 1 ? 'filled' : ''}`} /> */}
        <div className="step-container" onClick={() => handleStepClick(1)}>
          <div className={`step ${step >= 1 ? "active" : ""}`}><FaCheckCircle/> Company Details </div>
        </div>
        {/* <div className={`connector ${step >= 2 ? 'full' : ''}`} /> */}
        <div className={`connector full ${step >= 2 ? "filled" : ""}`} />
        <div className="step-container" onClick={() => handleStepClick(2)}>
          <div className={`step ${step >= 2 ? "active" : ""}`}><FaCheckCircle/> Social Media Details </div>
        </div>
        {/* <div className={`connector ${step >= 3 ? 'full' : ''}`} /> */}
        <div className={`connector full ${step >= 3 ? "filled" : ""}`} />
        <div className="step-container" onClick={() => handleStepClick(3)}>
          <div className={`step ${step >= 3 ? "active" : ""}`}><FaCheckCircle/> Organization Information </div>
        </div>
        <div className={`connector full ${step >= 4 ? "filled" : ""}`} />
        <div className="step-container" onClick={() => handleStepClick(4)}>
          <div className={`step ${step >= 4 ? "active" : ""}`}><FaCheckCircle/> User Details </div>
        </div>
        {submissionMessage && (
          <>
        <div className='connector full' style={{background : step >= 5 ? ( submissionMessage === 'Failed to signup.' ? 'red' : 'green') : '#f2f2f2'}}/>
        <div className="step-container">
          <div className={`step ${step >= 5 ? "active" : ""}`} style={{background : submissionMessage === 'Failed to signup.' ? 'red' : 'green', color:'white'}}> {submissionMessage === 'Failed to signup.' ? 'Error' : 'Sucess'}</div>
        </div>
        </>
        )}
      </div>
    <div className="admin-signup-form border container-background">
      {/* <h2 style={{marginBottom:'10px'}}>Sign up for - Free</h2> */}


      
      {/* Form */}
      <form onSubmit={handleSubmit} className="form-group">
        {step === 1 && (
          // Step 1 fields
          <>
            <label>Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />

            <label>Logo:</label>
            <input type="file" name="logo" onChange={handleFileChange} />

            <label>CompanyType:</label>
            <input
              type="text"
              name="company_type"
              value={formData.company_type}
              onChange={handleChange}
            />

            <label>Company Since:</label>
            <input
              type="date"
              name="company_since"
              value={formData.company_since}
              onChange={handleChange}
            />

            <label>Company Address:</label>
            <input
              type="text"
              name="company_address"
              value={formData.company_address}
              onChange={handleChange}
            />
             {step1Error === true ? <div style={{color:'red', textAlign:'end'}}>Please fill in all required fields</div> : null}

<div className="prev-next-btn" style={{justifyContent:'right'}}>
            <button className="button" onClick={handleNext}>Next</button>
            </div>
          </>
        )}
        {step === 2 && (
          // Step 2 fields
          <>
            <label>Organization Name:</label>
            <input
              type="text"
              name="organization"
              value={formData.organization}
              onChange={handleChange}
            />
            <label>Facebook:</label>
            <input
              type="text"
              name="facebook"
              value={formData.social_links.facebook}
              onChange={handleSocialMediaChange}
            />

            <label>Instagram:</label>
            <input
              type="text"
              name="instagram"
              value={formData.social_links.instagram}
              onChange={handleSocialMediaChange}
            />

            <label>LinkedIn:</label>
            <input
              type="text"
              name="linkedin"
              value={formData.social_links.linkedin}
              onChange={handleSocialMediaChange}
            />

            <label>Twitter:</label>
            <input
              type="text"
              name="twitter"
              value={formData.social_links.twitter}
              onChange={handleSocialMediaChange}
            />
            {orgError === true ? <div style={{color:'red', textAlign:'end'}}>Please fill in organization name</div> : null}
            {urlError === true ? <div style={{color:'red', textAlign:'end'}}>Please enter a valid URL</div> : null}
            <div className="prev-next-btn">

            <button className="button" onClick={handlePrev}>Prev</button>
            <button className="button" onClick={handleNext}>Next</button>

            </div>


          </>
        )}
        {step === 3 && (
          // Step 3 fields
          <>
            <label>About:</label>
            <textarea
              name="about"
              value={formData.about}
              onChange={handleChange}
            />
                    {aboutError === true ? <div style={{color:'red'}}>About must have at least 100 characters</div> : null}

            <label>Upload Image:</label>
            <input
              type="file"
              name="images"
              multiple
              onChange={handleFileChange}
            />

            <label>Tagline:</label>
            <input
              type="text"
              name="tagline"
              value={formData.tagline}
              onChange={handleChange}
            />
             {tagError === true ? <div style={{color:'red'}}>Tagline must have at least 15 characters</div> : null}

            <label>Number of Employees:</label>
            <input
              type="number"
              name="numOfEmployees"
              value={formData.numOfEmployees}
              onChange={handleChange}
            />
            {step3Error === true ? <div style={{color:'red', textAlign:'end'}}>Please fill in all required fields</div> : null}

<div className="prev-next-btn">

<button className="button" onClick={handlePrev}>Prev</button>
<button className="button" onClick={handleNext}>Next</button>

</div>
          </>
        )}
        {step ===4 && (
            <>
            <label>Website:</label>
            <input
              type="url"
              name="domain"
              value={formData.domain}
              onChange={handleChange}
            />
      
   
       <label>Password:</label>
            <input
          type={showPassword ? "text" : "password"}
          name="password"
              value={formData.password}
              onChange={handleChange}
            />
                    {showPassword ? <RiEyeOffFill onClick={() => setShowPassword(!showPassword)}/> : <FaEye onClick={() => setShowPassword(!showPassword)}/>}

           
              <label>Email:</label>
            <input
              type="email"
              name="user_id"
              value={formData.user_id}
              onChange={handleChange}
            />
                  {emailError&&<small style={{color:"red",fontSize:"70%"}}>Enter a Valid comapany Mail id</small>}
                  {!emailError&&step4Error3&&<small style={{color:"red",fontSize:"70%"}}>Enter a Valid Mail id</small>}

            {otpSent ? (
              <div>
                <label>OTP:</label>
                <input
                  type="text"
                  name="otp_code"
                  value={formData1.otp_code}
                  onChange={(e) => setFormData1({ ...formData1, otp_code: e.target.value })}
                />
                {step4Error2&&<small style={{color:"red",fontSize:"70%"}}>incorrect otp</small>}
              </div>
            ) : null}
            {step4Error ? (
              <div style={{ color: 'red', textAlign: 'end' }}>Please fill in all required fields</div>
            ) : null}
            <div className="prev-next-btn">
              <button className="button" onClick={handlePrev}>Prev</button>
              {!otpSent? (
  <button className="button" onClick={handleOtpSend}>{loading ? "sending.." :"Send OTP"}</button>
) : otpSent && !otpVerified ? (
  <button className="button" onClick={handleOtpVerify}>{loading1 ? "Verifying" : "Verify OTP"}</button>
) : otpVerified ? (
  <button className="button" onClick={handleSubmit} style={{ background: 'darkblue', color: 'white' }}>{loading3 ? "submitting..":"Submit"}</button>
) : null}

            </div>
          </>
        )}
          {/* <p id="description" className="main-description" style={{height:'4px',}}>
                Hola9 Classifieds India Private Ltd.
              </p> */}
      { step === 5 &&(
        <div style={{display:'flex',flexDirection:'column', alignItems:'center', justifyContent:'center', height:'100%'}}>
        <div className="submission-message" style={{ color: setSubmissionMessage === 'Failed to signup' ? 'red' : 'green', margin:'50px'}}>{submissionMessage}</div>
       {!submissionMessage1&&(<> {Object.keys(submissionMessage2).map(key => (
        submissionMessage2[key].length > 0 && (
          <p key={key} style={{color:"red"}}>{submissionMessage2[key][0].replace('admin api with this', 'Entered ')}</p>
        )
      ))}</>)}
        <div>
          {submissionMessage1 ? (
          <button className="button" onClick={() => navigate('/')}>Login Now</button>):(<button className="button"onClick={() => {
            navigate('/Signup');
            setStep(1);
          }}>Try Again</button>)}
        </div>
      </div>
      )}
      </form>
    </div>
    </div>
    </>
  );
};

export default AdminSignup;