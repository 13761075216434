import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UesrDashboard.css';
import {TasksLoader} from '../../Loaders/Loader'
import TaskDetailsPopup from './TaskDetailsPopup';
import AssignTask from './AssignTask';
import { FaEllipsisV, FaSearchMinus, FaTasks, FaTrashAlt } from 'react-icons/fa';
import { Progress } from '../../Loaders/Progress';

const TaskCardPage = (props) => {
  const name = props.name
  const [searchName, setSearchName] = useState('');
  const [searchName1, setSearchName1] = useState('');

  const [taskTab, setTaskTab] = useState(false);

  const [tasks, setTasks] = useState([]);
  const [search, setSearch] = useState('');
  const [currentTab, setCurrentTab] = useState('todo');
  const [selectedTask, setSelectedTask] = useState(null); // Track the selected task for the popup
  const [tasksToShow, setTasksToShow] = useState(8);
  const [loading, setLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(false); 
   // Add loading state


  useEffect(() => {
    // Fetch data from the API
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://empadmin.hola9.com/account/assigntaskview?${searchName ? `&assignee_name=${searchName}` : ''}`);
      setTasks(response.data);
      setLoading(false); // Set loading to false when data is loaded

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const handleButtonClick = async (taskId, fieldName) => {
    setIsLoading1(true);
    const updatedTasks = tasks.map((task) => {
      if (task.id === taskId) {
        return { ...task, [fieldName]: true };
      }
      return task;
    });

    try {
      // Assuming you are using axios for API calls
      await axios.put(`https://empadmin.hola9.com/account/assigntaskview/${taskId}`, {
        [fieldName]: true,
      });

      // Update local state to reflect the changes
      setTasks(updatedTasks);
      setIsLoading1(false);
    } catch (error) {
      setIsLoading1(false);

      console.error('Error updating data:', error);
    }
  };

  const filterTasks = () => {
    const filteredTasks = tasks.filter((task) => {
      // Filter tasks based on the search input
      const taskDetails = `${task.id} ${task.task_name} ${task.tl_name} ${task.project_name} ${task.task_description} ${task.push_code}`;
      return taskDetails.toLowerCase().includes(search.toLowerCase());
    });
    switch (currentTab) {
      // case 'Notdone':
      //   return tasks.filter((task) => !task.push_code && !task.dev_review && !task.task_done && task.push_code && task.dev_review && task.task_done);
      case 'todo':
        return filteredTasks.slice(0, tasksToShow).filter((task) => !task.task_done);
      case 'all':
        return filteredTasks.slice(0, tasksToShow);
      case 'devReview':
        return filteredTasks.slice(0, tasksToShow).filter((task) => task.task_done && task.push_code && !task.dev_review);
      case 'Deployment':
        return filteredTasks.slice(0, tasksToShow).filter((task) => task.task_done && task.dev_review && !task.deployment);
      case 'Re_Testing':
        return filteredTasks.slice(0, tasksToShow).filter((task) => task.task_done && task.re_deployment && !task.re_testing);
      case 'Testing':
        return filteredTasks.slice(0, tasksToShow).filter((task) => task.task_done && task.deployment && !task.testing);
      case 'Re_Deployment':
        return filteredTasks.slice(0, tasksToShow).filter((task) => task.task_done && task.testing && !task.re_deployment);
      case 'testing_bug':
        return filteredTasks.slice(0, tasksToShow).filter((task) => task.task_done && task.re_testing && !task.testing_bug);
      case 'pushCode':
        return filteredTasks.slice(0, tasksToShow).filter((task) => task.task_done && !task.push_code);
      case 'done':
        return filteredTasks.slice(0, tasksToShow).filter((task) => task.push_code && task.dev_review && task.task_done && task.deployment && task.testing && task.re_deployment && task.re_testing);
      case 'pending':
        return filteredTasks.slice(0, tasksToShow).filter((task) => task.overdue_duedate);
      default:
        return filteredTasks.slice(0, tasksToShow);
    }
  };
  const handleLoadMore = () => {
    // Increment the number of tasks to display by 20
    setTasksToShow((prevTasksToShow) => prevTasksToShow + 8);
  };
  const openPopup = (task) => {
    setSelectedTask(task);
  };

  const closePopup = () => {
    setSelectedTask(null);
  };
  const handleSearchByName = () => {
    fetchData();
    // setItemsToLoad(30);
    setLoading(true); // You can also perform additional logic here if needed
  };
  const [data1, setData1] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://empadmin.hola9.com/account/loginEmployee/');
        const apiData = await response.json();
        setData1(apiData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  const names = data1.map((item) => item.name);
  const names1 = data1.map((item) => item.tl);
  const handleSelectChange = (event) => {
    setSearchName(event.target.value);
    setSearchName1(event.target.value);
  };
  // delete logic start

  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://empadmin.hola9.com/account/assigntaskview//${id}`);
      fetchData(); // Refetch data after successful deletion
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };
  return (
    <div className="task-card-page">
 <div className={taskTab ? 'container-background' : ''}>
<div className='Reports-Top' onClick={() => setTaskTab(!taskTab)}>
      <h5 style={{cursor:'pointer', color:taskTab ? 'darkred' : ''}}>
        <FaTasks className='Report-top-icon'/>
        {taskTab ? 'Close Task Tab' : 'Assign Task'}
        </h5>
      </div>

     {taskTab && <AssignTask/> }
        
      </div>


      <div className='tasks-filter Reports-Top'>
      <h5>
      <select
          value={searchName}
          onChange={handleSelectChange}
          placeholder='select name' style={{ width: "100%", borderRadius: "10px" }}
        >
          <option value="">Select Name</option>
          {names.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>
        <FaSearchMinus className='Report-top-icon'  onClick={handleSearchByName} cursor='pointer'/>
        </h5>
      </div>


      {loading ? (
        <TasksLoader/>
      ) : (
        <>
          <div className='tasks-filter Reports-Top'>
      <h5>
        <input
          type="text" className='filin'
          placeholder="Search tasks by Name, Description, Title..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <FaSearchMinus className='Report-top-icon'/>
        </h5>
      </div>
          
          <div className="tasks-tabs scroll-bar">
            {/* <button
          className={currentTab === 'Notdone' ? 'active' : ''}
          onClick={() => setCurrentTab('Notdone')}
        >
          All task
        </button> */}
            <button
              className={currentTab === 'all' ? 'active' : ''}
              onClick={() => setCurrentTab('all')}
            >
              All Task
            </button>
            <button
              className={currentTab === 'todo' ? 'active' : ''}
              onClick={() => setCurrentTab('todo')}
            >
              ToDo
            </button>

            <button
              className={currentTab === 'pushCode' ? 'active' : ''}
              onClick={() => setCurrentTab('pushCode')}
            >
              Push Code
            </button>
            <button
              className={currentTab === 'devReview' ? 'active' : ''}
              onClick={() => setCurrentTab('devReview')}
            >
              Dev Review
            </button>
            <button
              className={currentTab === 'Deployment' ? 'active' : ''}
              onClick={() => setCurrentTab('Deployment')}
            >
              Deployment
            </button>
            <button
              className={currentTab === 'Testing' ? 'active' : ''}
              onClick={() => setCurrentTab('Testing')}
            >
              Testing
            </button>
            <button
              className={currentTab === 'Re_Deployment' ? 'active' : ''}
              onClick={() => setCurrentTab('Re_Deployment')}
            >
              Re_Deployment
            </button>
            <button
              className={currentTab === 'Re_Testing' ? 'active' : ''}
              onClick={() => setCurrentTab('Re_Testing')}
            >
              Re_Testing
            </button>
            <button
              className={currentTab === 'testing_bug' ? 'active' : ''}
              onClick={() => setCurrentTab('testing_bug')}
            >
              Testing_bug
            </button>

            <button
              className={currentTab === 'done' ? 'active' : ''}
              onClick={() => setCurrentTab('done')}
            >
              Done
            </button>
            <button
              className={currentTab === 'pending' ? 'active' : ''}
              onClick={() => setCurrentTab('pending')}
            >
              Pending
            </button>
            {/* <div className="line" style={{ width: `${100 / 4}%` }} /> */}
          </div>
          <div class="task-cards-container" >
            {filterTasks().length === 0 ? (
              <div style={{ textAlign: "center", padding: "70px" }}>Nothing in {currentTab}.</div>
            ) : (

              filterTasks().slice(0, tasksToShow).map((task) => (
                <div key={task.id} className="task-card container-background" >
                  <div className='task-card-h3'><h3>{`${task.id}) ${task.task_name}`}</h3><FaEllipsisV onClick={() => openPopup(task.id)} style={{cursor:'pointer'}}/> </div>
                      <p>Assignee: {task.assignee_name}</p>
                      <p>Project: {task.project_name}</p>
                      <p>Tl_name: {task.tl_name}</p>
                      <p>Due Date: {task.due_date}</p>
                    {task.addphoto_base64 && <img src={`data:image/png;base64,${task.addphoto_base64}`} alt="Task" style={{ height: "150px", width: "150px" }} />}
                    {/* {loggedInadmin || loginbackendtl || logintl || logintltest ? <span className='btn2000' style={{display: "flex",float:"left",marginLeft:"40px",position:"relative",top:"-5px",color:"red"}} onClick={() => handleDelete(task.id)}><FaTrashAlt  /></span> : null} */}
                 
                  <div className='buttoncont'>
                    <button
                      className={task.task_done ? 'done' : 'not-done'}
                      onClick={() => handleButtonClick(task.id, 'task_done')}
                    >
                      Task Done
                    </button>
                    <button
                      className={task.push_code ? 'done' : 'not-done'}
                      onClick={() => handleButtonClick(task.id, 'push_code')}
                    >
                      Push Code
                    </button>
                    <button
                      className={task.dev_review ? 'done' : 'not-done'}
                      onClick={() => handleButtonClick(task.id, 'dev_review')}
                    >
                      Dev Review
                    </button>
                    <button
                      className={task.deployment ? 'done' : 'not-done'}
                      onClick={() => handleButtonClick(task.id, 'deployment')}
                    >
                      Deployment
                    </button>
                    <button
                      className={task.testing ? 'done' : 'not-done'}
                      onClick={() => handleButtonClick(task.id, 'testing')}
                    >
                      Testing
                    </button>
                    <button
                      className={task.re_deployment ? 'done' : 'not-done'}
                      onClick={() => handleButtonClick(task.id, 're_deployment')}
                    >
                      Re_Deployment
                    </button>
                    <button
                      className={task.re_testing ? 'done' : 'not-done'}
                      onClick={() => handleButtonClick(task.id, 're_testing')}
                    >
                      Re_testing
                    </button>
                    <button
                      className={task.testing_bug ? 'done' : 'not-done'}
                      onClick={() => handleButtonClick(task.id, 'testing_bug')}
                    >
                      Testing_bug
                    </button>

                  </div>

                </div>

              ))

            )}</div>
          {/* {filterTasks().length > tasksToShow && ( */}
          {filterTasks().length > 7 && (
            <div style={{ textAlign: 'center', margin: '20px' }}>
              <button onClick={handleLoadMore} className="taskbtn2001">
                Load More
              </button>
            </div>
          )}
        </>)}
      {/* )} */}
      {selectedTask && (
      <div className="popupContainer-center3" onClick={() => setSelectedTask(!selectedTask)} style={{zIndex:'2'}}>
        <div className='popupContainer-center-sub3 box-shadow' onClick={(e) => e.stopPropagation()}>
        <TaskDetailsPopup task={selectedTask} onClose={closePopup} />
        <hr/>
        {loggedInadmin || loginbackendtl || logintl || logintltest ? <h4 className="content-hover"
        onClick={() => handleDelete(selectedTask)} style={{border:'none'}}
        >Delete</h4> : null}
                <hr/>
        <h4 onClick={() => setSelectedTask(!selectedTask)} className="content-hover">Cancel</h4>
        </div>
        </div>
      )}
        {isLoading1&&<Progress/>}
      
    </div>
  );
};

export default TaskCardPage;
