import React, { useEffect, useState } from 'react';
import './Report.css'
import { useNavigate } from "react-router-dom";
import axios from "axios";

const DailyReports = () => {

  const [reportError, setReportError] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowErrorMessage(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [showErrorMessage]);


  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintesting = JSON.parse(localStorage.getItem("logintesting"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
  const name=loggedInadmin ? loggedInadmin.name :loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name:logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name: null;
  const id=loggedInadmin ? loggedInadmin.name :loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name:logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name: null;
  

    const [startDate, setStartDate] = useState(null);
    const [id1, setid] = useState(id);
    const [task, settask] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedDropdown, setSelectedDropdown] = useState('');
    const [selectedRadio, setSelectedRadio] = useState('');
    const [comment, setcomment] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false)


    const handleDropdownChange = (e) => {
        setSelectedDropdown(e.target.value);
      };
    
      const handleRadioChange = (e) => {
        setSelectedRadio(e.target.value);
      };
    
      const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
      };
     const organization = loggedInUser
      ? loggedInUser.organization
      : logintesting
      ? logintesting.organization
      : loginbackend
      ? loginbackend.organization
      : logintl
      ? logintl.organization
      : logintltest
      ? logintltest.organization
      : loginbackendtl
      ? loginbackendtl.organization
      : null;
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading2(true)
        if (
          !id1 ||
          !task ||
          !startDate ||
          !selectedDropdown ||
          !selectedRadio ||
          !comment ||
          !selectedFile
        ) {
          alert('Please fill out all required fields.');
          return;
        } 
        const formData = new FormData();
        formData.append('name', name);
        formData.append('task', task);
        formData.append('task_date', startDate);
        formData.append('report_of_work', selectedDropdown);
        formData.append('completion_status', selectedRadio);
        formData.append('comment', comment);
        formData.append('documnet', selectedFile);
        formData.append('organization', organization);
        try {
            await axios.post('https://empadmin.hola9.com/account/detailEmployee/', formData, {
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(percentCompleted);
              },
            });
      
            console.log('Data sent successfully');
            localStorage.setItem('namerepo', id);
            setReportError('Hello Coder Your Reaport submitted successfully!')
            setShowErrorMessage(true);
            setLoading2(false)
            window.location.reload();
        }catch (error) {
            console.error('Error sending data:', error);
            setLoading2(false)
            setShowErrorMessage(true);
            setReportError(<> Report not updated <br/> Please try again later</>)
        }}

    return (
        <div style={{height:window.innerHeight, overflow:'scroll'}} className='scroll-bar'>
                <h3 className='Daily-reperts-heading'>Submit Your Reports</h3>
            <div className='Daily-reperts'>
                <form onSubmit={handleSubmit}>
                    <div>
                    <label htmlFor="task" id="task-label">
              Task Description
            <input
              type="text"
              name="task"
              id="task"
              className="task"
              placeholder="Enter your Task"
              required // Add the required attribute
              onChange={(e) => settask(e.target.value)}
              />
              </label>

              <label htmlFor="dropdown" id="dropdown-label">
              Report of Work?
            <select
              name="dropdown"
              id="dropdown"
              className="dropdown"
              required // Add the required attribute
              value={selectedDropdown}
              onChange={handleDropdownChange}
              style={{borderRadius:'7px'}}
            >
              <option value="">Select an option</option>
              <option value="Daily">Daily</option>
              <option value="2_days">2 days</option>
              <option value="3_days">3 days</option>
              <option value="Weekly">Weekly</option>
            </select>
              </label>
                    </div>

                    <div>
                    <label>Select a date 
              <input
                type="date"
                placeholder="From Date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              </label>
              <label>
              Work Status
              <span>
              <label className="radio-btn-label" htmlFor="25-50-percent">
                <input
                  type="radio"
                  id="25-50-percent"
                  name="completion-status"
                  className="completion-status"
                  value="25%"
                  checked={selectedRadio === '25%'}
                  onChange={handleRadioChange}
                  required
                />
                25% - 50%
              </label>
              <label className="radio-btn-label" htmlFor="50-75-percent">
                <input
                  type="radio"
                  id="50-75-percent"
                  name="completion-status"
                  className="completion-status"
                  value="50%"
                  checked={selectedRadio === '50%'}
                  onChange={handleRadioChange}
                  required 
                />
                50% - 75%
              </label>
              <label className="radio-btn-label" htmlFor="75-95-percent">
                <input
                  type="radio"
                  id="75-95-percent"
                  name="completion-status"
                  className="completion-status"
                  value="75%"
                  checked={selectedRadio === '75%'}
                  onChange={handleRadioChange}
                  required 
                />
                75% - 95%
              </label>
              <label className="radio-btn-label" htmlFor="100-percent">
                <input
                  type="radio"
                  id="100-percent"
                  name="completion-status"
                  className="completion-status"
                  value="100%"
                  checked={selectedRadio === '100%'}
                  onChange={handleRadioChange}
                  required 
                />
                Completed
              </label>
              </span>
            </label>
                    </div>

                    <label>File Upload
            <input
              type="file"
              className="col-lg-12"
              accept=".zip, .jpg, .jpeg, .png, .pdf, .xls, .xlsx, .doc, .docx, .mp4, .avi, .mkv, .mov, .wmv"
              onChange={handleFileChange}
              required // Add the required attribute
            />
           {loading && (
              <div className="loading-overlay">
                <img src="https://i.gifer.com/origin/a1/a1d81f564eeb1468aefbcfd54d1571b8_w200.gif" alt='processing'/>
              </div>
            )}
            {uploadProgress > 0 && uploadProgress < 100 && (
              <div className="progress-bar-container">
                <div className="daily-roprts-progress-bar" style={{ width: `${uploadProgress}%` }}>
                  {uploadProgress}%
                </div>
              </div>
            )}
            </label>

            <label htmlFor="comments">Your comments / suggestions
            <textarea
              name="comments"
              id="comments"
              cols={30}
              rows={5}
              style={{height:'30px'}}
              placeholder="Enter your suggestions here"
              defaultValue={''}
              onChange={(e) => setcomment(e.target.value)}
            />
            </label>

            <button type="submit" className='button' style={{padding:'9px', cursor:loading2 && 'not-allowed'}} disabled={loading2} >{loading2 ? 'Submitting Report...' : 'Submit'}</button>
                </form>
            </div>


            {showErrorMessage && (
        <i
          className={`side-pop-message ${
            reportError === 'Hello Coder Your Reaport submitted successfully!'
              ? "sucess-message"
              : "error-message"
          }`}
        >
          {reportError}
          </i>
)}
        </div>
    );
};

export default DailyReports;