import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Notificationpop=()=>{
 const MyComponent0 = () => {
    const handleClick = () => {
        toast.success('Notification message!', {
            position: 'top-right' // Set position directly as a string
        });
      };

  return (
    <div>
      <h1>My Component</h1>
      <button onClick={handleClick}>Show Notification</button>
      <ToastContainer />
      <div style={{marginTop:"50px"}}/>
    </div>
  );
};


 const MyComponent1 = () => {
    const handleClick = () => {
        if (Notification.permission === 'granted') {
          new Notification('Notification message 1!', { body: 'This is the body of the notification.' });
        } else if (Notification.permission !== 'denied') {
          Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
              new Notification('Notification message 1!', { body: 'This is the body of the notification.' });
            }
          });
        }
    }
  return (
    <div>
      <h1>My Component</h1>
      <button onClick={handleClick}>Show Notification</button>
    </div>
  );
};

 const MyComponent2 = () => {
    const handleClick1 = () => {
        if (Notification.permission === 'granted') {
          new Notification('Notification message 2!', { body: 'This is the body of the notification.' });
        } else if (Notification.permission !== 'denied') {
          Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
              new Notification('Notification message 2!', { body: 'This is the body of the notification.' });
            }
          });
        }
      };
  
    return (
      <div>
        <h1>My Component</h1>
        <button onClick={handleClick1}>Show Notification</button>
      </div>
    );
  };
  
  const MyComponent3 = () => {
    useEffect(() => {
      const scheduleNotification = () => {
        const now = new Date();
        const scheduledTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 13, 10, 0); // Set the time for 13:10
        const timeUntilNotification = scheduledTime - now;
  
        if (timeUntilNotification > 0) {
          setTimeout(() => {
            if (Notification.permission === 'granted') {
              new Notification('Scheduled Notification', { body: 'It\'s 13:10!' });
            } else if (Notification.permission !== 'denied') {
              Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                  new Notification('Scheduled Notification', { body: 'It\'s 13:10!' });
                }
              });
            }
          }, timeUntilNotification);
        }
      };
  
      scheduleNotification();
    }, []);
  
    return (
      <div>
        <h1>My Component</h1>
        <p>Notification will be scheduled for 13:10</p>
      </div>
    );
  };
}
export default Notificationpop;