import React, { useEffect, useState } from "react";
import './Home.css'
import { services } from "../LandingPage/MenuBar/DummyData";
import { LoadingUrl2 } from "../Loaders/Preloader";

const EmployeeOf = () => {
  const [details, setDetails] = useState([]);
  const [loading, setloading] = useState(true)
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintesting = JSON.parse(localStorage.getItem("logintesting"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
  const name=loggedInadmin ? loggedInadmin.name :loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name:logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name: null;
  const organization = loggedInUser
  ? loggedInUser.organization
  : logintesting
  ? logintesting.organization
  : loginbackend
  ? loginbackend.organization
  : logintl
  ? logintl.organization
  : logintltest
  ? logintltest.organization
  : loginbackendtl
  ? loginbackendtl.organization
  : null;
  const currentTime = new Date();
  const [data2, setData2] = useState([]);
  const [isLoading1, setIsLoading2] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading2(true);
      try {
        const response = await fetch(`https://empadmin.hola9.com/account/empapproveleave/`);
        const apiData = await response.json();
        setData2(apiData);
        setIsLoading2(false);
      } catch (error) {
        setIsLoading2(false);

        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    fetch(`https://empadmin.hola9.com/account/wanted/`)
      .then((response) => response.json())
      .then((data) => {
        setDetails(data);
        setloading(false)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [organization]);
    const EmployeOfMonth = (props) => {
      const data=props.data;

  return (

    <>
    {data.map((data) => data.event_type === "employee_of_month" ? (
      <div className="employee-of-card container-background border">
        <h1>{data.event_type}</h1>
        <p>THANK YOU FOR YOUR OUTSTANDING PERFORMANCE</p>
        <q>
        {data.description}
        </q>
        <div className="profile-image-container">
        {data.images.map((data, index) => (
                <img
                  src={"https://empadmin.hola9.com/" +data.image}
                  alt="profile"
                />))}
          <span>
            <h2>{data.name}</h2>
            <h6>{data.title}</h6>
          </span>
        </div>
      </div>
    ):null)}
    </>
  );
};

const EmployeOfWeek = (props) => {
  const data=props.data;

  return (

    <>
    {data.map((data) => data.event_type === "employee_of_week" ? (
          <div className="employee-of-card container-background border">
            {loading ? <>.....Loading</>:(<>
            <h1>{data.event_type}</h1>
            <p>THANK YOU FOR YOUR OUTSTANDING PERFORMANCE</p>
            <q>
            {data.description}
            </q>
            <div className="profile-image-container">
            {data.images.map((data, index) => (
                <img
                  src={"https://empadmin.hola9.com/" +data.image}
                  alt="profile"
                />))}
              <span>
              <h2>{data.name}</h2>
            <h6>{data.title}</h6>
              </span>
            </div></>)}
          </div>)
          :null)}
        </>
      );
    };

    const Birthday = (props) => {
      const data=props.data;

      return (
    
        <>
        {data.map((data) => data.event_type === "employee_birthday" && data.date == currentTime.toISOString().split('T')[0]? (
              <div className="employee-of-card container-background border">
                {loading ? <>.....Loading</>:(<>
              <h1>{data.event_type}</h1>
              <p>Happy Bithday✨</p>
              <q>
              {data.description}
              </q>
              <div className="profile-image-container">
              {data.images.map((data, index) => (
                <img
                  src={"https://empadmin.hola9.com/" +data.image}
                  alt="profile"
                />))}
                <span>
                <h2>{data.name}</h2>
              <h6>{data.title}</h6>
                </span>
              </div></>)}
            </div>
            ):null)}
            </>
          );
        };

    const Festival = (props) => {
      const data=props.data;

      return (
    
        <>
        {data.map((data) => data.event_type === "festival" && data.date == currentTime.toISOString().split('T')[0]? (
          <>
          {data.images.map((data, index) => (
           
              <div className="employee-of-card container-background border" style={{minHeight:'150px',  background :"https://empadmin.hola9.com/" +data.image }}>
           
              </div> ))}
             </>):null)}</>
          );
        };
  
      const names = data2.map((item) => item.date === currentTime.toISOString().split('T')[0]);
      return ( 
    <>
   
    <Festival data={details}/>
    <Birthday  data={details}/>
    <EmployeOfWeek  data={details}/>
    <EmployeOfMonth  data={details}/>
      {/* {leave} */}{names.length < 0 && (<><p>Employees who are on leave today</p>

        <div className="employee-of-card container-background border">
        {data2.map((data) => data.date === currentTime.toISOString().split('T')[0] ? (
          <li style={{fontSize:"80%",color:"red"}}>{data.name}</li>):null)}
        </div></>)}

       
      </>
)

}

export default EmployeeOf;
