import React, { useState } from "react";
import axios from "axios";

const AddGallery = ({ close }) => {

    const [formData, setFormData] = useState({
        id: "",
        images: [],
        video: "",
        description: "",
        organization: "",
        date: "",
      });
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleVideoUpload = (e) => {
        const file = e.target.files[0];
        const videoUrl = URL.createObjectURL(file);
        setFormData({
          ...formData,
          video: videoUrl,
        });
      };
    
      const handleImageUpload = (e) => {
        const files = e.target.files;
        const imageFiles = [];
    
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          imageFiles.push({ image: file.name }); // Push the file name
        }
    
        const updatedImages = [...formData.images, ...imageFiles];
    
        setFormData({
          ...formData,
          images: updatedImages,
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const formDataToSend = new FormData();
          formDataToSend.append("id", formData.id);
          formDataToSend.append("description", formData.description);
          formDataToSend.append("organization", formData.organization);
          formDataToSend.append("date", formData.date);
          formDataToSend.append("video", e.target.video.files[0]);
          for (let i = 0; i < formData.images.length; i++) {
            formDataToSend.append("images", e.target.images.files[i]);
          }
    
          const response = await axios.post(
            "https://empadmin.hola9.com/account/multiplimages/",
            formDataToSend
          );
    
          console.log(response.data);
          close();
          alert("Uploded Successfully");
          // handle success, maybe show a success message
        } catch (error) {
          alert("Uplod failed");
          console.error("Error:", error);
          // handle error, maybe show an error message
        }
      };
      const handleRemoveImage = (indexToRemove) => {
        const updatedImages = formData.images.filter(
          (_, index) => index !== indexToRemove
        );
        setFormData({
          ...formData,
          images: updatedImages,
        });
      };


    return (
        <>
             <div 
             onClick={(e) => e.stopPropagation()}
      style={{
        background: "white",
        padding: "20px",
        borderRadius: "10px",
        width: "70%",
        height: "auto",
      }}
    >
      <form
        onSubmit={handleSubmit}
        className="form-group "
      >
        <label>
        Description:</label>

          <input
            type="text"
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        
        <label>
        Title :</label>
          <input
            type="text"
            name="organization"
            value={formData.organization}
            onChange={handleChange}
          />
        

        <label>
          Date:</label>
          <input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
          />
        

        <label>
          Video:</label>
          <input
            type="file"
            name="video"
            accept="video/*"
            onChange={handleVideoUpload}
          />
        

        {/* {formData.video && (
        <video width="320" height="240" controls>
          <source src={formData.video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )} */}

        <label>
          Images:</label>
          <input
            type="file"
            name="images"
            accept="image/*"
            multiple
            onChange={handleImageUpload}
            required
          />
        

        {/* {formData.images.map((imageUrl, index) => (
        <img key={index} src={imageUrl.image} alt={`Image ${index + 1}`} />
      ))} */}

        <button type="submit" className="button" style={{marginTop:'10px'}}>Submit</button>
      </form>
    </div>
        </>
    );
};

export default AddGallery;