import React from 'react';
import ContentLoader from 'react-content-loader';

export const ListLoader = () => {
    return (
        <>
            <ContentLoader
    speed={0.5}
    viewBox="0 0 1500 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="27" y="139" rx="4" ry="4" width="20" height="20" />
    <rect x="67" y="140" rx="10" ry="10" width="85" height="19" />
    <rect x="188" y="141" rx="10" ry="10" width="169" height="19" />
    <rect x="402" y="140" rx="10" ry="10" width="85" height="19" />
    <rect x="523" y="141" rx="10" ry="10" width="169" height="19" />
    <rect x="731" y="139" rx="10" ry="10" width="85" height="19" />
    <rect x="852" y="138" rx="10" ry="10" width="85" height="19" />
    <rect x="1424" y="137" rx="10" ry="10" width="68" height="19" />
    <rect x="26" y="196" rx="4" ry="4" width="20" height="20" />
    <rect x="66" y="197" rx="10" ry="10" width="85" height="19" />
    <rect x="187" y="198" rx="10" ry="10" width="169" height="19" />
    <rect x="401" y="197" rx="10" ry="10" width="85" height="19" />
    <rect x="522" y="198" rx="10" ry="10" width="169" height="19" />
    <rect x="730" y="196" rx="10" ry="10" width="85" height="19" />
    <rect x="851" y="195" rx="10" ry="10" width="85" height="19" />
    <circle cx="1456" cy="203" r="12" />
    <rect x="26" y="258" rx="4" ry="4" width="20" height="20" />
    <rect x="66" y="259" rx="10" ry="10" width="85" height="19" />
    <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
    <rect x="401" y="259" rx="10" ry="10" width="85" height="19" />
    <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />
    <rect x="730" y="258" rx="10" ry="10" width="85" height="19" />
    <rect x="851" y="257" rx="10" ry="10" width="85" height="19" />
    <circle cx="1456" cy="265" r="12" />
    <rect x="26" y="316" rx="4" ry="4" width="20" height="20" />
    <rect x="66" y="317" rx="10" ry="10" width="85" height="19" />
    <rect x="187" y="318" rx="10" ry="10" width="169" height="19" />
    <rect x="401" y="317" rx="10" ry="10" width="85" height="19" />
    <rect x="522" y="318" rx="10" ry="10" width="169" height="19" />
    <rect x="730" y="316" rx="10" ry="10" width="85" height="19" />
    <rect x="851" y="315" rx="10" ry="10" width="85" height="19" />
    <circle cx="1456" cy="323" r="12" />
    <rect x="26" y="379" rx="4" ry="4" width="20" height="20" />
    <rect x="66" y="380" rx="10" ry="10" width="85" height="19" />
    <rect x="187" y="381" rx="10" ry="10" width="169" height="19" />
    <rect x="401" y="380" rx="10" ry="10" width="85" height="19" />
    <rect x="522" y="381" rx="10" ry="10" width="169" height="19" />
    <rect x="730" y="379" rx="10" ry="10" width="85" height="19" />
    <rect x="851" y="378" rx="10" ry="10" width="85" height="19" />
    <circle cx="1456" cy="386" r="12" />
    <rect x="978" y="138" rx="10" ry="10" width="169" height="19" />
    <rect x="977" y="195" rx="10" ry="10" width="169" height="19" />
    <rect x="977" y="257" rx="10" ry="10" width="169" height="19" />
    <rect x="977" y="315" rx="10" ry="10" width="169" height="19" />
    <rect x="977" y="378" rx="10" ry="10" width="169" height="19" />
    <rect x="1183" y="139" rx="10" ry="10" width="85" height="19" />
    <rect x="1182" y="196" rx="10" ry="10" width="85" height="19" />
    <rect x="1182" y="258" rx="10" ry="10" width="85" height="19" />
    <rect x="1182" y="316" rx="10" ry="10" width="85" height="19" />
    <rect x="1182" y="379" rx="10" ry="10" width="85" height="19" />
    <rect x="1305" y="137" rx="10" ry="10" width="85" height="19" />
    <rect x="1304" y="194" rx="10" ry="10" width="85" height="19" />
    <rect x="1304" y="256" rx="10" ry="10" width="85" height="19" />
    <rect x="1304" y="314" rx="10" ry="10" width="85" height="19" />
    <rect x="1304" y="377" rx="10" ry="10" width="85" height="19" />
    <circle cx="37" cy="97" r="11" />
    <rect x="26" y="23" rx="5" ry="5" width="153" height="30" />
    <circle cx="1316" cy="88" r="11" />
    <rect x="1337" y="94" rx="0" ry="0" width="134" height="3" />
    <circle cx="77" cy="96" r="11" />
  </ContentLoader>
        </>
    );
};

export const ProfileLoader = () => {
  return (
    <ContentLoader
    viewBox="0 0 260 160"
    speed={2}
  >
    <circle cx="50" cy="30" r="30" />
    <rect x="10" y="70" rx="3" ry="3" width="40" height="10" />
    <rect x="60" y="70" rx="3" ry="3" width="70" height="10" />
    <rect x="140" y="70" rx="3" ry="3" width="20" height="10" />
    <rect x="10" y="90" rx="3" ry="3" width="90" height="10" />
    <rect x="110" y="90" rx="3" ry="3" width="70" height="10" />
    <rect x="10" y="110" rx="3" ry="3" width="70" height="10" />
    <rect x="90" y="110" rx="3" ry="3" width="60" height="10" />
  </ContentLoader>
  )
}

export const NavProfileLoader = () => {
  return (
    <ContentLoader
    viewBox="0 0 260 160"
    speed={2}
  >
    <circle cx="50" cy="30" r="30" />
    <rect x="10" y="70" rx="3" ry="3" width="40" height="10" />
    <rect x="60" y="70" rx="3" ry="3" width="70" height="10" />
    <rect x="140" y="70" rx="3" ry="3" width="20" height="10" />
  </ContentLoader>
  )
}

export const GalleryLoader = () => {
  return (
    <ContentLoader
      viewBox="0 0 820 450"
      speed={2}
    >
      <rect x="10" y="10" rx="5" ry="5" width="260" height="140" />
      <rect x="280" y="10" rx="5" ry="5" width="260" height="280" />
      <rect x="550" y="10" rx="5" ry="5" width="260" height="140" />
      <rect x="10" y="160" rx="5" ry="5" width="260" height="280" />
      <rect x="280" y="300" rx="5" ry="5" width="260" height="140" />
      <rect x="550" y="160" rx="5" ry="5" width="260" height="280" />
    </ContentLoader>
  )
}
export const LeaveLoader = () => {
  return (
    <ContentLoader
    speed={2}
    viewBox="0 0 1200 900"
    backgroundColor="#eaeced"
    foregroundColor="#ffffff"
  >
    <rect x="68" y="37" rx="3" ry="3" width="298" height="129" />
    <rect x="426" y="37" rx="3" ry="3" width="298" height="129" />
    <rect x="786" y="37" rx="3" ry="3" width="298" height="129" />
    <rect x="104" y="217" rx="3" ry="3" width="578" height="42" />
    <rect x="123" y="308" rx="3" ry="3" width="906" height="17" />
    <circle cx="951" cy="386" r="11" />
    <circle cx="986" cy="386" r="11" />
    <rect x="176" y="378" rx="3" ry="3" width="141" height="15" />
    <rect x="377" y="377" rx="3" ry="3" width="299" height="15" />
    <rect x="733" y="377" rx="3" ry="3" width="141" height="15" />
    <rect x="127" y="418" rx="3" ry="3" width="897" height="2" />
    <circle cx="952" cy="447" r="11" />
    <circle cx="987" cy="447" r="11" />
    <rect x="177" y="439" rx="3" ry="3" width="141" height="15" />
    <rect x="378" y="438" rx="3" ry="3" width="299" height="15" />
    <rect x="734" y="438" rx="3" ry="3" width="141" height="15" />
    <rect x="128" y="479" rx="3" ry="3" width="897" height="2" />
    <circle cx="953" cy="505" r="11" />
    <circle cx="988" cy="505" r="11" />
    <rect x="178" y="497" rx="3" ry="3" width="141" height="15" />
    <rect x="379" y="496" rx="3" ry="3" width="299" height="15" />
    <rect x="735" y="496" rx="3" ry="3" width="141" height="15" />
    <rect x="129" y="537" rx="3" ry="3" width="897" height="2" />
    <circle cx="954" cy="566" r="11" />
    <circle cx="989" cy="566" r="11" />
    <rect x="179" y="558" rx="3" ry="3" width="141" height="15" />
    <rect x="380" y="557" rx="3" ry="3" width="299" height="15" />
    <rect x="736" y="557" rx="3" ry="3" width="141" height="15" />
    <rect x="130" y="598" rx="3" ry="3" width="897" height="2" />
    <circle cx="953" cy="626" r="11" />
    <circle cx="988" cy="626" r="11" />
    <rect x="178" y="618" rx="3" ry="3" width="141" height="15" />
    <rect x="379" y="617" rx="3" ry="3" width="299" height="15" />
    <rect x="735" y="617" rx="3" ry="3" width="141" height="15" />
    <rect x="129" y="658" rx="3" ry="3" width="897" height="2" />
    <circle cx="954" cy="687" r="11" />
    <circle cx="989" cy="687" r="11" />
    <rect x="179" y="679" rx="3" ry="3" width="141" height="15" />
    <rect x="380" y="678" rx="3" ry="3" width="299" height="15" />
    <rect x="736" y="678" rx="3" ry="3" width="141" height="15" />
    <rect x="127" y="716" rx="3" ry="3" width="897" height="2" />
    <rect x="123" y="312" rx="3" ry="3" width="2" height="465" />
    <rect x="1027" y="312" rx="3" ry="3" width="2" height="465" />
    <circle cx="954" cy="747" r="11" />
    <circle cx="989" cy="747" r="11" />
    <rect x="179" y="739" rx="3" ry="3" width="141" height="15" />
    <rect x="380" y="738" rx="3" ry="3" width="299" height="15" />
    <rect x="736" y="738" rx="3" ry="3" width="141" height="15" />
    <rect x="127" y="776" rx="3" ry="3" width="897" height="2" />
    <rect x="124" y="343" rx="3" ry="3" width="906" height="17" />
    <rect x="125" y="320" rx="3" ry="3" width="68" height="33" />
    <rect x="294" y="317" rx="3" ry="3" width="149" height="33" />
    <rect x="616" y="318" rx="3" ry="3" width="137" height="33" />
    <rect x="854" y="319" rx="3" ry="3" width="72" height="33" />
    <rect x="1005" y="317" rx="3" ry="3" width="22" height="33" />
    <circle cx="743" cy="237" r="20" />
    <rect x="739" y="217" rx="0" ry="0" width="67" height="40" />
    <circle cx="802" cy="237" r="20" />
    <circle cx="890" cy="238" r="20" />
    <rect x="890" y="218" rx="0" ry="0" width="140" height="40" />
    <circle cx="1032" cy="238" r="20" />
  </ContentLoader>
  )
}

export const TasksLoader = () => {
  return (
    <ContentLoader speed={2} viewBox="0 0 400 311">
      <rect x="0" y="0" rx="0" ry="0" width="400" height="1" />
      <rect x="0" y="19" rx="0" ry="0" width="400" height="1" />
      <rect x="0" y="24" rx="0" ry="0" width="400" height="3" />
      <rect x="0" y="31" rx="0" ry="0" width="23" height="3" />
      <rect x="0" y="43" rx="0" ry="0" width="199" height="1" />
      <rect x="200" y="43" rx="0" ry="0" width="200" height="1" />
      <rect x="0" y="54" rx="0" ry="0" width="199" height="1" />
      <rect x="200" y="54" rx="0" ry="0" width="200" height="1" />
      <rect x="0" y="67" rx="0" ry="0" width="199" height="1" />
      <rect x="200" y="67" rx="0" ry="0" width="200" height="1" />
      <rect x="0" y="80" rx="0" ry="0" width="199" height="1" />
      <rect x="200" y="80" rx="0" ry="0" width="200" height="1" />
      <rect x="32" y="5" rx="0" ry="0" width="10" height="10" />
      <rect x="86" y="5" rx="0" ry="0" width="10" height="10" />
      <rect x="170" y="5" rx="0" ry="0" width="10" height="10" />
      <rect x="239" y="5" rx="0" ry="0" width="10" height="10" />
      <rect x="341" y="5" rx="0" ry="0" width="41" height="10" />
    </ContentLoader>
  )
}



export const EmployeeDashLoader = () => {
  return (
    <ContentLoader
    viewBox="0 0 450 300"
    backgroundColor="#f5f5f5"
    foregroundColor="#dbdbdb"
  >
    <circle cx="75" cy="75" r="70" />
    <rect x="160" y="15" rx="3" ry="3" width="50" height="15" />
    <rect x="215" y="15" rx="3" ry="3" width="50" height="15" />
    <rect x="270" y="15" rx="3" ry="3" width="50" height="15" />
    <rect x="325" y="15" rx="3" ry="3" width="50" height="15" />

    <rect x="160" y="35" rx="3" ry="3" width="290" height="1" />

    <rect x="160" y="45" rx="3" ry="3" width="35" height="8" />
    <rect x="380" y="45" rx="3" ry="3" width="70" height="8" />

    <rect x="160" y="60" rx="3" ry="3" width="140" height="50" />
    <rect x="310" y="60" rx="3" ry="3" width="140" height="50" />
    <rect x="160" y="120" rx="3" ry="3" width="140" height="50" />
    <rect x="310" y="120" rx="3" ry="3" width="140" height="50" />
    <rect x="160" y="180" rx="3" ry="3" width="140" height="50" />
    <rect x="310" y="180" rx="3" ry="3" width="140" height="50" />

    <rect x="5" y="150" rx="3" ry="3" width="130" height="15" />
    <rect x="5" y="170" rx="3" ry="3" width="70" height="10" />
    <rect x="10" y="190" rx="3" ry="3" width="115" height="15" />
    <rect x="10" y="210" rx="3" ry="3" width="35" height="8" />
    <rect x="50" y="210" rx="3" ry="3" width="35" height="8" />
    <rect x="90" y="210" rx="3" ry="3" width="35" height="8" />
  </ContentLoader>
  )
}

export const  HomeLoader = () => {
  return (
<ContentLoader
    viewBox="0 0 500 300"
  >
    <rect x="0" y="8" rx="0" ry="0" width="40" height="18" />
    <circle cx="492" cy="16" r="8" />
    <circle cx="472" cy="16" r="8" />
    <rect x="362" y="8" rx="7" ry="7" width="96" height="16" />

    <rect x="0" y="39" rx="0" ry="0" width="34" height="8" />
    <rect x="50" y="39" rx="0" ry="0" width="36" height="8" />
    <rect x="102" y="39" rx="0" ry="0" width="34" height="8" />
    <rect x="152" y="39" rx="0" ry="0" width="34" height="8" />
    <rect x="202" y="39" rx="0" ry="0" width="36" height="8" />
    <rect x="254" y="39" rx="0" ry="0" width="34" height="8" />

    <rect x="477" y="39" rx="0" ry="0" width="10" height="8" />

    <rect x="19" y="64" rx="0" ry="0" width="465" height="155" />

    <rect x="18" y="225" rx="0" ry="0" width="141" height="38" />
    <rect x="182" y="225" rx="0" ry="0" width="141" height="38" />
    <rect x="343" y="225" rx="0" ry="0" width="141" height="38" />
    <rect x="18" y="270" rx="0" ry="0" width="141" height="38" />
    <rect x="182" y="270" rx="0" ry="0" width="141" height="38" />
    <rect x="343" y="270" rx="0" ry="0" width="141" height="38" />
  </ContentLoader>
  )
}

export const NewsFeedLoader = () => {
  return (
    <ContentLoader
    viewBox="0 0 500 475"
  >
    <circle cx="70.2" cy="73.2" r="41.3" />
    <rect x="129.9" y="29.5" width="125.5" height="17" />
    <rect x="129.9" y="64.7" width="296" height="17" />
    <rect x="129.9" y="97.8" width="253.5" height="17" />
    <rect x="129.9" y="132.3" width="212.5" height="17" />

    <circle cx="70.7" cy="243.5" r="41.3" />
    <rect x="130.4" y="199.9" width="125.5" height="17" />
    <rect x="130.4" y="235" width="296" height="17" />
    <rect x="130.4" y="268.2" width="253.5" height="17" />
    <rect x="130.4" y="302.6" width="212.5" height="17" />

    <circle cx="70.7" cy="412.7" r="41.3" />
    <rect x="130.4" y="369" width="125.5" height="17" />
    <rect x="130.4" y="404.2" width="296" height="17" />
    <rect x="130.4" y="437.3" width="253.5" height="17" />
    <rect x="130.4" y="471.8" width="212.5" height="17" />
  </ContentLoader>

  )
}
export const NewsFeedPostLoader = () => {
  return (
    <ContentLoader>
    <rect x="110" y="21" rx="4" ry="4" width="254" height="6" />
    <rect x="111" y="41" rx="3" ry="3" width="185" height="7" />
    <rect x="304" y="-46" rx="3" ry="3" width="350" height="6" />
    <rect x="371" y="-45" rx="3" ry="3" width="380" height="6" />
    <rect x="484" y="-45" rx="3" ry="3" width="201" height="6" />
    <circle cx="48" cy="48" r="48" />
  </ContentLoader>

  )
}
export const AddProfileLoader = () => {
  return (
    <ContentLoader
    speed={2}
    viewBox="0 0 800 575"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="537" y="9" rx="2" ry="2" width="140" height="10" />
    <rect x="14" y="30" rx="2" ry="2" width="667" height="11" />
    <rect x="12" y="58" rx="2" ry="2" width="211" height="211" />
    <rect x="240" y="57" rx="2" ry="2" width="211" height="211" />
    <rect x="467" y="56" rx="2" ry="2" width="211" height="211" />
    <rect x="12" y="283" rx="2" ry="2" width="211" height="211" />
    <rect x="240" y="281" rx="2" ry="2" width="211" height="211" />
    <rect x="468" y="279" rx="2" ry="2" width="211" height="211" />
    <circle cx="286" cy="536" r="12" />
    <circle cx="319" cy="535" r="12" />
    <circle cx="353" cy="535" r="12" />
    <rect x="378" y="524" rx="0" ry="0" width="52" height="24" />
    <rect x="210" y="523" rx="0" ry="0" width="52" height="24" />
    <circle cx="210" cy="535" r="12" />
    <circle cx="428" cy="536" r="12" />
  </ContentLoader>

  )
}

export const SalaryLoader = () => {
  return (
<ContentLoader
viewBox="0 0 400 160"
speed={2}
backgroundColor="transparent"
>
<circle cx="150" cy="86" r="8" />
<circle cx="194" cy="86" r="8" />
<circle cx="238" cy="86" r="8" />
</ContentLoader>

)
}