import React, { useEffect, useState } from 'react';

const AdminEditProfile = () => {
  const [editSucess, setEditSucess] = useState(false)
  const [editError, setEditError] = useState(false)
  const [loading, setLoading] = useState(false)

  // ---------------- auto hide error popup after 5 seconds ----------------- //
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowErrorMessage(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [showErrorMessage]);


    const [isLoading, setIsLoading] = useState(false);
    const storedColor = localStorage.getItem('selectedColor')
    const [company, setCompany] = useState([]);
    const [edit, setEdit] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [isLoading1, setIsLoading1] = useState(true);
    const [companyData, setCompanyData] = useState({
      name: '',
      organization: '',
      logo: null,
      company_type: '',
      company_since: '',
      color: '',
      company_address: '',
      social_links
: {
      facebook: '',
      instagram: '',
      linkedin: '',
      twitter: '',
    }, // Initialize social_links with an empty object
      about: '',
      images: [],
      tagline: '',
      numOfEmployees: '',
      domain: '',   
      user_id: '',
      email: '',
      password: '',
    });
    
    const [logoFile, setLogoFile] = useState(null);

    
    const handleChange = (e) => {
      const { name, value, type, files } = e.target;
    
      // Check if the change is for a social media link directly
      const socialMediaFields = ['facebook', 'instagram', 'linkedin', 'twitter'];
      if (socialMediaFields.includes(name)) {
        // Handle change for social media links
        setCompanyData(prevState => ({
          ...prevState,
          social_links: {
            ...prevState.social_links,
            [name]: value
          }
        }));
      } else if (type === 'file') {
        // Handle file input types
        setCompanyData(prevState => ({ ...prevState, [name]: files[0] }));
      } else {
        // Handle all other inputs
        setCompanyData(prevState => ({ ...prevState, [name]: value }));
      }
    };
    
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
    const logintl = JSON.parse(localStorage.getItem("logintl"));
    const logintesting = JSON.parse(localStorage.getItem("logintesting"));
    const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
    const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
    const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
    const name=loggedInadmin ? loggedInadmin.name :loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name:logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name: null;
    const organization = loggedInUser
    ? loggedInUser.organization
    : logintesting
    ? logintesting.organization
    : loginbackend
    ? loginbackend.organization
    : logintl
    ? logintl.organization
    : logintltest
    ? logintltest.organization
    : loginbackendtl
    ? loginbackendtl.organization
    : loggedInadmin
    ? loggedInadmin.name
    : null;

  const FetchCompanyData = async () => {
    try {
      const response = await fetch(
        `https://empadmin.hola9.com/account/adminapiget/?organization=${organization}`
      );
      if (!response.ok) {
        throw new Error("Network response not ok");
      }
      const data = await response.json();
      setCompanyData(data[0])
      setIsLoading(false);
      const logoResponse = await fetch(data[0].logo);
      if (logoResponse.ok) {
        const logoBlob = await logoResponse.blob();
        setLogoFile(new File([logoBlob], 'logo.png', { type: 'image/png' }));
      }
      console.log("companydata", data);
      setIsLoading1(false);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    FetchCompanyData();
  }, []);

  const handleSubmit = async (e) => {
    setEdit(true);

    e.preventDefault();
    setLoading(true)
    try {
      const formData = new FormData();
      formData.append('name', companyData.name);
      formData.append('organization', companyData.organization);

      formData.append('company_type', companyData.company_type);
      formData.append('company_since', companyData.company_since);
      formData.append('color', companyData.color);
      formData.append('company_address', companyData.company_address);

      // Append social media links if it's an object
      formData.append('social_links', JSON.stringify(companyData.social_links));
  
      if (logoFile) {
        formData.append('logo', logoFile);
      }
      formData.append('about', companyData.about);
      formData.append('tagline', companyData.tagline);
      formData.append('no_of_employees', companyData.no_of_employees);
      formData.append('domain', companyData.domain);
      formData.append('user_id', companyData.user_id);
      formData.append('email', companyData.email);
      formData.append('password', companyData.password);

      // Now you can send fo
      if (companyData.images) {
        formData.append("images", companyData.images);
      }
      // Append logo file if available
      if (companyData.logo) {
        formData.append('logo', companyData.logo);
      }


       const url = `https://empadmin.hola9.com/account/adminapiupdate/${companyData.id}/`;
       const method = "PUT";

      const response = await fetch(url, {
        method: method,
        body: formData
      });


      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setShowForm(false);
      setLoading(false)
      setShowErrorMessage(true);
      setEditSucess(true)
      setEditError(false)
      FetchCompanyData();
      window.location.reload();
    } catch (error) {
      console.error("Error submitting holiday data:", error);
      setLoading(false)
      setShowErrorMessage(true);
      setEditError(true)
      setEditSucess(false)
    }
  };

  const handleEditClick = (item) => {
    setEdit(true);
    // Set companyData with existing data, except for logo and images
    const { logo, images, ...rest } = item;
    setCompanyData({ ...rest });
    setShowForm(true);
  };
    return (
        <>
        <center className="edit-profile-container-img ">
          <img src={companyData.logo} alt="logo" height={50} width={100}/>
        </center>
        <div className="edit-profile-container scroll-bar">
          {isLoading&&"loading.."}
          <h6>Personal Details</h6>
          <label>
            Name
            <input type="text" name="name" value={companyData.name} onChange={handleChange} />
          </label>
          <label>
          Organization
            <input type="text" name="organization" value={companyData.organization} onChange={handleChange} />
          </label>
          <label>
          
          Logo <img src={companyData.logo} alt="logo" height={50} width={100}/>
            <input type="file" name="logo" accept="image/*" onChange={handleChange} />
          </label>
          <label>
          {Array.isArray(companyData.images) && companyData.images.map((image, index) => (
        <img key={index} src={image.image} alt={`Image ${index + 1}`} />
      ))}
          Image
          <input type="file" name="images" accept="image/*" onChange={handleChange} />
          </label>
          <label>
          Company Type
          <input type="text" name="company_type" value={companyData.company_type} onChange={handleChange} />
          </label>
          <label>
          Company Since
          <input type="text" name="company_since" value={companyData.company_since} onChange={handleChange} />
          </label>
          <label>
          Color
          <input type="text" name="color" value={companyData.color} onChange={handleChange} />
          </label>
          <label>
          Company Address
            <input type="text" name="company_address" value={companyData.company_address} onChange={handleChange} />
          </label>
          <label>
          About
          <textarea name="about" value={companyData.about} onChange={handleChange}></textarea>
          </label>
          <label>
          Tagline
            <input type="text" name="tagline" value={companyData.tagline} onChange={handleChange} />
          </label>
          <label>
          No. of Employees
            <input type="number" name="no_of_employees" value={companyData.no_of_employees} onChange={handleChange} />
          </label>
          <label>
          Domain
          <input type="text" name="domain" value={companyData.domain} onChange={handleChange} />
          </label>
  
          <label>
          User ID
            <input type="text" name="user_id" value={companyData.user_id} onChange={handleChange} />
          </label>
              <label>
              Email
              <input type="text" name="email" value={companyData.email} onChange={handleChange} />
              </label>
              <label>
              Password
              <input type="password" name="password" value={companyData.password} onChange={handleChange} />
              </label>
  
              {companyData.social_links && (
                <>
          <h6>Social Media Links</h6>
          <label>
            Facebook Link
            <input type="text" name="facebook" value={companyData.social_links.facebook} onChange={handleChange} />
          </label>
          <label>
            Instagram Link
            <input type="text" name="instagram" value={companyData.social_links.instagram} onChange={handleChange} />
          </label>
          <label>
            LinkedIn Link
            <input type="text" name="linkedin" value={companyData.social_links.linkedin} onChange={handleChange} />
          </label>
          <label>
            Twitter Link
            <input type="text" name="twitter" value={companyData.social_links.twitter} onChange={handleChange} />
          </label></>
      )}
        </div>
        <div className="edit-profile-submit">
          <button className="button" onClick={handleSubmit} >
                {loading ? 'Updating...' : 'Edit Profile'}
          </button>
        </div>
  
  
  
        {showErrorMessage && (
          <div className={`side-pop-message-div`}>
          <i
            className={`side-pop-message ${
              editSucess
                ? "sucess-message"
                : "error-message"
            }`}
          >
            {editError ? 'Failed to update profile.' : null}
            {editSucess ? 'Profile updated successfully.' : null}
            </i></div>
  )}
      </>
    );
};

export default AdminEditProfile;