import React, { useEffect, useRef, useState } from "react";
import "./Gallery.css";
import { FaEllipsis } from "react-icons/fa6";
import AddGallery from "./AddGallery";
import { GalleryLoader } from "../../Loaders/Loader";

const Gallery = () => {

  const [isLoading, setIsLoading] = useState(true);


  const [galleryData, setGalleryData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    // Fetch data from your API endpoint
    fetch("https://empadmin.hola9.com/account/wanted2/")
      .then((response) => response.json())
      .then((data) => { setGalleryData(data)
      setIsLoading(false);
    })
      .catch((error) => console.error("Error fetching data:", error));
      setIsLoading(false);
  }, []);

  // Function to handle click on a card
  const handleCardClick = (item) => {
    setSelectedItem(item);
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setSelectedItem(null);
  };
  const [openLeaveForm, setopenLeaveForm] = useState(false);

  //  -----------------------  Add Mor Gallery -------------------------------------- //

  const fileInputRef = useRef(null);

  const handleImageUpload = (image) => {
    setIsLoading(true);
    fetch(`https://empadmin.hola9.com/account/wanted2/${image}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(galleryData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to Uploaded Images");
          setIsLoading(false);
        }
        return response.json();
      })
      .then((data) => {
        alert("Images uploaded successfully:", data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error during image upload:", error);
        alert("Error during image upload:");
        setIsLoading(false);
      });
  };

  const handleAddMoreClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // ---------------------------- Click on esc Button to close popup ------------------------------------- //
  const storedColor = localStorage.getItem("selectedColor");

  const handleEscKey = (event) => {
    if (event.key === "Escape") {
      setopenLeaveForm(false);
      setSelectedItem(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  return (
    <>
    {isLoading && <GalleryLoader/>}
                   <div className='Reports-Top'>
            <h5 onClick={() => setopenLeaveForm(true)} style={{cursor:'pointer'}}> <span className='Report-top-icon'>🤳🏿</span>Add Galery</h5>
           </div>

    <div className="gallery-box-scroll scroll-bar">
      <div className="gallery-box scroll-bar">
        {galleryData.map((dat, index) => (
          <>
        <div className="gallery-container container-boxShadow">
          <h5 className="border">{dat.organization}</h5>
          <div className="scroll-bar">
          {dat.images && dat.images.length > 0 && (
          <img
           src={`https://empadmin.hola9.com${dat.images[0].image}`}
            alt="events"
          />
        )}
          </div>
          <p>
          {dat.description}
          </p>
          <i>
            {dat.date}
          </i>

        </div>
          {/* {(index + 1) % 4 === 0 && (
            <div className="gallery-container container-boxShadow" style={{display:'flex',justifyContent:'center'}}>
                <button onClick={() => setopenLeaveForm(true)} className="button">🤳🏿</button>
              </div>
            )} */}
            </>
        ))}
      {galleryData.length < 1 && 'No Data Found'}
      </div>


      {openLeaveForm ? <div className="popupContainer-center2" onClick={() => setopenLeaveForm(false)}><AddGallery close={() => setopenLeaveForm(false)} /></div> : null}
    </div>
    </>
  );
};

export default Gallery;
