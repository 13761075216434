import React, { useEffect, useState } from 'react';
import { FaUserAlt, FaUserAltSlash} from "react-icons/fa";
import AddUserForm from './AddUserForm';
import axios from 'axios';
import { AddProfileLoader } from '../../Loaders/Loader';
import { NewsFeedLoader } from '../../Loaders/Loader';
import Preloader from '../../Loaders/Preloader';

const AddUser = () => {

    const [taskTab, setTaskTab] = useState(false);

    const [data, setData] = useState([]);
    const [isLoading1, setIsLoading1] = useState(true);
    const [isLoading2, setIsLoading2] = useState(false);
  
    useEffect(() => {
      fetchData();
    }, []);
  
    const fetchData = async () => {
      try {
        const response = await axios.get('https://empadmin.hola9.com/account/adduser/');
        setData(response.data);
        setIsLoading1(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading1(false);
      }
    };
  
    const handleDelete = async (id) => {
      setIsLoading2(true)
      try {
        await axios.delete(`https://empadmin.hola9.com/account/adduser/${id}`);
        fetchData(); // Refetch data after successful deletion
        setIsLoading2(false)
      } catch (error) {
        console.error('Error deleting data:', error);
        setIsLoading2(false)
      }
    };
    const determineRole = (user) => {
      if (user.tl || user.testing || user.testing_tl || user.backend || user.backend_tl) {
          let roles = [];
          if (user.tl) roles.push('TL');
          if (user.testing) roles.push('Testing');
          if (user.testing_tl) roles.push('Testing TL');
          if (user.backend) roles.push('Backend');
          if (user.backend_tl) roles.push('Backend TL');
          return roles.join(', ');
      } else {
          return 'Dev';
      }
  };
  const copyUserData = (user) => {
    const userData = `name: ${user.name}\norganization: ${user.organization}\nusername: ${user.username}\npassword: ${user.password}`;
    navigator.clipboard.writeText(userData);
};
    return (
        <>
                 <div className={taskTab ? "container-background" : ""}>
        <div className="Reports-Top" onClick={() => setTaskTab(!taskTab)}>
          {/* <h5 style={{ cursor: "pointer", color: taskTab ? "darkred" : "" }}>
          {taskTab ? <FaUserAltSlash className="Report-top-icon" /> : <FaUserAlt className="Report-top-icon" />}
            {taskTab ? "Close Add User Tab" : "Add User"}
          </h5> */}
        </div>
        {isLoading2&&<Preloader/>}
        {taskTab && <AddUserForm/>}
      </div>
<>
      {isLoading1 ? <AddProfileLoader /> :
            <table className='Reports-table'>
        <thead  className='Reports-thead container-background'>
          <tr className='Reports-tr'>
            <th>Name</th>
            <th>Organization</th>
            <th>Username</th>
            <th>Password</th>
            <th>Role</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody  className='Reports-thead container-background'>
          {data.map(user => (
            <tr key={user.id} className='Reports-tr'>
              <td>{user.name}</td>
              <td>{user.organization}</td>
              <td>{user.username}</td>
              <td>{user.password}</td>
              <td>{determineRole(user)}</td> {/* Displaying user's role directly */}
              <td>
                <button onClick={() => handleDelete(user.id)} className='button error-message'>Delete</button>
              </td>
              <td>
                                        <button onClick={() => copyUserData(user)} className='button'>Copy</button>
                                    </td>
            </tr>
          ))}
        </tbody>
      </table>
}
      </>

      <div className="bottom-height-768"></div>
        </>
    );
};

export default AddUser;