import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BrowseNotifications = () => {
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const loggedInAdmin = localStorage.getItem("loggedInadmin");
  const log = localStorage.getItem("logg");

  useEffect(() => {
    if(loggedInAdmin){
    const fetchData = () => {
      fetch(
        `https://empadmin.hola9.com/account/employeelogin2`
      )
        .then((response) => response.json())
        .then((data) => {
          setDetails(data);
          setLoading(false);
          const storedLength = localStorage.getItem("apiDataLength3");
          if (storedLength && data.length > parseInt(storedLength)) {
            const increasedLength = data.length - parseInt(storedLength);
            localStorage.setItem("apiDataLength3", data.length);
            handleClick5(increasedLength);
          } else {
            localStorage.setItem("apiDataLength3", data.length);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    // Fetch data initially
    fetchData()
    // Fetch data every one minute
    const interval = setInterval(fetchData, 60000);

    // Cleanup function to clear interval
    return () => clearInterval(interval);
}
  }, [loggedInAdmin]);
  useEffect(() => {
if(log){
    const fetchData = () => {
      fetch(
        `https://empadmin.hola9.com/account/adduser/`
      )
        .then((response) => response.json())
        .then((data) => {
          setDetails(data);
          setLoading(false);
          const storedLength = localStorage.getItem("apiDataLength2");
          if (storedLength && data.length > parseInt(storedLength)) {
            localStorage.setItem("apiDataLength2", data.length);
            handleClick4(data[data.length - 1].name);
          } else {
            localStorage.setItem("apiDataLength2", data.length);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    // Fetch data initially
   fetchData()
    // Fetch data every one minute
    const interval = setInterval(fetchData, 60000);

    // Cleanup function to clear interval
    return () => clearInterval(interval);
}
  }, []);
  useEffect(() => {

    if(log){
    const fetchData = () => {
      fetch(
        `https://empadmin.hola9.com/account/feeds/`
      )
        .then((response) => response.json())
        .then((data) => {
          setDetails(data);
          setLoading(false);
          const storedLength = localStorage.getItem("apiDataLength1");
          if (storedLength && data.length > parseInt(storedLength)) {
            localStorage.setItem("apiDataLength1", data.length);
            handleClick3(data[data.length - 1].name);
          } else {
            localStorage.setItem("apiDataLength1", data.length);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    // Fetch data initially
    fetchData();

    // Fetch data every one minute
    const interval = setInterval(fetchData, 60000);

    // Cleanup function to clear interval
    return () => clearInterval(interval);
  }
  }, []);
  useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const loggedInAdmin = JSON.parse(localStorage.getItem("loggedInadmin"));
    const loginTL = JSON.parse(localStorage.getItem("logintl"));
    const loginTesting = JSON.parse(localStorage.getItem("logintesting"));
    const loginTLTest = JSON.parse(localStorage.getItem("logintestingtl"));
    const loginBackend = JSON.parse(localStorage.getItem("loginbackend"));
    const loginBackendTL = JSON.parse(localStorage.getItem("loginbackendtl"));

    const name =
      loggedInAdmin?.name ||
      loggedInUser?.name ||
      loginTesting?.name ||
      loginBackend?.name ||
      loginTL?.name ||
      loginTLTest?.name ||
      loginBackendTL?.name ||
      null;

    const organization =
      loggedInUser?.organization ||
      loginTesting?.organization ||
      loginBackend?.organization ||
      loginTL?.organization ||
      loginTLTest?.organization ||
      loginBackendTL?.organization ||
      null;
      if(log){
    const fetchData = () => {
      fetch(
        `https://empadmin.hola9.com/account/usernotification/?name=${name}&organization=${organization}`
      )
        .then((response) => response.json())
        .then((data) => {
          setDetails(data);
          setLoading(false);
          const storedLength = localStorage.getItem("apiDataLength");
          if (storedLength && data.length > parseInt(storedLength)) {
            localStorage.setItem("apiDataLength", data.length);
            handleClick();
            handleClick1(data[data.length - 1].message); // Pass the last notification message to handleClick
          } else {
            localStorage.setItem("apiDataLength", data.length);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    // Fetch data initially
    fetchData();

    // Fetch data every one minute
    const interval = setInterval(fetchData, 60000);

    // Cleanup function to clear interval
    return () => clearInterval(interval);
  }
  }, []);

  const handleClick = () => {
    toast.success("New Notification for you", {
      position: "top-right", 
      sound: true,// Set position directly as a string
    });
  };
  const handleClick1 = (massage) => {
    if (Notification.permission === 'granted') {
      new Notification('Notification message 1!', { body: massage });
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          new Notification('Notification message 1!', { body: massage });
        }
      });
    }
}
  const handleClick3 = (massage) => {
    if (Notification.permission === 'granted') {
      new Notification('Check Feeds 😍 ',{ body: "🔥 " + massage + ", 'posted feed'" })
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          new Notification('Check Feeds 😍 ',{ body: "🔥 " + massage + ", 'posted feed'" })
        }
      });
    }
}
  const handleClick5 = (massage) => {
    if (Notification.permission === 'granted') {
      new Notification('Hello Admin Checkin Alert ',{ body: + massage + ", Are checked in Checkit who is Not Checkedin" })
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          new Notification('Hello Admin Checkin Alert ',{ body: + massage + ", Are checked in Checkit who is Not Checkedin" })
        }
      });
    }
}
  const handleClick4= (massage) => {
    if (Notification.permission === 'granted') {
      new Notification('See Guys Who the new Employee😍 ',{ body: "Admin Added_" + massage + ",in our Team" })
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          new Notification('See Guys Who the new Employee😍 ',{ body: "Admin Added_" + massage + ",in our Team" })
        }
      });
    }
}

useEffect(() => {
 
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const timeInHours = currentHour + currentMinutes / 60;
    const notificationSent = localStorage.getItem("notificationSen");
    const CheckIn = localStorage.getItem("Check In");

    // If the current time is between 10:00 and 10:30 and notification hasn't been sent yet
    if (timeInHours >= 10.00&& timeInHours <= 10.50 && !notificationSent && !CheckIn && log) {
      // Set the notification sent flag
      localStorage.setItem("notificationSen", true);
      // Push the notification
      handleClick2("Login fast Dude");
    }
  }, []);
useEffect(() => {
 
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const timeInHours = currentHour + currentMinutes / 60;
    const notificationSent = localStorage.getItem("notificationSen");
    const CheckIn = localStorage.getItem("Lunch");

    // If the current time is between 10:00 and 10:30 and notification hasn't been sent yet
    if (timeInHours >= 14.00&& timeInHours <= 15.00 && !notificationSent && !CheckIn && log) {
      // Set the notification sent flag
      localStorage.setItem("notificationSen", true);
      // Push the notification
      handleClick2("Mention lunch Time Dude");
    }
  }, []);

  const handleClick2 = (massage) => {
    if (Notification.permission === 'granted') {
      new Notification('ApkaHR Alert ⚠️⚠️⚠️!', { body: massage });
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
      new Notification('ApkaHR Alert ⚠️⚠️⚠️!', { body: massage });
        }
      });
    }
}
  return (
    <>
   
      <ToastContainer />
    </>
  );
};

export default BrowseNotifications;



