import React, { useState } from "react";
import "./LoginForm.css";
import Adminlogin from "./Adminlogin";
import Login from "./UserLogin";
import { Link } from "react-router-dom";

const LoginForm = () => {
  const [isSignUpActive, setIsSignUpActive] = useState(false);
  const [isEmployeeLogin, setIsEmployeeLogin] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [activeButton, setActiveButton] = useState("employee"); // Default active button

  const handleTogglePanel = () => {
    setIsSignUpActive((prev) => !prev);
    setIsEmployeeLogin(false);
    setName("");
  };

  const handleEmployeeLogin = () => {
    setIsSignUpActive(false);
    setIsEmployeeLogin(true);
    setActiveButton("employee");
  };

  const handleAdminLogin = () => {
    setIsSignUpActive(true);
    setIsEmployeeLogin(false);
    setActiveButton("admin");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("Submitted:", { name, email, password, isEmployeeLogin });
  };

  const pri = "sss^7FvbbPric";
  return (
    <>
      <div
        className="LoginForm-container"
        style={{ height: window.innerHeight }}
      >
        <div className="LoginForm-content1">
          {activeButton === 'admin' ? (
            <div className="LoginForm-Animation">
            <img
              src="https://static.vecteezy.com/system/resources/previews/005/861/670/non_2x/user-key-concepts-vector.jpg"
              style={{ borderRadius: "50%" }}
              alt="login"
            />
            <h4>Welcome, Admin!</h4>
                  <p>
                    Enter your personal details and start the journey with us
                  </p>
            </div>
          ) : (
            <div className="LoginForm-Animation">
            <img
              src="https://cdn2.iconfinder.com/data/icons/flat-style-svg-icons-part-1/512/user_login_man-1024.png"
              alt="login"
            />
            <h4>Welcome Employee</h4>
                  <p>
                    To keep connected with us, please login with your personal
                    info
                  </p>
            </div>
          )}
        </div>
        <div className="LoginForm-content2">
          {activeButton === 'admin' ? <Adminlogin setActiveButton={setActiveButton}/> : <Login setActiveButton={setActiveButton}/>}
        </div>
      </div>
    </>
  );
};

export default LoginForm;
