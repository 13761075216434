import React, { useEffect, useState } from 'react';
import { FaFileContract } from 'react-icons/fa';
import { ListLoader } from '../../Loaders/Loader';
import ReactPaginate from 'react-paginate';

const Reports = ({setIsActive}) => {

  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintesting = JSON.parse(localStorage.getItem("logintesting"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
  const name=loggedInadmin ? loggedInadmin.name :loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name:logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name: null;
    
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(true)
  const [fromDateFilter, setFromDateFilter] = useState('');
  const [toDateFilter, setToDateFilter] = useState('');
  const [nameEmailFilter, setNameEmailFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(0); // Current page number
  const itemsPerPage = 40; // Number of items per page
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(true);
  const [selectedTab, setSelectedTab] = useState("allEmployees");
  const [itemsToLoad, setItemsToLoad] = useState(20)
  const [limitIncreaseCount, setLimitIncreaseCount] = useState(0);
  useEffect(() => {
    // Fetch the data from your API
    fetch(`https://empadmin.hola9.com/account/employeeprofile/?limit=${itemsToLoad}&name=${name}`)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setIsLoading(false); // Set loading state to false when data is fetched
        setloading(false)
      })
      .catch((error) => {
        setIsLoading(false); // Set loading state to false in case of an error
        console.error('Error fetching data:', error);
      });
  }, [itemsToLoad]);
  

  const handleLoadMore = () => {
    setItemsToLoad(itemsToLoad + 20);
    setLimitIncreaseCount((prevCount) => prevCount + 1); // Increase the number of items to load
  };
  const [prevDataLength, setPrevDataLength] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!isLoading && limitIncreaseCount != 3) {
        const newDataLength = data.length;
  
        if (newDataLength !== prevDataLength) {
          handleLoadMore();
          setPrevDataLength(newDataLength);
        } else {
          clearInterval(intervalId); // Stop further calls to handleLoadMore
        }
      }
    }, 2000);
  
    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [isLoading, data, prevDataLength,limitIncreaseCount]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
    setLimitIncreaseCount(0);
  };
  // Filter function to apply filters
  const filteredData = data.filter((employee) => {
    // Filter by Name/Email
    if (nameEmailFilter) {
      const name = employee.name.toLowerCase();
      const email = employee.email ? employee.email.toLowerCase() : '';
      const task = employee.task ? employee.task.toLowerCase() : '';
      const nameEmail = name + email + task;
      if (!nameEmail.includes(nameEmailFilter.toLowerCase())) {
        return false;
      }
    }

    // Filter by Date Range
    if (dateFilter) {
      const employeeDate = new Date(employee.task_date);
      const selectedDate = new Date(dateFilter);

      // Check if the selected date matches the employee's task_date
      if (employeeDate.toDateString() !== selectedDate.toDateString()) {
        return false;
      }
    }

    return true;
  });
  filteredData.sort((a, b) => new Date(b.id) - new Date(a.id));


  const sortedData = filteredData.sort((a, b) => b.id - a.id);
  // Calculate the start and end indexes based on the current page
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the data to display for the current page
  const dataToDisplay = filteredData.slice(startIndex, endIndex);

  // Calculate the total number of pages
  const pageCount = Math.ceil(sortedData.length / itemsPerPage);
  return (
    <>
    {loading ? <ListLoader/> : 
    <>
               <div className='Reports-Top'>
            <h5> <FaFileContract className='Report-top-icon' />Reports <span className='error-message'>{name}</span></h5>
            <button className='button' onClick={() => {setIsActive('Daily report')}}>Submit Report</button>
           </div>

           <div className='reportsscroll-div'>
      <table className='Reports-table' id="employeeTable">
                <thead  className='Reports-thead container-background'>
                  <tr className='Reports-tr'>
                    <th>ID</th>
                    <th>Task Date</th>
                    <th>Task</th>
                    <th>Work Report</th>
                    <th>Document</th>
                    <th>Comment</th>
                  </tr>
                </thead>
                <tbody className='Reports-thead container-background'>
                  {dataToDisplay.sort((a, b) => b.id - a.id).map((employee) =>
                   (
                      <tr key={employee.id} className='Reports-tr'>
                        <td>{employee.id}</td>
                        <td style={{whiteSpace:'nowrap'}}>{employee.task_date}</td>
                        <td>{employee.task}</td>
                        <td>
                          <p>
                          {`Report  -  ${employee.report_of_work}`}
                          </p>
                          <p>
                          {`Status  -  ${employee.completion_status}`}
                          </p>
                        </td>
                        <td>
                          <span>
                          <a href={employee.documnet} target='_blank' style={{color:"navy"}}>View Document</a>
                          </span>
                          <span>
                          {/* {employee.document_base64 && (
                              <DocumentViewer documentData={employee} />
                            )} */}
                          </span>
                        </td>
                        <td>{employee.comment}</td>
                      </tr>
                    ))}
                </tbody>
              </table>


              {filteredData.length > itemsPerPage && (
              <ReactPaginate
                className='paginat'
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                />)}
              </div>
              </>
}
    </>
  );
};

export default Reports;