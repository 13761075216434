import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAPI } from "../Context/APIProvider ";

const Checkcred = () => {
  const [logoutPopUp, setlogoutPopUp] = useState(false);
  const [message, setmessage] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintesting = JSON.parse(localStorage.getItem("logintesting"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
  const data = useAPI();
  useEffect(() => {
    // Check if data has loaded
    if (data.length > 0) {
      // Navigate automatically based on the first entry
      const firstEntry = data[4];
      if (firstEntry[1] === true) {
        window.location.href = firstEntry[3];
    }
    }
  }, [data]);
  const name = loggedInUser
    ? loggedInUser.name
    : logintesting
    ? logintesting.name
    : loginbackend
    ? loginbackend.name
    : logintl
    ? logintl.name
    : logintltest
    ? logintltest.name
    : loginbackendtl
    ? loginbackendtl.name
    : null;
  const email = loggedInUser
    ? loggedInUser.email
    : logintesting
    ? logintesting.email
    : loginbackend
    ? loginbackend.email
    : logintl
    ? logintl.email
    : logintltest
    ? logintltest.email
    : loginbackendtl
    ? loginbackendtl.email
    : null;
  const organization = loggedInUser
    ? loggedInUser.organization
    : logintesting
    ? logintesting.organization
    : loginbackend
    ? loginbackend.organization
    : logintl
    ? logintl.organization
    : logintltest
    ? logintltest.organization
    : loginbackendtl
    ? loginbackendtl.organization
    : null;
  const password = loggedInUser
    ? loggedInUser.password
    : logintesting
    ? logintesting.password
    : loginbackend
    ? loginbackend.password
    : logintl
    ? logintl.password
    : logintltest
    ? logintltest.password
    : loginbackendtl
    ? loginbackendtl.password
    : null;

  useEffect(() => {
    if (loggedInadmin) {
      attemptLogin();
    } else if (loggedInUser || logintl || logintesting || logintltest || loginbackend || loginbackendtl)  {
      
      handleSubmit();
    }
  }, []);

  const attemptLogin = async () => {
    if (navigator.onLine) {
      setIsProcessing(true);
      try {
        const response = await fetch(
          "https://empadmin.hola9.com/account/adminAuth",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username: loggedInadmin.email,
              password: loggedInadmin.password,
              organization: loggedInadmin.name,
            }),
          }
        );

        if (response.ok) {
          const result = await response.json();
          if (result === false) {
            handleLoginError(loggedInadmin.name);
          }
        } else {
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsProcessing(false);
      }
    } else {
      setlogoutPopUp(true);
      setmessage("Opps, Check your internet connection!");
    }
  };

  const handleSubmit = async () => {
    if (navigator.onLine) {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleString();
      try {
        const response = await fetch(
          "https://empadmin.hola9.com/account/loginEmployee/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username: email,
              password,
              name,
              organization,
            }),
          }
        );
        if (response.ok) {
          localStorage.setItem("logg", true);

          const result = await response.json();
          if (result === false) {
            handleLoginError1();
          }
        } else {
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsProcessing(false);
      }
    } else {
      setlogoutPopUp(true);
      setmessage("Opps, Check your internet connect!");
    }
  };

  const handleLoginError = () => {
    localStorage.removeItem("loggedInadmin");
    setlogoutPopUp(true);
    setmessage("Session Expired!");
    navigate("/");
  };
  const handleLoginError1 = () => {
            // localStorage.removeItem("loggedInUser");
            // localStorage.removeItem("logintl");
            // localStorage.removeItem("logintesting");
            // localStorage.removeItem("logintestingtl");
            // localStorage.removeItem("loginbackend");
            // localStorage.removeItem("loginbackendtl");
            // localStorage.removeItem("lastlogintime");
    localStorage.clear();
    setlogoutPopUp(true);
    setmessage("Session Expired!");
    navigate("/");
  };
  return (
    <>
      {logoutPopUp ? (
        <div
          className="popupContainer-center2"
        //   onClick={() => setlogoutPopUp(!logoutPopUp)}
          style={{ zIndex: "2" }}
        >
          <div
            className="popupContainer-center-sub2 logout-popup box-shadow"
            onClick={(e) => e.stopPropagation()}
          >
            <p style={{ padding: "20px 0" }}>{message}</p>
            <hr />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <button
                className="border"
                onClick={() => {setlogoutPopUp(!logoutPopUp);     window.location.reload();}}
                style={{ cursor: "pointer" }}
              >
                ok
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Checkcred;
