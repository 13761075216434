import React, { useEffect, useState } from 'react';
import { FaArrowRight, FaFacebook, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';

const HomeHeader = (props) => {
const data=props.data
    const [currentIndex, setCurrentIndex] = useState(0);

    const images = [
      "https://evalground.com/blog/wp-content/uploads/2020/06/HRMS_-1024x576.png",
      "https://spinetechnologies.com/wp-content/uploads/2022/01/Post-covid-1.png",
      "https://uknowva.com/images/hrms.jpg",
      "https://blog.darwinbox.com/hubfs/Rid%20Yourself%20of%20Repetitive%20and%20Recurring%20HR%20Tasks_%20HRMS%20System%20Implementation.jpg",
      // "",
    ];
  
    useEffect(() => {
      const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      };
  
      const intervalId = setInterval(nextSlide, 3000);
  
      return () => clearInterval(intervalId);
    }, [currentIndex]);


    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    };

    return (
        <>
        {data.map((data, index) => (
          <>
            <div className='Home-header-container border' style={{
    background: `linear-gradient(to right, rgba(0, 0, 0, 0.1), transparent ), url(${images[currentIndex]}) no-repeat`
}}>
  <div className='Home-header-container-span'>
  <h1>{data.organization}</h1>
  <q>{data.tagline}</q>
  <span className='Home-header-container-button'>
    <button className='button' onClick={() => scrollToSection('contact')}>Let's Talk <FaArrowRight/> </button>
    <button className='button' onClick={() => scrollToSection('about')}>Details <FaArrowRight/> </button>
  </span>
  </div>
          </div>
          <div className='Home-header-bottom-container border'>
            <a href={data?.social_media_links?.instagram}><FaInstagram className='home-header-bottom-icons border'/></a>
            <a href={data?.social_media_links?.facebook}><FaFacebook className='home-header-bottom-icons border'/></a>
            <a href={data?.social_media_links?.linkedin}><FaLinkedinIn className='home-header-bottom-icons border'/></a>
            <a href={data?.social_media_links?.twitter}><FaTwitter className='home-header-bottom-icons border'/></a>
          </div></>))}
        </>
    );
};

export default HomeHeader;