import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import './AddProfile.css'
import { Await } from "react-router-dom";
import { Progress } from "../../Loaders/Progress";

const AddProfileForm = (props) => {
  const nam = props.name;

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loader, setLoader] = useState(true);
  const [tru1, settrue1] = useState();
  const [tru3, settrue3] = useState();
  const [submissionMessage2, setSubmissionMessage2] = useState([]);
  const [submissionMessage21, setSubmissionMessage21] = useState([]);

  const [tru4, settrue4] = useState(false);
  const [tru2, settrue2] = useState();
  const [error, setError] = useState(false);
  const [General, setGeneral] = useState(false);
  const [Personal, setPersonal] = useState(false);
  const [Social, setSocial] = useState(false);
  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintesting = JSON.parse(localStorage.getItem("logintesting"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));


  const organization = loggedInUser
    ? loggedInUser.organization
    : logintesting
    ? logintesting.organization
    : loginbackend
    ? loginbackend.organization
    : logintl
    ? logintl.organization
    : logintltest
    ? logintltest.organization
    : loginbackendtl
    ? loginbackendtl.organization
    : loggedInadmin
    ? loggedInadmin.name
    : null;
    const [message, setMeaasage] = useState(false);
const id=sessionStorage.getItem("retrievedUserId")
    // ---------------- auto hide error popup after 5 seconds ----------------- //
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [formData, setFormData] = useState({
      name: '',
      organization: organization,
      username: '',
      password: '',
      tl: false,
      dev: false,
      testing: false,
      testing_tl: false,
      backend: false,
      backend_tl: false
    });
  const [userData, setUserData] = useState({
    name: formData.name,
    blood_group: "",
    userid: "",
    dob: "",
    image: null,
    organization:organization,
    role: "",
    email: formData.username,
    reporting_to: "",
    marital_status: "",
    gender: "",
    project_name: "",
    working_since: "",
    earned_leave: "",
    sick_leave: "",
    casual_leave: "",
    facebook_link: null,
    insta_link: null,
    linkedin_link: null,
    twitter_link: null,
    phone_number:'',
    // rating: null
  });

  const [errors, setErrors] = useState({
    name: "",
    userid: "",
    password:"",
    organization: "",
    email: "",
  });

const [emailError, setEmailError] = useState(false);

  const email=formData.username
  useEffect(() => {
    const companyDomain = "outlook.com";
    const emailRegex = new RegExp(`^[A-Za-z0-9._%+-]+@${companyDomain}$`, "i");
  
    if (!emailRegex.test(email) && email.trim() !== "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }, [email]);

const [errors1, setErrors1] = useState({});

const handleChange1 = (e) => {
    const { name, type, checked } = e.target;

    // Handle exclusive selection for checkboxes
    if (type === 'checkbox' && checked) {
        const updatedFormData = { ...formData };
        Object.keys(updatedFormData).forEach(key => {
            if (key !== name && typeof updatedFormData[key] === 'boolean') {
                updatedFormData[key] = false;
            }
        });
        setFormData({ ...updatedFormData, [name]: checked });
    } else {
        const value = type === 'checkbox' ? checked : e.target.value;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
};


  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setUserData({ ...userData, image: file });
  };
  useEffect(() => {
    fetch(`https://empadmin.hola9.com/account/loginprofile/?name=${nam}`)
      .then((response) => response.json())
      .then((data) => {
        setUserData(data);
        setLoading(false);
        setLoader(false);
      });
  }, [nam]);

  const validateForm = () => {
    const newErrors = { ...errors };
    let isValid = true;

    if (userData.name === "") {
      newErrors.name = "*Name is ";
      isValid = false;
    } else {
      newErrors.name = "";
    }

    if (userData.userid === "") {
      newErrors.userid = "*Hola9 Id is ";
      isValid = false;
    } else {
      newErrors.userid = "";
    }

  

    if (userData.email === "") {
      newErrors.email = "*Email is ";
      isValid = false;
    } else {
      newErrors.email = "";
    }

    setErrors(newErrors);
    return isValid;
  };
  const bloodGroupOptions = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return false;
  }
    else if (validateForm()) {
      if (emailError) {
        return; // Don't submit if there's an email error
      }
      else if (!formData.password.trim()) {
        errors.password = '*Password is required';
      }
      setLoading1(true);
     
      const apiUrl = "https://empadmin.hola9.com/account/loginprofile/";
      // const apiUrl = 'https://empadmin.hola9.com/account/loginprofi';

      const formData1 = new FormData();
      formData1.append("name", formData.name);
      formData1.append("userid", userData.userid);
      formData1.append("blood_group", userData.blood_group);
      formData1.append("dob", userData.dob);
      formData1.append("image", userData.image);
      formData1.append("organization", organization);
      formData1.append("role", userData.role);
      formData1.append("email", formData.username);
      formData1.append("reporting_to", userData.reporting_to);
      formData1.append("marital_status", userData.marital_status);
      formData1.append("gender", userData.gender);
      formData1.append("working_since", userData.working_since);
      formData1.append("earned_leave", userData.earned_leave);
      formData1.append("sick_leave", userData.sick_leave);
      formData1.append("casual_leave", userData.casual_leave);
      formData1.append("gender", userData.gender);
      formData1.append("project_name", userData.project_name);
      formData1.append("phone_number", userData.phone_number);
      localStorage.setItem("image", userData.image);

      axios
        .post(apiUrl, formData1, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const retrievedUserId = response.data.id;
        sessionStorage.setItem("retrievedUserId", retrievedUserId);
        settrue3(true);


          handleSubmit1(retrievedUserId);
          // handleDelete(retrievedUserId); // Pass the retrievedUserId directly
          console.log("Data posted successfully:", response.data);
          setLoader(false);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            // Set the submissionMessage2 state with the array of error messages
            setSubmissionMessage2(error.response.data);
          }
          setLoading1(false);

          settrue1(false);
          settrue3(false)

          console.error("Error posting data:", error);
        });
    }
  };
  const handleSubmit1 = (id) => {
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = '*Name is required';
    }
    
    if (!formData.username.trim()) {
      errors.username = '*Username is required';
    }
    if (!formData.password.trim()) {
      errors.password = '*Password is required';
    }
    if (emailError) {
      return; // Don't submit if there's an email error
    }
    setErrors(errors);
  
    axios.post('https://empadmin.hola9.com/account/adduser/', JSON.stringify(formData), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      settrue3(true);
      settrue1(false);
      setShowErrorMessage(false);
      setMeaasage('User Added');
    const confirmed = window.confirm("Profiles created successfully.");
    if (confirmed) {
      setLoading1(false);
      window.location.reload();
    }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        // Set the submissionMessage2 state with the array of error messages
        setSubmissionMessage21(error.response.data);
      }
      handleDelete(id);
      settrue2(false);
      settrue1(false);
      settrue3(false);
      setShowErrorMessage(true);
      setMeaasage('Failed to create');
      console.error('Error submitting data:', error);
    });
  };
  

// const handleButtonClick = async (e) => {
//   e.preventDefault(); // Prevent the default behavior of the button click event
//   await handleSubmit(e); // Pass the event object to handleSubmit
//   await handleSubmit1(e); 
//   handlefinal()
// }
// const handlefinal = async () => {
//   if (tru3) {
//     const confirmed = window.confirm("Profiles created successfully.");
//     if (confirmed) {
//       setLoading1(false);
//     }
//   } else if(!tru3) {
//     const confirmed = window.confirm("Profiles not craeted.");
//     if (confirmed) {
//       setLoading1(false);
//     }
//   }

//   // Delete data corresponding to tru1

// };
const handleDelete = async (id) => {
  try {
    await axios.delete(`https://empadmin.hola9.com/account/loginprofile/${id}/`);
    setLoading1(false);
    const confirmed = window.confirm("Profiles not craeted.");
    if (confirmed) {
      setLoading1(false);
    }
  } catch (error) {
   
    handleDelete1(id)
    console.error("Error deleting profile:", error);
    // Optionally, you can perform additional error handling here
  }
};

const handleDelete1 = (id) => {
  axios
    .delete(`https://empadmin.hola9.com/account/loginprofile/${id}/`)
    .then((response) => {
      const confirmed = window.confirm("Profiles not craeted.");
      if (confirmed) {
        setLoading1(false);
      }
      console.log("Profile deleted successfully:", response.data);
      setLoading1(false);
      
      // window.location.reload();
    })
    .catch((error) => {
      setLoading1(false);
      const confirmed = window.confirm("Profiles not craete.");
      if (confirmed) {
        setLoading1(false);
      }
      console.error("Error deleting profile:", error);
    });
};

  return (
    <>
      <div className="form-group2 form-group3 form-group4">
      <div className="grid-div ">
        <span style={{width:'100px', height:'100px', margin:'auto'}}>
      
      <img
                src={userData.image ? URL.createObjectURL(userData.image) :"https://i.gifer.com/origin/a1/a1d81f564eeb1468aefbcfd54d1571b8_w200.gif"}
                alt="user image"
                width={'100%'} height={'100%'}
              />
              <input
                style={{ position:'sticky', width:'100px', height:'90px', color:'transparent', marginTop:'-65px'}}
                required
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                placeholder="Image"
                name="image"
              />
              </span>
        <div>
          <span>
            <label>Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange1}
              style={{borderBottomColor: errors.name ? 'rgb(255, 0, 0)' : ''}}
            />
          </span>
          <span>
            <label>Hola9 Id:H-10__</label>
            <input
              type="text"
              name="userid"
              value={userData.userid}
              onChange={handleChange}
              style={{borderBottomColor: errors.userid ? 'rgb(255, 0, 0)' : ''}}
            />
          </span>
        </div>
        <div>
         
          <span>
            <label>Email</label>
            <input
              type="email"
              name="username"
              value={formData.username}
              onChange={handleChange1}
              style={{borderBottomColor: errors.email ? 'rgb(255, 0, 0)' : ''}}
            />
             {emailError&&<small style={{color:"red",fontSize:"70%"}}>Only outlook Mail id required</small>}
          </span>
        </div>
        <div>
         
          <span>
            <label>password</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange1}
              style={{borderBottomColor: errors.email ? 'rgb(255, 0, 0)' : ''}}
            />
           <p style={{color:"red",fontSize:"70%"}}>{errors.password}</p> 
          </span>
        </div>
        <div>
        <span className='adduserCheckbox-TS'>     
      <label className='adduser-TL-LA' >
        TL:</label>
           <input type="checkbox" name="tl" checked={formData.tl} onChange={handleChange1} />
        </span>
        <span className='adduserCheckbox-TS'>     
      <label className='adduser-TL-LA' >
        Dev:</label>
           <input type="checkbox" name="dev" checked={formData.dev} onChange={handleChange1} />
        </span>
      
        <span  className='adduserCheckbox-TS' >
      <label className='adduser-TS-LA' >
        Testing:</label>
        <input  type="checkbox" name="testing" checked={formData.testing} onChange={handleChange1} />
        </span>
      
        <span  className='adduserCheckbox-TS'>
      <label className='adduser-TST-LA'  >
        Testing TL:</label>
        <input type="checkbox" name="testing_tl" checked={formData.testing_tl} onChange={handleChange1} />
        </span>
      
        <span  className='adduserCheckbox-TS' >
      <label className='adduser-BD-LA'  >
        Backend: </label>
        <input type="checkbox" name="backend" checked={formData.backend} onChange={handleChange1} />
        </span>
     
        <span  className='adduserCheckbox-TS'>
      <label className='adduser-BDT-LA'  >
        Backend TL: </label>
        <input type="checkbox" name="backend_tl" checked={formData.backend_tl} onChange={handleChange1} />
        </span>
        </div>
        </div>
        <h5  className="profile-form-Top" onClick={() => setGeneral(!General)}>General Information {General ? <FaAngleDown className="profile-form-icon"/> : <FaAngleRight className="profile-form-icon"/>}  </h5>
        {General && 
        <div className="grid-div ">
          <span>
            <label>Project Name</label>
            <input
              type="text"
              name="project_name"
              value={userData.project_name}
              onChange={handleChange}
            />
          </span>
          <span>
            <label>Working Since</label>
            <input
              type="date"
              name="working_since"
              value={userData.working_since}
              onChange={handleChange}
            />
          </span>

          <span>
            <label>Reporting Manager</label>
            <input
              type="text"
              name="reporting_to"
              value={userData.reporting_to}
              onChange={handleChange}
            />
          </span>
          <span>
            <label>Marital Status</label>
            <input
              type="text"
              name="marital_status"
              value={userData.marital_status}
              onChange={handleChange}
            />
          </span>
        <span>
            <label>Role</label>
            <input
              type="text"
              name="role"
              value={userData.role}
              onChange={handleChange}
            />
          </span>
          <span>
            <label>Gender</label>
            <input
              type="text"
              name="gender"
              value={userData.gender}
              onChange={handleChange}
            />
          </span>
        </div>
        }
        <h5  className="profile-form-Top" onClick={() => setPersonal(!Personal)}>Personal Information {Personal ? <FaAngleDown className="profile-form-icon"/> : <FaAngleRight className="profile-form-icon"/>}  </h5>
        {Personal && 
        <div className="grid-div ">
          <span>
            <label>Blood Group</label>
            <select
              name="blood_group"
              value={userData.blood_group}
              onChange={handleChange}
            >
              <option value="">Select Blood Group</option>
              {bloodGroupOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </span>
          <span>
            <label>Date of Birth</label>
            <input
              type="date"
              name="dob"
              value={userData.dob}
              onChange={handleChange}
            />
          </span>
          <span>
            <label>Earned Leave</label>
            <input
              type="number"
              name="earned_leave"
              value={userData.earned_leave}
              onChange={handleChange}
            />
          </span>
          <span>
            <label>Sick Leave</label>
            <input
              type="number"
              name="sick_leave"
              value={userData.sick_leave}
              onChange={handleChange}
            />
          </span>
          <span>
            <label>casual Leave</label>
            <input
              type="number"
              name="casual_leave"
              value={userData.casual_leave}
              onChange={handleChange}
            />
          </span>
          <span>
            <label>Phone Number</label>
            <input
              type="number"
              name="phone_number"
              value={userData.phone_number}
              onChange={handleChange}
            />
          </span>
        </div>
        }
        <h5  className="profile-form-Top"  onClick={() => setSocial(!Social)}>Social Media {Social ? <FaAngleDown className="profile-form-icon"/> : <FaAngleRight className="profile-form-icon"/>} </h5>
        {Social &&
        <div className="grid-div ">
          <span>
            <label>Instagram Link</label>
            <input
              type="text"
              name="insta_link"
              value={userData.insta_link || ""}
              onChange={handleChange}
            />
          </span>
          <span>
            <label>LinkedIn Link</label>
            <input
              type="text"
              name="linkedin_link"
              value={userData.linkedin_link || ""}
              onChange={handleChange}
            />
          </span>
          <span>
          <label>Twitter Link</label>
          <input
            type="text"
            name="twitter_link"
            value={userData.twitter_link || ""}
            onChange={handleChange}
          />
          </span>
          <span>
            <label>Facebook Link</label>
            <input
              type="text"
              name="facebook_link"
              value={userData.facebook_link || ""}
              onChange={handleChange}
            />
          </span>
        </div>
        }
        

        <button className="button" onClick={handleSubmit} type="button">
          Add Profile
        </button>
      </div>
  

      
    {showErrorMessage && (
            <div className={`side-pop-message-div`}>
        <i
          className={`side-pop-message ${
            message === 'User Added'
              ? "sucess-message"
              : "error-message"
          }`}
        >{message}</i>
        </div>
)}

{!tru3 && (
  <>
    {Object.keys(submissionMessage2).map((key) => (
      submissionMessage2[key].length > 0 && (
        <p key={key} style={{ color: "red" }}>
          {submissionMessage2[key][0].replace('admin api with this', 'Entered ')}
        </p>
      )
    ))}
  </>
)}
{!tru1 && (
  <>
    {Object.keys(submissionMessage21).map((key) => (
      submissionMessage21[key].length > 0 && (
        <p key={key} style={{ color: "red" }}>Password,name,email mandotory
          {submissionMessage21[key][0].replace('admin api with this', 'Entered ')}
        </p>
      )
    ))}
  </>
)}
{loading1&&<Progress/>}
    </>
  );
};

export default AddProfileForm;
