import React, { useState, useEffect } from 'react';
// import './Parent.css';

const AddProject = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintesting = JSON.parse(localStorage.getItem("logintesting"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
  const name=loggedInadmin ? loggedInadmin.name :loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name:logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name: null;
  const organization = loggedInUser
  ? loggedInUser.organization
  : logintesting
  ? logintesting.organization
  : loginbackend
  ? loginbackend.organization
  : logintl
  ? logintl.organization
  : logintltest
  ? logintltest.organization
  : loginbackendtl
  ? loginbackendtl.organization
  : null;
    const storedColor = localStorage.getItem("selectedColor");
  const [formData, setFormData] = useState({
    projectname: '',
    logo: null,
    employeename: [],
    organization:organization // Modify to store selected employee names
  });
  const bloodGroupOptions = [
    "Hola9","Matrimony","DatingApp","Realestate","Services","HRMS","Jobportal"
   ];

  const [errors, setErrors] = useState({}); // Define errors state
  
  const [employeeNames, setEmployeeNames] = useState([]); // State to store employee
  useEffect(() => {
    // Fetch employee names from API
    const fetchEmployeeNames = async () => {
      try {
        const response = await fetch('https://empadmin.hola9.com/account/loginprofile/');
        if (!response.ok) {
          throw new Error('Failed to fetch employee names');
        }
        const data = await response.json();
        setEmployeeNames(data); // Set fetched employee names to state
      } catch (error) {
        console.error('Error fetching employee names:', error.message);
      }
    };

    fetchEmployeeNames();
  }, []);
  const [data1, setData1] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://empadmin.hola9.com/account/loginEmployee/');
        const apiData = await response.json();
        setData1(apiData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  const names = data1.map((item) => item.name);
  const handleChange = (e) => {
    const { name, value, type } = e.target;
    const val = type === 'file' ? e.target.files[0] : value;
    setFormData(prevState => ({
      ...prevState,
      [name]: val
    }));
  };

  const handleSelectChange = (e) => {
    const selectedNames = Array.from(e.target.selectedOptions, option => {
      return { name: option.value }; // No need for ID
    });
    setFormData(prevState => ({
      ...prevState,
      employeename: [...prevState.employeename, ...selectedNames],
    }));
  };
  
  
  
  const removeName = (nameToRemove) => {
    setFormData(prevState => ({
      ...prevState,
      employeename: prevState.employeename.filter(employee => employee.name !== nameToRemove),
    }));
  };
  
  const monthsList = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December',
  ];
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const errors = validateForm(formData);
      if (Object.keys(errors).length === 0) {
        const formDataToSend = new FormData();
        formDataToSend.append("projectname", formData.projectname);
        formDataToSend.append("logo", formData.logo);
        const employeeNamesString = formData.employeename.map(employee => employee.name).join(', '); // Concatenate names with commas
        formDataToSend.append("employeename", employeeNamesString);
        
        const response = await fetch('https://empadmin.hola9.com/account/projectdetail/', {
          method: 'POST',
          body: formDataToSend
        });
  
        if (!response.ok) {
          throw new Error('Failed to submit form');
        }
        console.log('Form submitted successfully');
        setFormData({
          projectname: '',
          logo: null,
          employeename: [], // Clear selected employee names after successful submission
        });
        setErrors({});
      } else {
        setErrors(errors);
      }
    } catch (error) {
      console.error('Error submitting form:', error.message);
    }
  };
  

  const validateForm = (data) => {
    let errors = {};
    if (!data.projectname.trim()) {
      errors.projectname = 'Project Name is required';
    }
    return errors;
  };
  return (

         <form
          className="form-group"
          onSubmit={handleSubmit}>
 <label htmlFor="projectname"><span style={{ color: "red" }}>P</span>roject Name:</label>
            <select name="projectname" value={formData.projectname} onChange={handleChange}required>
            <option>select</option>
              {bloodGroupOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
         

          <label htmlFor="logo"><span style={{ color: "red" }}>L</span>ogo:</label>
          <input
            type="file"
            id="logo"
            name="logo"
            accept="image/*"
            onChange={handleChange}
          />

          <label htmlFor="employeename"><span style={{ color: "red" }}>E</span>mployee Name:</label>
          <select
  id="employeename"
  name="employeename"
  multiple
  value={formData.employeename.map(employee => employee.name)} // Set the value attribute to the names of selected employees
  onChange={handleSelectChange}
>
  {data1.map(employee => (
    <option key={employee.id} value={employee.name}>
      {employee.name}
    </option>
  ))}
</select>

        <div style={{width:'fit-content', height:'fit-content', display:'flex', flexWrap:'wrap'}}>
          <label>Selected Names:</label>
          {formData.employeename && formData.employeename.map((employee, index) => (
  <span key={index} style={{background:'lightgray', margin:'2px'}}>
    {employee.name}
    <i type="button" onClick={() => removeName(employee.name)} style={{color:'red', padding:'5px', cursor:'pointer'}}>❌</i>
  </span>
))}

        </div>
        <button className='button' type="submit" style={{background:storedColor, color:'white'}}>
          Submit
        </button>
      </form>
  );
};

export default AddProject;