import React, { useState, useEffect } from 'react';
import "./ForgetpasswordAdmin.css"

const ForgotPasswordPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [otpSent1, setOtpSent1] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [otpTimer, setOtpTimer] = useState(60); // Initial timer value in seconds
    const [formData1, setFormData1] = useState({ otp_code: "" });

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handlePassword2Change = (e) => {
        setPassword2(e.target.value);
    };

    const handleOtpSend = async () => {
        setOtpSent1(true);
        try {
            const response = await fetch('https://empadmin.hola9.com//account/send-otp/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                }),
            });
            if (response.ok) {
                setOtpSent(true);
                startOtpTimer(); // Start OTP timer
                setOtpSent1(false)
            } else {
                setErrorMessage('Failed to send OTP');
                setOtpSent1(false)
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            setErrorMessage('Failed to send OTP');
            setOtpSent1(false)
        }
    };

    const startOtpTimer = () => {
        const timerInterval = setInterval(() => {
            setOtpTimer((prevTimer) => {
                if (prevTimer === 1) {
                    clearInterval(timerInterval); // Stop timer when it reaches 0
                    setOtpSent(false); // Reset otpSent state
                }
                return prevTimer - 1;
            });
        }, 1000);
    };

    const handleOtpVerify = async () => {
        try {
            const response = await fetch('https://empadmin.hola9.com/account/verify-otp/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    otp_code: formData1.otp_code
                }),
            });
            if (response.ok) {
                setOtpVerified(true);
            } else {
                setErrorMessage('Invalid OTP');
            }
        } catch (error) {
            console.error('Error verifying OTP:', error);
            setErrorMessage('Failed to verify OTP');
        }
    };

    const handleSubmit = async () => {
        if (password !== password2) {
            setErrorMessage("Passwords don't match");
            return;
        }
        if (password.length < 8) {
            setErrorMessage("Password must be at least 8 characters long");
            return;
        }
    
        try {
            const response = await fetch('https://empadmin.hola9.com/account/forgotadminpassword/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                    password2: password2
                }),
            });
            if (response.ok) {
                setOtpVerified(true);
            } else {
                setErrorMessage('Failed to reset password');
            }
        } catch (error) {
            console.error('Error resetting password:', error);
            setErrorMessage('Failed to reset password');
        }
    };

    useEffect(() => {
        // Clear error message after 5 seconds
        const errorTimeout = setTimeout(() => {
            setErrorMessage('');
        }, 5000);
        return () => clearTimeout(errorTimeout);
    }, [errorMessage]);

    return (
        <div className="containerer">
            <form className='form-gorup'>
                <div className="form-grouper">
                    <label className='forgetlabel' htmlFor="email">Email:</label>
                    <input className='forgetinput'
                        type="email"
                        id="email"
                        value={email}
                        onChange={handleEmailChange}
                        required
                    />
                    {otpSent1&&(<>...sending</>)}
                </div>
                
                {otpSent && !otpVerified ? (
                    <div className="form-grouper">
                        <label className='forgetlabel' htmlFor="password">OTP:</label>
                        <input className='forgetinput'
                            type="text"
                            name="otp_code"
                            value={formData1.otp_code}
                            onChange={(e) => setFormData1({ ...formData1, otp_code: e.target.value })}
                        />
                    </div>
                ) : null}
                {otpVerified ? (
                    <>
                        <div className="form-grouper">
                            <label className='forgetlabel' htmlFor="password">New Password:</label>
                            <input className='forgetinput'
                                type="password"
                                id="password"
                                value={password}
                                onChange={handlePasswordChange}
                                required
                            />
                        </div>
                        <div className="form-grouper">
                            <label className='forgetlabel' htmlFor="password2">Confirm Password:</label>
                            <input className='forgetinput'
                                type="password"
                                id="password2"
                                value={password2}
                                onChange={handlePassword2Change}
                                required
                            />
                        </div>
                    </>
                ) : null}
                {errorMessage && <p className="errorere">{errorMessage}</p>}
                {!otpSent ? (
                    <button className="buttoner" type="button" onClick={handleOtpSend}>Send OTP</button>
                ) : (
                    <>
                        {otpTimer > 0 && (
                            <p>Resend OTP in {otpTimer} seconds</p>
                        )}
                        {!otpVerified && (
                            <button className="buttoner" type="button" onClick={handleOtpVerify}>Verify OTP</button>
                        )}
                        {otpVerified && (
                            <button className="buttoner" type="button" onClick={handleSubmit}>Reset Password</button>
                        )}
                    </>
                )}
            </form>
        </div>
    );
};

export default ForgotPasswordPage;
