import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddUserForm = () => {

  const [message, setMeaasage] = useState(false);

  // ---------------- auto hide error popup after 5 seconds ----------------- //
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowErrorMessage(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [showErrorMessage]);


  const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  const logintl = JSON.parse(localStorage.getItem("logintl"));
  const logintesting = JSON.parse(localStorage.getItem("logintesting"));
  const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
  const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
  const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
  

  const organization = loggedInUser
    ? loggedInUser.organization
    : logintesting
    ? logintesting.organization
    : loginbackend
    ? loginbackend.organization
    : logintl
    ? logintl.organization
    : logintltest
    ? logintltest.organization
    : loginbackendtl
    ? loginbackendtl.organization
    : loggedInadmin
    ? loggedInadmin.name
    : null;
    const [formData, setFormData] = useState({
        name: '',
        organization: organization,
        username: '',
        password: '',
        tl: false,
        dev: false,
        testing: false,
        testing_tl: false,
        backend: false,
        backend_tl: false
      });
  const [emailError, setEmailError] = useState(false);

      const email=formData.username
      useEffect(() => {
        const companyDomain = "outlook.com";
        const emailRegex = new RegExp(`^[A-Za-z0-9._%+-]+@${companyDomain}$`, "i");
      
        if (!emailRegex.test(email) && email.trim() !== "") {
          setEmailError(true);
        } else {
          setEmailError(false);
        }
      }, [email]);

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, type, checked } = e.target;
    
        // Handle exclusive selection for checkboxes
        if (type === 'checkbox' && checked) {
            const updatedFormData = { ...formData };
            Object.keys(updatedFormData).forEach(key => {
                if (key !== name && typeof updatedFormData[key] === 'boolean') {
                    updatedFormData[key] = false;
                }
            });
            setFormData({ ...updatedFormData, [name]: checked });
        } else {
            const value = type === 'checkbox' ? checked : e.target.value;
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };
    
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = {};
        if (!formData.name.trim()) {
            errors.name = '*Name is required';
        }
        
        if (!formData.username.trim()) {
            errors.username = '*Username is required';
        }
        if (!formData.password.trim()) {
            errors.password = '*Password is required';
        }
        if (emailError) {
          return; // Don't submit if there's an email error
        }
        setErrors(errors);

            try {
                const response = await fetch('https://empadmin.hola9.com/account/adduser/', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(formData),
                });
                setShowErrorMessage(false)
                setMeaasage('User Added')
                window.location.reload();
              } catch (error) {
                setShowErrorMessage(true)
                setMeaasage("Failed to create")
                console.error('Error submitting data:', error);
              }
    };


    return (
        <>
            <form onSubmit={handleSubmit} className="form-group2 form-group3 form-group4">
            <div className='grid-div '>
                    <span>
            <label>{organization}: </label>   
      <input required type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Name" style={{borderBottomColor: errors.name ? 'rgb(255, 0, 0)' : ''}}/>
      </span>
      <span>
      
      <label>Username: </label>
      <input required type="email" name="username" value={formData.username} onChange={handleChange} placeholder="Username" style={{borderBottomColor: errors.username ? 'rgb(255, 0, 0)' : ''}}/>
      {emailError&&<small style={{color:"red",fontSize:"70%"}}>Only outlook Mail id required</small>}
      </span>
      <span>
      <label>Password: </label>
      <input required type="password" name="password" value={formData.password} onChange={handleChange} placeholder="Password" style={{borderBottomColor: errors.password ? 'rgb(255, 0, 0)' : ''}}/>
      </span>
      {/* <div className='grid-div '> */}
        <span className='adduserCheckbox-TS'>     
      <label className='adduser-TL-LA' >
        TL:</label>
           <input type="checkbox" name="tl" checked={formData.tl} onChange={handleChange} />
        </span>
        <span className='adduserCheckbox-TS'>     
      <label className='adduser-TL-LA' >
        Dev:</label>
           <input type="checkbox" name="dev" checked={formData.dev} onChange={handleChange} />
        </span>
      
        <span  className='adduserCheckbox-TS' >
      <label className='adduser-TS-LA' >
        Testing:</label>
        <input  type="checkbox" name="testing" checked={formData.testing} onChange={handleChange} />
        </span>
      
        <span  className='adduserCheckbox-TS'>
      <label className='adduser-TST-LA'  >
        Testing TL:</label>
        <input type="checkbox" name="testing_tl" checked={formData.testing_tl} onChange={handleChange} />
        </span>
      
        <span  className='adduserCheckbox-TS' >
      <label className='adduser-BD-LA'  >
        Backend: </label>
        <input type="checkbox" name="backend" checked={formData.backend} onChange={handleChange} />
        </span>
     
        <span  className='adduserCheckbox-TS'>
      <label className='adduser-BDT-LA'  >
        Backend TL: </label>
        <input type="checkbox" name="backend_tl" checked={formData.backend_tl} onChange={handleChange} />
        </span>
        {/* </div> */}
        </div>
     
      <button type="submit" className='button'>Submit</button>
    </form>


    {showErrorMessage && (
            <div className={`side-pop-message-div`}>
        <i
          className={`side-pop-message ${
            message === 'User Added'
              ? "sucess-message"
              : "error-message"
          }`}
        >{message}</i>
        </div>
)}
        </>
    );
};

export default AddUserForm;