import React, { useEffect, useState } from 'react';

const ApproveForm = (props) => {
const data=props.dataid
   const [Loading, setLoading] = useState(false)
   const [errorMessage, setErrorMessage] = useState("")
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowErrorMessage(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [showErrorMessage]);

    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const loggedInadmin = JSON.parse(localStorage.getItem("loggedInadmin"));
    const logintl = JSON.parse(localStorage.getItem("logintl"));
    const logintesting = JSON.parse(localStorage.getItem("logintesting"));
    const logintltest = JSON.parse(localStorage.getItem("logintestingtl"));
    const loginbackend = JSON.parse(localStorage.getItem("loginbackend"));
    const loginbackendtl = JSON.parse(localStorage.getItem("loginbackendtl"));
    const name=loggedInadmin ? loggedInadmin.name :loggedInUser ? loggedInUser.name : logintesting ? logintesting.name : loginbackend ? loginbackend.name : logintl ? logintl.name:logintltest ? logintltest.name : loginbackendtl ? loginbackendtl.name: null;
      

    const [formData, setFormData] = useState({
        name: name,
        emp_id: data,
        description: '',
        // emp_approve: true
      });
    
      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
      };
    
      const handleSubmit = (e) => {
      
        e.preventDefault();
        if (formData.description.length < 1) {
          setErrorMessage("Write Something");
          return;
      } 
        setLoading(true)
        fetch('https://empadmin.hola9.com/account/empapprovdetail/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        })
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data);
          setShowErrorMessage(true)
          setErrorMessage('Leave Request Sent to Hr Department')
          setLoading(false)
        })
        .catch(error => {
          console.error('Error:', error);
          setShowErrorMessage(true)
          setErrorMessage('Failed to sent error message to hr depatment')
          setLoading(false)
        });
      };

    return (
        <>
            <form className='form-group' onSubmit={handleSubmit}>
            <label>Drop Your Reason Here</label><textArea type="text" name="description" required value={formData.description} onChange={handleChange} style={{height:"70px", border:"1px solid lightgray", marginBottom : '5px'}}></textArea>
            <button className='button' type='submit' disabled={Loading} >{ Loading ? 'sending Approval Request...' : 'Send Approve Request To HR Department'}</button>
            </form>

            {showErrorMessage && (
            <div className={`side-pop-message-div`}>
        <i
          className={`side-pop-message ${
            errorMessage === 'Leave Request Sent to Hr Department'
              ? "sucess-message"
              : "error-message"
          }`}
        >
          {errorMessage}</i>
          </div>)}
        </>

        
    );
};

export default ApproveForm;