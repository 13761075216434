import React, { useState, useEffect, useCallback } from 'react';

const ProjectDetails = ({ projectId , setIsActive}) => {
    const [projectDetails, setProjectDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
const pro=sessionStorage.getItem("project");
    useEffect(() => {
        const fetchProjectDetails = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const response = await fetch(`https://empadmin.hola9.com/account/projectdetail/?projectname=${pro}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch project details');
                }

                const data = await response.json();
                setProjectDetails(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        if (pro) {
            fetchProjectDetails();
        }
    }, [pro]);


    const handleSelect = useCallback(
      (name) => {
          return () => {
              // setIsActive(`Home`);
              setIsActive(`profileDetails/${name}`);
          };
      },
      [setIsActive]
  );

    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
    if (!projectDetails) return <p>No project details available.</p>;

    return (
        // <div>
        //     <h2>{projectDetails.title}</h2>
        //     <p>{projectDetails.description}</p>
        // </div>
        <>
        {projectDetails.map((dev, index) => (
            <section key={index} style={{ height: '100%', overflowY: 'scroll' }} className="scroll-bar">
              <div
                style={{ backgroundImage: `url(https://empadmin.hola9.com/${dev.backgroundimg})` }}
                className="projects-main-img border"
              >
                <span className="project-sub-header-cont">
                  <h2>{dev.organization}</h2>
                  <p>{dev.description}</p>
                </span>
              </div>
          
              <div className="Project-team">
                <h1>{`${dev.projectname} - TEAM`}</h1>
                {/* <div className="project-team-cards">
                 
                    <span className="project-team-cards-span container-background" key={index}>
                      <img src={"https://empadmin.hola9.com/" + dev.tl.image} alt="" />
                      <h3>{dev.tl.name}</h3>
                      <button className="button">Profile</button>
                    </span>
                 
                </div> */}
                <div className="project-team-cards">
                  {dev.emp_profiles.map((data, index) => (
                    <span className="project-team-cards-span container-background" key={index}>
                      <img src={"https://empadmin.hola9.com/" + data.image} alt="" />
                      <h3>{data.name}</h3>
                      <button className="button" onClick={handleSelect(data.name)}>Profile</button>
                    </span>
                  ))}
                </div>
              </div>
            </section>
          ))}
          </>
    );
};

export default ProjectDetails;
