import React, { useState } from 'react';
import './Int.css'; // Import your CSS file for styling
import { MdAssistant, MdAssistantPhoto } from 'react-icons/md';
import { FcAssistant } from "react-icons/fc";
const Int = () => {
  const [isPopupVisible, setPopupVisible] = useState(false);

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  return (
    <div className="app">
      <div className="float-button" onClick={togglePopup}>
        <FcAssistant style={{fontSize:"50px"}}/>
      </div>
      {isPopupVisible && <Popup onClose={togglePopup} />}
    </div>
  );
};

const Popup = ({ onClose }) => {
    const handleInstructionClick = () => {
        onClose(); // Close the popup
        // Return the URL or perform any other action
        window.open("https://cdn.dribbble.com/users/563509/screenshots/3210197/comingsoon_01.gif", "_blank");
    };
  const log = localStorage.getItem("logg");
    
  return (
    <div className="popup">
      <div className="popup-header">
        <div className="popup-title"><MdAssistant  className="animated-icon" /></div>
        <button className="close-btn" onClick={onClose}>X</button>
      </div>
      <div className="popup-content">
        {log ?(
            <>
        <div className="message" onClick={handleInstructionClick}>Instructions </div>
        <div className="message" onClick={handleInstructionClick}>Features</div>
        <div className="message" onClick={handleInstructionClick}>24/7 Help Desk</div>
        <div className="message" onClick={handleInstructionClick}> Customer Support</div>
        </>):(
        <><div className="message" onClick={handleInstructionClick}>Signin/Signup ? </div>
        <div className="message" onClick={handleInstructionClick}>Forgot Password ?</div>
        </>)}
      </div>
    </div>
  );
};

export default Int;
