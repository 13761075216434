import Holidays from "../../images/featuresImages/Holidays.webp";
import TaskTracking from "../../images/featuresImages/TaskTracking.webp";
import Salary from "../../images/featuresImages/salary.webp";
import WorkReport from "../../images/featuresImages/report.webp";
import Gallery from "../../images/featuresImages/Gallery.webp";
import LoginDetails from "../../images/featuresImages/LoginDetails.webp";
import AddProfile from "../../images/featuresImages/AddProfile.jpg";
import AddUser from "../../images/featuresImages/AddUser.avif";
import Leave from "../../images/featuresImages/Leave.jpg";
export const services = [
  {
    imgUrl: `${AddProfile}`,
    title: "Add Profile",
  },
  {
    imgUrl: `${AddUser}`,
    title: "Credentials",
  },
  {
    imgUrl: `${WorkReport}`,
    title: "Your details - logs",
  },
  {
    imgUrl: `${LoginDetails}`,
    title: "checkIn Details",
  },
  {
    imgUrl: `${TaskTracking}`,
    title: "Task list",
  },
  {
    imgUrl: `${Leave}`,
    title: "Leave",
  },
  {
    imgUrl: `${Salary}`,
    title: "Salaryslips",
  },
  {
    imgUrl: `${Holidays}`,
    title: "Holidays",
  },
  {
    imgUrl: `${Gallery}`,
    title: "Gallery",
  },
];