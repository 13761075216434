import './EmployeeDash.css'
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProfileLoader } from '../Loaders/Loader';
import { FaFacebookSquare, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';


const EmployeeDash = (props) => {

  const { isActive } = props;
  const name = isActive.split('/')[1];

  const {id} = useParams();
    
  const [isLoading1, setIsLoading1] = useState(true);
  const [details, setDetails] = useState([]);
  const [Error, setError] = useState(false);

  useEffect(() => {
    fetch(`https://empadmin.hola9.com/account/loginprofile/?name=${id ? id :name}`)
      .then((response) => response.json())
      .then((data) => {
        setDetails(data);
        setIsLoading1(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading1(false);
        setError(true)
      });
  }, [id]);

  const navigate = useNavigate();

  const handleChat = (e) => {
    navigate(`/profileDashboard/${id}`);
  };

    return (
        <>
        {isLoading1 ? <ProfileLoader/> : <> 
        {details.map((user) => (
            <div className="Allempdash-div">
              <div className="Allempdash-img-div">
                <img className="Allempdash-img"             src={user.image.replace("http://66.179.250.38:8005/", "https://empadmin.hola9.com/")}
 />
                <sapn style={{ display: "flex", alignItems: "center" , flexDirection:'column'}}>
                  <h3 className="Allempdash-c-h3">{user.name}</h3>
                  <div className='profile-dash-socail' style={{display:'flex', justifyContent:'space-around', width:'100%'}}>
                  {/* <GiConversation
                    className="icon-style-prof"
                    title={`Chat with ${user.name}`}
                    onClick={handleChat}
                  /> */}
                  {user.facebook_link ? (
                    <FaFacebookSquare
                      className="icon-style-prof"
                      onClick={() => window.open(user.facebook_link, "_blank")}
                    />
                  ) : null}
                  {user.insta_link ? (
                    <FaInstagram
                      className="icon-style-prof"
                      onClick={() => window.open(user.insta_link, "_blank")}
                    />
                  ) : null}
                  {user.linkedin_link ? (
                    <FaLinkedinIn
                      className="icon-style-prof"
                      onClick={() => window.open(user.linkedin_link, "_blank")}
                    />
                  ) : null}
                  {user.twitter_link ? (
                    <FaTwitter
                      className="icon-style-prof"
                      onClick={() => window.open(user.twitter_link, "_blank")}
                    />
                  ) : null}
                  </div>
                </sapn>
              </div>
              <div className="Allempdash-cont-div">


                <div
                  className="Allempdash-cont-sub-div"
                >
                  <h5>PERSONAL</h5>

                  <div className="Allempdash-cont-details-div Allempdash-cont-details-div-flex profile-dash-details-row">
                    <span>
                      <h6>Role</h6>
                      <p>{user.role}</p>
                    </span>
                    <span>
                      <h6>Date of Birth</h6>
                      <p>{user.dob}</p>
                    </span>
                    <span>
                      <h6>Blood Group</h6>
                      <p>{user.blood_group}</p>
                    </span>
                    <span>
                      <h6>Phone Number</h6>
                      <p>{user.phone_number} </p>
                    </span>
                    <span>
                      <h6>Marital Status</h6>
                      <p>{user.marital_status}</p>
                    </span>
                    <span>
                      <h6>Gender</h6>
                      <p>{user.gender}</p>
                    </span>
                  </div>
                </div>

                <div className="Allempdash-cont-sub-div">
                  <h5>PROFESSIONAL</h5>

                  <div className="Allempdash-cont-details-div Allempdash-cont-details-div-flex profile-dash-details-row">
                    <span>
                      <h6>Employee ID</h6>
                      <p>{user.userid}</p>
                    </span>
                    <span>
                      <h6>Reporting</h6>
                      <p>{user.reporting_to}</p>
                    </span>
                    <span>
                      <h6>Designation</h6>
                      <p>{user.role}</p>
                    </span>
                    <span>
                      <h6>mail ID</h6>
                      <p>{user.email}</p>
                    </span>
                    <span>
                      <h6>Joining Date</h6>
                      <p>{user.working_since}</p>
                    </span>
                  </div>
                </div>

                <div className="Allempdash-cont-sub-div">
                  <h5>PROJECTS</h5>

                  <div className="Allempdash-cont-details-div profile-dash-details-row">
                    <span>
                      {user.project_name && (
                        <>
                          <p>{`${user.project_name} | on going`}</p>
                        </>
                      )}
                    </span>
                    {/*<span>
                <h6>Services website &nbsp; </h6> | &nbsp;{" "}
                <p>09-10-23 to 05-01-24</p>
              </span> */}
                  </div>
                </div>
              </div>
            </div>
        ))}


{details.length < 1 && 
        <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
          <div className=''>
            <h4>No data found for {name}</h4>
          </div>
        </div>}
        </>
        }

<div className='bottom-height-768'></div>
        </>
    );
};

export default EmployeeDash;