import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MapLocationAds1 from './Maplocation1';
import MapLocationAds from './MapLocation';
import { ListLoader } from '../../Loaders/Loader';
import { FaFileContract } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';

const AdminCheckInLogs = () => {

    const [apiData, setApiData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true); // New loading state
    const itemsPerPage = 45;
    const [itemsToLoad, setItemsToLoad] = useState(30); // Holds the number of items to load initially and increases when "Load More" is clicked
    const [searchName, setSearchName] = useState('');
    const [limitIncreaseCount, setLimitIncreaseCount] = useState(0);
    const [loading, setloading] = useState(true)
  
    const handlePageClick = ({ selected }) => {
      setCurrentPage(selected);
      setLimitIncreaseCount(0);
    };
    useEffect(() => {
      fetchData();
      // Fetch your API data here
    }, [itemsToLoad]);
  
      const fetchData = () => {
      fetch(`https://empadmin.hola9.com/account/employeelogin2?limit=${itemsToLoad}&${searchName ? `name=${searchName}` : ''}`)
        .then(response => response.json())
        .then(data => {
          setApiData(data);
          setIsLoading(false); // Set loading state to false when data is loaded
          setloading(false)
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setIsLoading(false); // Set loading state to false on error
        });
      };
    const handleLoadMore = () => {
      setItemsToLoad(itemsToLoad + 30);
      setLimitIncreaseCount((prevCount) => prevCount + 1);
      // Increase the number of items to load
    };
    const [prevDataLength, setPrevDataLength] = useState(0);
    
    useEffect(() => {
      const intervalId = setInterval(async () => {
        if (!isLoading && limitIncreaseCount != 3) {
          const newDataLength = apiData.length;
    
          if (newDataLength !== prevDataLength) {
            handleLoadMore();
            setPrevDataLength(newDataLength);
          } else {
            clearInterval(intervalId); // Stop further calls to handleLoadMore
          }
        }
      }, 2000);
    
      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }, [isLoading, apiData, prevDataLength,limitIncreaseCount]);
    const calculateTotalHours = (loginTime, logoutTime) => {
      if (!loginTime || !logoutTime) return 0;
  
      const login = new Date(`2000-01-01 ${loginTime}`);
      const logout = new Date(`2000-01-01 ${logoutTime}`);
  
      const diffInMilliseconds = logout - login;
      const totalHours = diffInMilliseconds / (1000 * 60 * 60);
  
      return totalHours;
    };
  
    const handleSearch = (e) => {
      setSearchTerm(e.target.value);
      setCurrentPage(0); // Reset to the first page when searching
    };
    const [selectedDateRange, setSelectedDateRange] = useState({
      startDate: '',
      endDate: '',
    });
  
    const handleDateChange = (date, type) => {
      setSelectedDateRange(prevRange => ({
        ...prevRange,
        [type]: date,
      }));
      setCurrentPage(0); // Reset to the first page when changing the date range
    };
  
    const filterByDate = (rowData) => {
      const { startDate, endDate } = selectedDateRange;
      if (!startDate || !endDate) {
        return true; // Return true if no date range is selected
      }
  
      const rowDate = new Date(rowData.date);
      return rowDate >= new Date(startDate) && rowDate <= new Date(endDate);
    };
  
    const filteredData = apiData
    .filter(rowData =>
      Object.values(rowData).some(value =>
        value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    )
    .filter(filterByDate);
  
    const offset = currentPage * itemsPerPage;
    const pageCount = Math.ceil(filteredData.length / itemsPerPage);
   
    const paginatedData = filteredData
    .sort((a, b) => b.id - a.id)
    .slice(offset, offset + itemsPerPage)
    .map((rowData) => {
      const isDataMissing = Object.values(rowData).some((value) => value === null);
      const totalHours = calculateTotalHours(rowData.login_time, rowData.logout_time);
      const isTotalTimeYellow = totalHours < 1;
      const rowStyle = isDataMissing ? { backgroundColor: 'red' } : {};
      const totalTimeStyle = isTotalTimeYellow ? { backgroundColor: 'yellow' } : {};
      const convertMinutesToHours = (minutes) => {
        const totalMinutes = parseInt(minutes, 10); // Ensure total_minutes is a valid number
        if (isNaN(totalMinutes)) {
          return 'Invalid Time';
        }
    
        const hours = Math.floor(totalMinutes / 60);
        const remainingMinutes = totalMinutes % 60;
        return `${hours}h ${remainingMinutes}m`;
      };

      return (
        <tr key={rowData.id} style={rowStyle} className='Reports-tr'>
          <td>
            <a href={`https://empadmin.hola9.com/admin/account/employeelogin2/${rowData.id}/change/`} target='_blank' style={{ color: 'blue' }}>
              {rowData.id}
            </a>
          </td>
          <td>{rowData.name || 'N/A'}</td>
          <td>{rowData.date.split('T')[0] || 'N/A'}</td>
          <td>{rowData.login_time || 'N/A'}
            {rowData.lattiude1 && rowData.longitude1 ? (
                    <>
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${rowData.lattiude1},${rowData.longitude1}`}
                        target='_blank'
                      >
                        See On Map
                        
                      </a>
                      <MapLocationAds id={rowData.id} props={{ lati: rowData.lattiude1, long: rowData.longitude1 }} />
                    </>
                  ) : (
                    <>
                      No location found
                    </>
                  )}
                  </td>
                  <td>
                    {rowData.lunch_break_checkin && 
                    <p>{`from  -  ${rowData.lunch_break_checkin}`}</p>}
                    {rowData.lunch_break_checkout &&
                    <p>{`to    -  ${rowData.lunch_break_checkout}`}</p>}
                  </td>
          <td>{rowData.logout_time}
            {rowData.lattiude2 && rowData.longitude2 ? (
                    <>
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${rowData.lattiude2},${rowData.longitude2}`}
                        target='_blank'
                      >
                        See On Map
                        
                      </a>
                      <MapLocationAds1 id1={rowData.id} props1={{ lati1: rowData.lattiude2, long1: rowData.longitude2 }} />
                    </>
                  ) : (
                    <>
                      No location found
                    </>
                  )}</td>
          <td style={totalTimeStyle}>{convertMinutesToHours(rowData.total_time) || 'N/A'}</td>
  
        </tr>
      );
    });
  //navigation code start for adimin login
  const navigate = useNavigate();
  
  useEffect(() => {
    const loggedInadmin = localStorage.getItem("loggedInadmin");
  
    if (!loggedInadmin) {
      navigate("/AdminLogin");
    }
  }, []);
  const [data1, setData1] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://empadmin.hola9.com/account/loginEmployee/');
        const apiData = await response.json();
        setData1(apiData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);
  const names = data1.map((item) => item.name);
  const handleSelectChange = (event) => {
    setSearchName(event.target.value);
  };
  const handleSearchByName = () => {
    fetchData();
    setItemsToLoad(30);
    setIsLoading(true); // You can also perform additional logic here if needed
  };

    return (
        <>
                   <div className='Reports-Top'>
            <h5> <FaFileContract className='Report-top-icon' />CheckIn Reports <span className='error-message'></span></h5>
           </div>

           <div className="reports-top-filters">
           <input style={{width:"40%",borderRadius:"10px"}}
          type="date"
          placeholder="Start Date"
          value={selectedDateRange.startDate}
          onChange={(e) => handleDateChange(e.target.value, 'startDate')}
        />
        <input style={{width:"40%",borderRadius:"10px"}}
          type="date"
          placeholder="End Date"
          value={selectedDateRange.endDate}
          onChange={(e) => handleDateChange(e.target.value, 'endDate')}
        />
        <select style={{width:"40%",borderRadius:"10px"}}
          value={searchName}
          onChange={handleSelectChange}
         placeholder='select name'
        >
          <option value="">Select Name</option>
          {names.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>
        <button className='button' onClick={handleSearchByName}>Search</button>
          </div>
         <>
        <div className='reportsscroll-div'>
        {loading ? <ListLoader/> :
            <table  className='Reports-table' id="employeeTable">
        <thead className='Reports-thead container-background'>
          <tr className='Reports-tr'>
          <th>ID</th>
          <th>Name</th>
            <th style={{ margin:'0'}}>
              Date
            </th>
            <th>Check-in</th>
            <th>Lunch Break</th>
            <th>Check-Out</th>
            <th>Total Time</th>
          </tr>
        </thead>
        <tbody className='Reports-thead container-background'>{paginatedData}</tbody>

      </table>
      }
      </div>
      </>


      <ReactPaginate
                      className='paginat'

        pageCount={pageCount}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
        </>
    );
};

export default AdminCheckInLogs;